// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    height: '84px'
  },
  stepperNumber: {
    height: 20,
    width: 20,
    borderRadius: 50
  },
  stepperTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 8,
  },
  stepperConnector: {
    color: theme.palette.text.secondary,
    marginLeft: 22,
  }
}))

export default useStyles
