import { useContext, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import ContactOrderIcon from 'assets/images/logos/order-contact-logo.svg'
import IndonesiaFlag from 'assets/images/logos/order-flag-logo.svg'
import PaperIcon from 'assets/images/logos/order-paper-logo.svg'

// COMPONENT
import LoadHeader from 'components/BookingHeader/BookingHeader'
import LoadFooter from 'components/BookingFooter/BookingFooter'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// MUI ICONS 
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import IconInfo from '@mui/icons-material/InfoOutlined'
import RemoveIcon from '@mui/icons-material/Remove'

// SERVICE
import { getDepoListItem } from 'service/booking'

// STYLES
import useStyles from './orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string' 

const Order = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const {
    loadOrderForm, setLoadOrderFrom,
    loadItemList, setLoadItemList,
    bookingHeaderData, setSecondarySnackBarObject,
    auth
  } = useContext(AllPagesContext)

  const [depoListItem, setDepoListItem] = useState([])
  const [isValidatingForms, setIsValidatingForms] = useState(false)

  // FETCH DEPO LIST ITEM
  const loadDepoListItem = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      id: bookingHeaderData?.depo.id,
      type: 'LOADING'
    }
    const resultLoadDepoListItem = await getDepoListItem(inputAbortController.signal, queryParams)
      
    if(resultLoadDepoListItem.status === 200 && inputIsMounted) {
      setDepoListItem(resultLoadDepoListItem.data)
    }
  }

  // HANDLE ORDER FORMS CHANGE
  const handleLoadOrderForm = (event) => {
    setLoadOrderFrom(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  // HANDLE ORDER ITEM CHANGE
  const handleLoadItemListChange = (inputEvent,inputId,inputValue) => {
    // SET CONTAINER TYPE LIST
    inputEvent.stopPropagation()
    const newList = [...loadItemList].map((item) => {
      if(item.id === inputId) {
        item.containerType = inputValue?.item_name
        item.sku = inputValue?.sku
        item.price = inputValue?.price
        item.tempPrice = inputValue?.price
        item.description = inputValue?.description
        item.value = {...inputValue}
        item.fleet = inputValue.fleet.name
        item.quantity = 1
      }
      return item
    })
    setLoadItemList(newList)
  }

  // HANDLE ADD NEW ORDER ITEM
  const handleAddNewItemForm = () => {
    let newForm = {
      titleQuantity : 'Quantity',
      titleType : 'Items',
      quantity: 1,
      containerType: '',
      price: 0,
      sku: '',
      description: '',
      id : Math.floor(Math.random() * 1000000),
    }

    setLoadItemList(current => [...current, newForm ])
  }

  // HANDLE DELETE ORDER ITEM
  const deleteLoadItemList = (inputIndex) => {
    const newList = [...loadItemList]
    newList.splice(inputIndex,1)
    setLoadItemList(newList)
  }

  // HANDLE CHECKOUT BUTTON
  const handleCheckoutButton = () => {
    // CHECK IS ITEMS CHOOSED & CHECK CONTAINER NUMBER FIELD
    let isItemChoosed = true
    loadItemList.forEach((item) => {
      if(item.fleet === '' || item.containerType === '' || item.containerNo === '') isItemChoosed = false
    })
  
    // VALIDATING ALL FORMS
    if(
      loadOrderForm.fullName === '' || loadOrderForm.phoneNumber === '' ||
      loadOrderForm.email === '' || loadOrderForm.delivNo === '' ||
      loadOrderForm.consignee === '' || loadOrderForm.npwp === '' ||
      loadOrderForm.address === '' || !isItemChoosed
    ) {
      setSecondarySnackBarObject({
        open: true,
        severity: 'error',
        message: 'Required fields must be filled in'
      })
      setIsValidatingForms(true)
    }
  
    else {
      setIsValidatingForms(false)
      navigate('/load/checkout')
    }
  }  

  // HANDLE SUBSTRACT QUANTITY ITEM
  const handleSubstractQuantity = (inputEvent, inputItem) => {
    inputEvent.stopPropagation()
    
    const newList = [...loadItemList].map((item) => {
      if(item.id === inputItem.id && item.quantity > 1) {
        item.quantity--
        item.price -= item.tempPrice
      }
      return item
    })
    setLoadItemList(newList)
  }

  // HANDLE ADD QUANTITY ITEM
  const handleAddQuantity = (inputEvent, inputItem) => {
    inputEvent.stopPropagation()
    
    const newList = [...loadItemList].map((item) => {
      if(item.id === inputItem.id) {
        item.quantity++
        item.price += item.tempPrice
      } 
      return item
    })
    setLoadItemList(newList)
  }

  // HANDLE TOTAL PRICE
  let totalPrice = 0
  for (let i = 0; i < loadItemList.length; i++) {
    totalPrice += loadItemList[i].price
  }
  
  // HANDLE TOTAL ITEM
  let totalItem = 0
  for (let i = 0; i < loadItemList.length; i++) {
    if(loadItemList[i].value !== undefined) totalItem++
  }

  // SIDE EFFECT FETCH DEPO LIST ITEM
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoListItem(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  // SIDE EFFECT PARSING CONTACT DETAIL DATA
  useEffect(() => {
    setLoadOrderFrom(current => {
      return {
        ...current,
        phoneNumber: auth?.phoneNumber?.slice(1),
        fullName: auth.fullName,
        email: auth.email,
      }
    })
  }, [])

  return (
    <Stack
      marginTop='24px'
      width='100%'
      maxWidth='1140px'
      padding='0px 50px'
      position='relative'
    >
      {/* HEADER */}
      <LoadHeader type='load' />

      {/* CONTACT DETAILS */}
      <Stack className={classes.contactOrderDetail}>
        {/* TITLE */}
        <Stack 
          direction='row' 
          alignItems='center'
          borderBottom='solid 1px #D9D9D9'
          padding='16px 24px '
        >
          <Box 
            width='38px'
            component='img'
            src={ContactOrderIcon}
            alt=''
          />
          <Typography className={classes.contactOrderTitle}>
            Contact Details
          </Typography>
        </Stack>

        {/* FORMS */}
        <Stack padding='20px 24px 4px 24px'>
          {/* FULL NAME */}
          <Typography variant='body1' marginBottom='12px'>
            Full Name<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            required
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              error={(isValidatingForms && loadOrderForm.fullName === '') ? true : false}
              required
              placeholder='Enter full name'
              type='text'
              name='fullName'
              value={loadOrderForm.fullName}
              onChange={(event) => handleLoadOrderForm(event)}
              disabled
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.fullName === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* PHONE NUMBER */}
          <Typography variant='body1' marginBottom='12px'>
            Phone Number<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              disabled
              placeholder='Enter phone number'
              type='number'
              name='phoneNumber'
              className={classes.fieldNumber}
              InputProps={{
                startAdornment : <InputAdornment position='start' > 
                  <Stack alignItems='center' direction='row' paddingRight='14px' borderRight='solid 1px #D9D9D9'>
                    <Box component='img' src={IndonesiaFlag}/>
                    <Typography marginLeft='4px'> +62 </Typography>
                  </Stack>
                </InputAdornment>
              }}
              value={loadOrderForm.phoneNumber}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.phoneNumber === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.phoneNumber === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* EMAIL */}
          <Typography variant='body1' marginBottom='12px'>
            Email<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter email'
              type='email'
              name='email'
              value={loadOrderForm.email}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.email === '') ? true : false}
              disabled
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.email === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>

      </Stack>

      {/* ORDER DETAIL */}
      <Stack className={classes.orderDetail}>
        {/* TITLE */}
        <Stack 
          direction='row' 
          alignItems='center'
          borderBottom='solid 1px #D9D9D9'
          padding='16px 24px '
        >
          <Box 
            width='38px'
            component='img'
            src={PaperIcon}
            alt=''
          />
          <Typography className={classes.contactOrderTitle}>
            Order Details
          </Typography>
        </Stack>

        {/* FORMS */}
        <Stack padding='20px 24px 4px 24px'>
          {/* DELIVERY ORDER NO */}
          <Typography variant='body1' marginBottom='12px'>
            Delivery Order No.<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter delivery order no.'
              type='tex'
              name='delivNo'
              value={loadOrderForm.delivNo}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.delivNo === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.delivNo === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
            
          {/* CONSIGNEE */}
          <Typography variant='body1' marginBottom='12px'>
            Shipper<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter shipper'
              type='tex'
              name='consignee'
              value={loadOrderForm.consignee}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.consignee === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.consignee === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* NPWP */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter NPWP'
              type='number'
              className={classes.fieldNumber}
              name='npwp'
              value={loadOrderForm.npwp}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.npwp === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.npwp === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ADDRESS */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP Company Address<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter address'
              type='tex'
              name='address'
              value={loadOrderForm.address}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.address === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.address === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ITEMS */}
          {loadItemList.map((item,index) => (
            <Stack  key={index}>
              {/* ITEM FORM */}
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                {/* FORM */}
                <Stack direction='row' spacing={4} alignItems='center'>
                  {/* CONTAINER TYPE */}
                  <Stack>
                    <Typography variant='body1' marginBottom='12px'>
                      {item.titleType}<span className={classes.superscript}>*</span>
                    </Typography>

                    <Stack marginBottom='20px'>
                      <Autocomplete
                        disablePortal
                        disableClearable
                        options={depoListItem}
                        sx={{ width: 480 }}
                        value={item.value || null}
                        isOptionEqualToValue={(option, value)=> option.item_name?.id === value.item_name?.id}
                        onChange={(event,value) => handleLoadItemListChange(event, item.id, value)}
                        renderInput={(params) => <TextField {...params} placeholder='Select items' />}
                        getOptionLabel={(option) => `${option?.item_name?.itemCode} - ${option?.item_name?.itemType}`}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Stack width='100%'>
                              {/* ITEM NAME & PRICE */}
                              <Stack 
                                direction='row'
                                justifyContent='space-between'
                              >
                                <Typography 
                                  variant='body2'
                                  sx={{fontWeight: 500}}
                                >
                                  {option?.item_name?.itemCode} - {option?.item_name?.itemType}
                                </Typography>

                                <Typography variant='caption'>
                                  {`Rp. ${addSeparatorsForNumber(option.price)}`}
                                </Typography>
                              </Stack>

                              {/* FLEET MANAGER */}
                              <Typography variant='caption'>
                                {option?.fleet?.fleet_manager_company}
                              </Typography>

                              {/* SKU */}
                              <Typography variant='caption'>
                                {option.sku}
                              </Typography>
                            </Stack>
                          </li>
                        )}
                      />
                      {/* CUSTOM HELPER TEXT */}
                      {(item?.value?.item_name?.id === undefined && isValidatingForms) &&
                      <Typography 
                        margin='6px 12px'
                        color='error' 
                        variant='caption'
                      >
                        This field can't be empty
                      </Typography>}
                    </Stack>
                  </Stack>

                  {/* QUANTITY */}
                  <Stack width='174px'>
                    <Typography 
                      variant='body1' 
                      marginBottom='12px'
                      sx={(theme) => ({color: theme.palette.text.secondary})}
                    >
                      {item.titleQuantity}
                    </Typography>
                    
                    {/* QUANTITY COUNTER */}
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      height='54px'
                      marginBottom='20px'
                      border='1px solid #D9D9D9'
                      borderRadius='4px'
                    >
                      {/* SUBSTRACT BUTTON */}
                      <Stack className={classes.substrackButton}>
                        <IconButton onClick={(event) => handleSubstractQuantity(event,item)}>
                          <RemoveIcon/>
                        </IconButton>
                      </Stack>

                      {/* VALUE */}
                      <Stack
                        width='100%'
                        height='100%'
                        borderRight='1px solid #D9D9D9'
                        borderLeft='1px solid #D9D9D9'
                        alignItems='center'
                        justifyContent='center'
                      >
                        {item.quantity}
                      </Stack>

                      {/* ADD BUTTON */}
                      <Stack className={classes.substrackButton}>
                        <IconButton 
                          disabled={item?.value !== undefined ? false : true} 
                          onClick={(event) => handleAddQuantity(event,item)}
                        >
                          <AddIcon/>
                        </IconButton>
                      </Stack>

                    </Stack>
                  </Stack>
                </Stack>

                {/* TOTAL PRICE */}
                <Stack 
                  direction='row' 
                  alignItems='center'
                >
                  {/* TOTAL PRICE */}
                  <Stack marginRight='45px'>
                    <Typography
                      variant='body1' 
                      marginBottom='12px'
                      sx={(theme) => ({color: theme.palette.text.secondary})}
                    >
                      Total Price
                    </Typography>
                    <Stack
                      height='48px'
                      marginBottom='20px'
                      justifyContent='center'
                      paddingLeft='67px'
                    >
                      <Typography variant='body1'>
                        {`Rp. ${addSeparatorsForNumber(item.tempPrice * item.quantity)}`}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* BUTTON DELETE ITEM */}
                  <Stack direction='row'>
                    <IconButton 
                      onClick={() => deleteLoadItemList(index,item.id)}
                      disabled={loadItemList.length > 1 ? false : true}
                      disableRipple
                    >
                      <DeleteIcon 
                        sx={{color: loadItemList.length > 1 ? '#F96649' : '#00000033'}} 
                        className={classes.addNewFromIcon}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>

              {/* ITEM DESCRIPTION */}
              {item.fleet !== '' && item.description !== '' &&
              <Stack 
                direction='row' 
                marginBottom='20px'
                alignItems='center'
              >
                {/* FLEET */}
                <Stack width='480px' marginRight='30px' paddingLeft='16px'>
                  <Typography 
                    variant='body2'
                    textTransform='uppercase'
                  >
                    {item?.value?.fleet?.fleet_manager_company?? '-'}
                  </Typography>
                </Stack>

                {/* DESCRIPTION */}
                <CustomTooltipBlack
                  followCursor 
                  title={item.description?? '-'}
                  placement='bottom'
                >
                  <Stack 
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    width='100%'
                    maxWidth= '390px'
                  >
                    <IconInfo className={classes.descriptionIcon}/>

                    <Typography
                      variant='body2'
                      className={classes.descriptionText}
                    >
                      {item.description?? '-'}
                    </Typography>
                  </Stack>
                </CustomTooltipBlack>
              </Stack>}

              {/* DIVIDER */}
              <Divider sx={{marginBottom: '20px'}}/>
            </Stack>
          ))}

          {/* BUTTON ADD ITEM */}
          <Stack 
            width='150px' 
            marginBottom='24px'
          >
            <Button 
              variant='outlined'
              size='large'
              startIcon={<AddIcon/>}
              sx={{textTransform: 'none'}}
              onClick={() => handleAddNewItemForm()}
            >
              Add Items
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {/* LOAD FOOTER */}
      <LoadFooter
        totalPrice={totalPrice}
        totalItem={totalItem}
        checkOutButton={handleCheckoutButton}
      />
    </Stack>
  )
}

export default Order