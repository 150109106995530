import { useEffect, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'

// MUIS
import { 
  Box, 
  Button, 
  IconButton, 
  Menu, 
  MenuItem, 
  Stack, 
  Typography, 
  Fade,
} from '@mui/material'

// ASSETS
import BankMandiri from 'assets/images/logos/PaymentMethods/bank-mandiri.svg'
import BankBCA from 'assets/images/logos/PaymentMethods/bank-bca.svg'
import BankBRI from 'assets/images/logos/PaymentMethods/bank-bri.svg'
import BankBNI from 'assets/images/logos/PaymentMethods/bank-bni.svg'
import BankPermata from 'assets/images/logos/PaymentMethods/bank-permata.svg'
import BankBSI from 'assets/images/logos/PaymentMethods/bank-bsi.svg'
import BankBJB from 'assets/images/logos/PaymentMethods/bank-bjb.svg'
import LoadingLogo from 'assets/images/logos/order-loading-logo.svg'
import UnloadingLogo from 'assets/images/logos/order-unloading-logo.svg'
import MainBackground from 'assets/images/backgrounds/check-orders-bg.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import CheckOrderFooter from 'components/CheckOrderFooter/CheckOrderFooter'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'

// MUI ICONS
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

// SERVICE
import { cancelBookingOrder } from 'service/booking'

// STYLES
import useStyles from './unpaidCardUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'
import { removeUserProfileFromLocalStorage } from 'utilities/localStorage'

const UnpaidCard = (props) => {
  const { unpaidListOrders, reloadData } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { 
    auth, setAuth,
    setSnackbarObject, setSecondarySnackBarObject,
  } = useContext(AllPagesContext)
  
  const [listData, setListData] = useState([])
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [bookingID, setBookingID] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // HANDLE MENU ITEM CLOSE
  const handleMenuItemClose = (inputItem) => {
    const newListData = [...listData].map((item) => {
      if(item.id === inputItem.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)
  }

  // HANDLE OPEN XENDIT URL PAYMENT
  const handleHowToPaymentButton = (inputItem) => {
    // OPEN XENDIT URL
    if(inputItem.payment !== undefined) window.open(`${inputItem.payment.invoiceUrl}`, '_blank', 'noopener,noreferrer')

    // CLOSE MENU ITEM
    const newListData = [...listData].map((item) => {
      if(item.id === inputItem.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)
  }

  // HANDLE EXPIRED DATE COUNT DOWN TIMER
  const handleCountDownTime = (inputDate, index) => {
    const currentTime = new Date().getTime()
    const diff = new Date(inputDate) - currentTime

    if(diff > 0) {
      let count = setInterval(() => {
        if(document.getElementById(`countDown${index}`) === null) clearInterval(count)
        const currentTime = new Date().getTime()
        const eventTime = new Date(inputDate).getTime()
        const diff = eventTime - currentTime
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
        let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let seconds = Math.floor((diff % (1000 * 60)) / 1000)

        document.getElementById(`countDown${index}`).innerHTML = 'Waiting for payment ' + hours + ':' + minutes + ':' + seconds + ''

      }, 1000)
    } else return 'Expired'
    
  }

  // HANDLE SHOW MENU ITEM
  const handleHorizonIconClick = (inputEvent, inputItem) => {
    const newListData = [...listData].map((item) => {
      if(item.id === inputItem.id) item.anchor = inputEvent.currentTarget
      else item.anchor = null
      return item
    })
    setListData(newListData)
  }

  // HANDLE CONTINE CANCEL ORDER
  const handleContinueButtonClick = async () => {
    setIsDialogConfirmationOpen(false)
    const abortController = new AbortController()

    // CLOSE MENU ITEM
    const newListData = [...listData].map((item) => {
      if(item.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)

    setIsLoading(true)

    // CALL CANCEL ORDER API
    const resultCancelBookingORder = await cancelBookingOrder(abortController.signal, auth.accessToken, bookingID)
    
    if(resultCancelBookingORder.status === 200) {
      setBookingID(null)
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully cancel the order',
      })
      reloadData(true, abortController)
      setIsLoading(false)
    }

    // HANDLE IF SESSION JWT EXPIRED
    else if(resultCancelBookingORder.status === 403 && resultCancelBookingORder.data.error === 'Forbidden') {
      setSecondarySnackBarObject({
        open: true,
        severity: 'info',
        message: 'Session Expired. Please Log in again.'
      })
      removeUserProfileFromLocalStorage()
      setAuth({})
      navigate('/')
    }

    // HANDLE WHILE FAILED TO CANCEL THE ORDER
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to cancel the order',
      })
      setBookingID(null)
    }
    setIsLoading(false)
  }

  // HANDLE DIALOG CONFIRMATION BACK BUTTON
  const handleBackButtonClick = () => {
    setIsDialogConfirmationOpen(false)
    // CLOSE MENU ITEM
    const newListData = [...listData].map((item) => {
      if(item.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)
  }

  // HANDLE CANCEL ORDER
  const handleCancelOrderClick = (event, item) => {
    event.stopPropagation()
    setIsDialogConfirmationOpen(true)
    setBookingID(item?.id)
  }

  // TOTAL PAYMENT
  const handleTotalPayment = (inputItem) => {
    let total = 0
    inputItem.forEach((item) => total += item.price)
    
    return `Rp ${addSeparatorsForNumber(total)}`
  }

  // GET BANK LOGO
  const getBankLogo = (inputItem) => {
    if(inputItem.payment !== undefined) {
      if(inputItem?.payment?.bankCode?.toLowerCase().includes('bni')) return BankBNI
      else if(inputItem?.payment?.bankCode?.toLowerCase().includes('bca')) return BankBCA
      else if(inputItem?.payment?.bankCode?.toLowerCase().includes('mandiri')) return BankMandiri
      else if(inputItem?.payment?.bankCode?.toLowerCase().includes('bri')) return BankBRI
      else if(inputItem?.payment?.bankCode?.toLowerCase().includes('permata')) return BankPermata
      else if(inputItem?.payment?.bankCode?.toLowerCase().includes('bsi')) return BankBSI
      else if(inputItem?.payment?.bankCode?.toLowerCase().includes('bjb')) return BankBJB
    }
    else return ''
  }

  // STYLING FOOTER
  const stylingFooter = {
    position: listData.length > 1 ? 'unset' : 'absolute', 
    bottom: listData.length > 1 ? 'unset' : '0px'
  }

  useEffect(() => {
    if(unpaidListOrders) {
      setListData(unpaidListOrders)
      setIsMounted(true)
    }
  }, [unpaidListOrders])

  return (
    <Fade in={isMounted} timeout={1000}>
      <Box>
        {listData && listData.map((item, index) => (
          <Stack key={index} className={classes.root}>
            {/* BOOKING TYPE */}
            <Stack 
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              marginBottom='20px'
            >
              {/* LOGO & TITLE */}
              <Stack
                direction='row'
                alignItems='center'
              >
                <Box
                  src={item.booking_type === 'UNLOADING' ? UnloadingLogo : LoadingLogo}
                  component='img'
                />
                <Typography className={classes.bookingTypetitle}>
                  {item.booking_type === 'UNLOADING' ? 'Unloading' : 'Load'}
                </Typography>
              </Stack>
  
              {/* BOOKING ID */}
              <Typography className={classes.bookingID}>
                Booking ID {item.id}
              </Typography>
  
            </Stack>
  
            {/* DETAIL PAYMENT */}
            <Stack
              border='1px solid #D9D9D9'
              height='144px'
              borderRadius='4px'
            >
              {/* HEADER */}
              <Stack
                padding='16px 20px'
                height='47px'
                alignItems='center'
                justifyContent='space-between'
                direction='row'
                borderBottom='1px solid #D9D9D9'
              >
                {/* BANK NAME */}
                <Typography className={classes.bankName}>
                  {item?.payment?.bankCode?? 'Bank'}
                </Typography>
                {/* BANK LOGO */}
                <Box
                  src={getBankLogo(item)}
                  component='img'
                />
              </Stack>
  
              {/* CONTENT */}
              {/* VRITUAL ACCOUNT NO */}
              <Stack 
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='16px 20px'
              >
                <Typography variant='body2'>
                  Virtual Account No.
                </Typography>
  
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  <Typography className={classes.typography1}>
                    {item?.payment?.va?? '-'}
                  </Typography>
              
                  <ContentCopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(item?.payment?.va?? '-')
                      setSnackbarObject({
                        open: true,
                        severity: 'success',
                        title: '',
                        message: 'Successfully copied text',
                      })
                    }}
                    className={classes.copyIcon}
                  />
                </Stack>
  
              </Stack>
  
              {/* TOTAL PAYMENT */}
              <Stack 
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='0px 20px'
              >
                <Typography variant='body2'>
                  Total Payment
                </Typography>
  
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  <Typography className={classes.typography1}>
                    {handleTotalPayment(item.items)}
                  </Typography>
              
                  <ContentCopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(handleTotalPayment(item.items))
                      setSnackbarObject({
                        open: true,
                        severity: 'success',
                        title: '',
                        message: 'Successfully copied text',
                      })
                    }}
                    className={classes.copyIcon}
                  />
                </Stack>
              </Stack>
            </Stack>
  
            {/* COUNTDOWN TIME PAYMENT & SEE DETAIL */}
            <Stack 
              marginTop='20px'
              direction='row'
              alignitmes='center'
              justifyContent='space-between'
            >
              {/* COUNTDOWN TIME */}
              <Stack justifyContent='center' className={classes.countDownTime}>
                <Typography id={`countDown${index}`} className={classes.countDownText}>
                  {handleCountDownTime(item?.payment?.expiryDate, index)}
                </Typography>
              </Stack>
  
              {/* SEE DETAIL */}
              <Stack 
                direction='row'
                alignItems='center'
                spacing={1.5}
              >
                <Button 
                  onClick={() => navigate(`/check-order/unpaid/${item.id}`)}
                  variant='outlined'>
                  See Detail
                </Button>
  
                <IconButton onClick={(event) => handleHorizonIconClick(event,item)}>
                  <MoreHorizIcon/>
                </IconButton>
  
                {/* MENU ITEMS */}
                <Menu
                  anchorEl={item.anchor}
                  open={Boolean(item.anchor)}
                  onClose={() => handleMenuItemClose(item)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={(event) => handleCancelOrderClick(event, item)}>Cancel Order</MenuItem>
                  <MenuItem onClick={() => handleHowToPaymentButton(item)}>How To Payment</MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </Stack>
        ))}

        {/* NLE DEFAULT BACKGROUND IMAGE */}
        {listData.length === 0 && 
        <Stack 
          width='100%'
          height='200%'
          justifyContent='center'
          alignItems='center'
        >
          <Box
            src={MainBackground}
            component='img'
          />
        </Stack>}

        {/* FOOTER */}
        <CheckOrderFooter styling={stylingFooter}/>

        {/* DIALOG CONFIRMATION */}
        <DialogConfirmation
          isDialogConfirmationOpen={isDialogConfirmationOpen}
          setIsDialogConfirmationOpen={setIsDialogConfirmationOpen}
          handleContinueButtonClick={handleContinueButtonClick}
          handleBackButtonClick={handleBackButtonClick}
          dialogConfirmationTitle='Are you sure you want to cancel the transaction?'
          dialogConfirmationDescription='Confirm Your Transaction Cancellation'
          cancelButtonText='Back'
          continueButtonText='Yes, Cancel'
        />

        {/* LOADING COMPONENT */}
        <LoadingComponent isLoading={isLoading}/>
      </Box>
    </Fade>
  )
}

export default UnpaidCard