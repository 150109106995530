import { useNavigate } from 'react-router-dom'
import { useState, useContext } from 'react'

// ASSETS
import LogoproductNleConnect from 'assets/images/logos/product-nle-connect.svg'

// COMPONENTS
import BookingAuthentication from 'components/BookingAuthentication/BookingAuthentication'
import OTPMobileVerivication from 'components/OTPMobileVerification/OTPMobileVerification'
import ContactDetail from 'components/ContactDetailModal/ContactDetail'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './headerUseStyles'
import { Typography } from '@mui/material'

const Header = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { bookingContactDetail } = useContext(AllPagesContext)

  const [isBookingAuthOpen, setIsBookingAuthOpen] = useState(false)
  const [isOtpMobileOpen, setIsOtpMobileOpen] = useState(false)
  const [isContactDetailOpen, setIsContactDetailOpen] = useState(false)

  const handleContactDetailNavigate = () => {
    navigate('/check-order')
  }

  return (
    <>
      {/* APP BAR */}
      <Stack 
        className={classes.appBarMainContainer}
        alignItems='center'
      >
        <Stack 
          className={classes.appBarContainer}
          direction='row'
          justifyContent='space-between'
        >
          {/* NLE CONNECT LOGO */}
          <Box
            component='img'
            src={LogoproductNleConnect}
            className={classes.productLogo}
            onClick={() => navigate('/')}
          />

          {/* CHECK ORDER */}
          <Button 
            className={classes.logInBtn}
            variant='contained'
            onClick={() => setIsBookingAuthOpen(true)}
          >
            <Typography>Log in</Typography>
          </Button>
        </Stack>
      </Stack>
      
      {/* BOOKING AUTHENTICATION */}
      <BookingAuthentication
        isBookingAuthOpen={isBookingAuthOpen}
        setIsBookingAuthOpen={setIsBookingAuthOpen}
        setIsOtpMobileOpen={setIsOtpMobileOpen}
      />

      {/* OTP MOBILE VERIFICATION */}
      <OTPMobileVerivication
        isOtpMobileOpen={isOtpMobileOpen}
        setIsOtpMobileOpen={setIsOtpMobileOpen}
        phoneNumber={`0${bookingContactDetail.phoneNumber}`}
        setIsContactDetailOpen={setIsContactDetailOpen}
      />

      {/* CONTACT DETAIL MODAL */}
      <ContactDetail
        isContactDetailOpen={isContactDetailOpen}
        setIsContactDetailOpen={setIsContactDetailOpen}
        handleContactDetailNavigate={handleContactDetailNavigate}
      />
    </>
  )
}

export default Header