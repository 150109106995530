import { useState, useEffect } from 'react'
import moment from 'moment'

// STYLES
import useStyles from './dateRangePickerUSeStyles'

// MUIS
import { 
  IconButton,
  Stack, 
  Typography, 
  TextField, 
  Box, 
  Button 
} from '@mui/material'

// MUI DATE PICKERS
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker'

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close'

const DateRangePicker = (props) => {
  const { 
    setIsDateRangeTimePickerOpen, 
    dateRangeValue, 
    setDateRangeValue,
    setDateRangeInputValue,
  } = props

  const classes = useStyles() 

  const dateRangeList = ['Last 7 days','Last 14 days', 'Last 30 days']

  const [ tempValue, setTempValue ] = useState(dateRangeValue ? dateRangeValue : [null, null])
  const [ tempInputValue, setTempInputValue ] = useState('')
  const [ selectedDateRangeitem, setSelectedDateRangeItem] = useState('Last 14 days')
  const [ countDays, setCountDays ] = useState(0)

  const getSelectedDays = () => {
    if (countDays) {
      if (countDays === 1) return 'Selected 1 day'
      else return `Selected ${countDays} days`
    } 
    else return 'Selected 0 days'
  }

  const handleDateRangePickerChange = (inputNewValue) => {
    setTempInputValue(`${moment(inputNewValue[0]).format('DD MMM YYYY')} - ${moment(inputNewValue[1]).format('DD MMM YYYY')}`)
    setTempValue(inputNewValue)
  }

  const handleDateRangeItemClick = (inputItem, inputIndex) => {
    let startDate
    let endDate

    if (inputItem === 'Last 7 days') {
      startDate = moment().subtract(6, 'days').toDate()
      endDate = moment().endOf('day').toDate()
    } 
    else if (inputItem === 'Last 14 days') {
      startDate = moment().subtract(13, 'days').toDate()
      endDate = moment().endOf('day').toDate()
    } 
    else if (inputItem === 'Last 30 days') {
      startDate = moment().subtract(29, 'days').toDate()
      endDate = moment().endOf('day').toDate()
    } 

    setSelectedDateRangeItem(inputItem)
    setTempValue([startDate, endDate])
    setTempInputValue(`${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`)
  }

  useEffect(() => {
    setCountDays(moment(tempValue[1]).diff(tempValue[0], 'days') + 1)
  }, [
    tempValue[0],
    tempValue[1],
  ])

  return (
    <Stack className={classes.root}>
      {/* HEADER */}
      <Stack 
        direction='row'
        height='69px'
        justifyContent='space-between'
        alignItems='center'
        borderBottom='1px solid #EFEFEF'
        padding='22px'
      >
        {/* TITLE */}
        <Typography className={classes.headerTitle}>
          Select Period
        </Typography>

        {/* CLOSE ICON */}
        <IconButton onClick={() => setIsDateRangeTimePickerOpen(false)}>
          <CloseIcon/>
        </IconButton>

      </Stack>

      {/* CONTENT */}
      <Stack 
        direction='row'
        height='100%'
      >
        {/* CONTENT LEFT */}
        <Stack
          width='194px'
          borderRight='solid 1px #EFEFEF'
          paddingTop='20px'
        >
          {dateRangeList.map((item, index) => (
            <Stack 
              onClick={() => handleDateRangeItemClick(item, index)}
              className={classes.dateRangeListContainer}
              key={index}
              sx={(theme) => (
                {borderLeft : selectedDateRangeitem === item ? `4px solid ${theme.palette.primary.main}` : '4px solid transparent'}
              )}
            >
              <Typography className={classes.typography1}>
                {item}
              </Typography>
            </Stack>
          ))}

        </Stack>

        {/* CONTENT RIGHT */}
        <Stack
          padding='22px'
          width='100%'
        >
          <Typography className={classes.typography2}>
            Date Range :
          </Typography>

          {/* CALENDAR */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateRangePicker
              className={classes.dateRangePicker}
              displayStaticWrapperAs='desktop'
              // value={value}
              value={tempValue}
              onChange={(newValue) => handleDateRangePickerChange(newValue)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>

          <Stack 
            direction='row'
            alignItems='center'
            marginTop='31px'
          >
            {/* COUNT DAYS */}
            <Typography className={classes.countDays}>
              {getSelectedDays()}
            </Typography>

            {/* BUTTONS */}
            <Button 
              onClick={() => setIsDateRangeTimePickerOpen(false)}
              className={classes.cancelButton}>
              Cancel
            </Button>
            <Button 
              onClick={() => {
                setDateRangeValue(tempValue)
                setIsDateRangeTimePickerOpen(false)
                setDateRangeInputValue(tempInputValue)
              }}
              className={classes.selectButton}>
              Select
            </Button>

          </Stack>

        </Stack>

      </Stack>
    </Stack>
  )
}

export default DateRangePicker