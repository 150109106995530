import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import ContactOrderIcon from 'assets/images/logos/order-contact-logo.svg'
import IndonesiaFlag from 'assets/images/logos/order-flag-logo.svg'
import PaperIcon from 'assets/images/logos/order-paper-logo.svg'

// COMPONENTS
import UnloadingHeader from 'components/BookingHeader/BookingHeader'
import UnloadingFooter from 'components/BookingFooter/BookingFooter'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// MUI ICONS 
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import IconInfo from '@mui/icons-material/InfoOutlined'

// SERVICE 
import { getDepoListItem, getInswData } from 'service/booking'

// STYLES
import useStyles from './orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'
import { removeUserProfileFromLocalStorage } from 'utilities/localStorage'

const Order = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const {
    unloadingOrderForm, setUnloadingOrderForm,
    unloadingItemList, setUnloadingItemList,
    bookingHeaderData, setSecondarySnackBarObject,
    auth, setAuth
  } = useContext(AllPagesContext)

  const [depoListItem, setDepoListItem] = useState([])
  const [isValidatingForms, setIsValidatingForms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // FUNCTION HANDLE SESSION EXPIRED
  const handleJwtTokenExpired = () => {
    setSecondarySnackBarObject({
      open: true,
      severity: 'info',
      message: 'Session Expired. Please Log in again.'
    })
    removeUserProfileFromLocalStorage()
    setAuth({})
    navigate('/')
  }

  // HANDLE ORDER FORMS CHANGE
  const handleOrderForm = (event) => {
    setUnloadingOrderForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  // HANDLE CONTAINER NUMBER CHANGE
  const handleContainerNoForm = (inputItem, inputEvent) => {
    const newList = [...unloadingItemList].map((item) => {
      if (item.id === inputItem.id) item.containerNo = inputEvent.target.value
      return item
    })
    setUnloadingItemList(newList)
  }

  // HANDLE ORDER ITEMS CHANGE
  const handleContainerTypeForm = (inputEvent, inputId, inputValue) => {
    // SET CONTAINER TYPE LIST
    inputEvent.stopPropagation()
    const newList = [...unloadingItemList].map((item) => {
      if (item.id === inputId) {
        item.containerType = inputValue?.item_name
        item.sku = inputValue?.sku
        item.price = inputValue?.price
        item.description = inputValue?.description
        item.value = { ...inputValue }
        item.fleet = inputValue.fleet.name
      }
      return item
    })
    setUnloadingItemList(newList)
  }

  // HANDLE ADD NEW ORDER ITEM
  const handleAddNewItemForm = () => {
    let newForm = {
      titleNo: 'Container No.',
      titleType: 'Items',
      containerNo: '',
      containerType: '',
      price: 0,
      sku: '',
      description: '',
      id: Math.floor(Math.random() * 1000000),
    }

    setUnloadingItemList(current => [...current, newForm])
  }

  // HANDLE DELETE ORDER ITEM
  const deleteContainerTypeForm = (inputIndex) => {
    const newList = [...unloadingItemList]
    newList.splice(inputIndex, 1)
    setUnloadingItemList(newList)
  }

  // HANDLE CHECKOUT BUTTON
  const handleCheckoutButton = () => {
    // CHECK IS ITEMS CHOOSED & CHECK CONTAINER NUMBER FIELD
    let isItemChoosed = true
    unloadingItemList.forEach((item) => {
      if (item.fleet === '' || item.containerType === '' || item.containerNo === '') isItemChoosed = false
    })

    // VALIDATING ALL FORMS
    if (
      unloadingOrderForm.fullName === '' || unloadingOrderForm.phoneNumber === '' ||
      unloadingOrderForm.email === '' || unloadingOrderForm.bolNo === '' ||
      unloadingOrderForm.consignee === '' || unloadingOrderForm.npwp === '' ||
      unloadingOrderForm.address === '' || !isItemChoosed
    ) {
      setSecondarySnackBarObject({
        open: true,
        severity: 'error',
        message: 'Required fields must be filled in'
      })
      setIsValidatingForms(true)
    }

    else {
      setIsValidatingForms(false)
      navigate('/unloading/checkout')
    }
  }

  // FETCH DEPO LIST ITEM
  const loadDepoListItem = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      id: bookingHeaderData?.depo.id,
      type: 'UNLOADING'
    }
    const resultLoadDepoListItem = await getDepoListItem(inputAbortController.signal, queryParams)

    if (resultLoadDepoListItem.status === 200 && inputIsMounted) {
      setDepoListItem(resultLoadDepoListItem.data)
    }
  }

  // HANDLE TOTAL PRICE
  let totalPrice = 0
  for (let i = 0; i < unloadingItemList.length; i++) {
    totalPrice += unloadingItemList[i].price
  }

  // HANDLE TOTAL ITEM
  let totalItem = 0
  for (let i = 0; i < unloadingItemList.length; i++) {
    if (unloadingItemList[i].value !== undefined) totalItem++
  }

  // HANDLE CHECK BOL NUMBER
  const handleCheckBOL = async () => {
    const abortController = new AbortController()
    setIsLoading(true)
    const resultGetInswData = await getInswData(abortController.signal, auth.accessToken, unloadingOrderForm.bolNo, bookingHeaderData?.depo?.id)

    // PARSING DATA ITEMS FROM INSW TO FORMS
    let tempListItems = []

    depoListItem.forEach((item) => {
      resultGetInswData?.data?.container?.filter((item) => item.activeStatus === true)?.forEach((newItem) => {
        if (newItem?.itemResponse?.id === item?.id) tempListItems.push({
          titleNo: 'Container No.',
          titleType: 'Items',
          id: Math.floor(Math.random() * 1000000),
          containerNo: newItem?.noContainer,
          containerType: item?.item_name,
          description: item?.description,
          sku: item.sku,
          value: item,
          price: item?.price,
        })
      })
    })

    if (resultGetInswData.status === 200 && tempListItems.length > 0) {
      setIsLoading(false)
      setSecondarySnackBarObject({
        open: true,
        severity: 'info',
        message: `Data from Bill of Lading No. ${unloadingOrderForm.bolNo} found`
      })

      // PARSING DATA TO ORDER DETAIL FORMS
      setUnloadingOrderForm(current => {
        return {
          ...current,
          consignee: resultGetInswData?.data?.consignee,
          npwp: resultGetInswData?.data?.npwpConsignee,
        }
      })

      // PARSING DATA TO ORDER DETAIL ITEMS
      setUnloadingItemList(tempListItems)
    }

    // HANDLE SESSION JWT EXPIRED
    else if (resultGetInswData.status === 403 && resultGetInswData.data.error === 'Forbidden') {
      handleJwtTokenExpired()
    }

    else {
      setSecondarySnackBarObject({
        open: true,
        severity: 'error',
        message: `Data from Bill of Lading No. ${unloadingOrderForm.bolNo} not found`
      })
    }

    setIsLoading(false)
  }

  // SIDE EFFECT FETCH DEPO LIST ITEM
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoListItem(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  // SIDE EFFECT PARSING CONTACT DETAIL DATA
  useEffect(() => {
    setUnloadingOrderForm(current => {
      return {
        ...current,
        phoneNumber: auth?.phoneNumber?.slice(1),
        fullName: auth.fullName,
        email: auth.email,
      }
    })
  }, [])

  return (
    <Stack
      marginTop='24px'
      width='100%'
      maxWidth='1140px'
      padding='0px 50px'
      position='relative'
    >
      {/* HEADER COMPONENT */}
      <UnloadingHeader type='unloading' />

      {/* CONTACT ORDER */}
      <Stack className={classes.contactOrderDetail}>
        {/* TITLE */}
        <Stack
          direction='row'
          alignItems='center'
          borderBottom='solid 1px #D9D9D9'
          padding='16px 24px '
        >
          <Box
            width='38px'
            component='img'
            src={ContactOrderIcon}
            alt=''
          />
          <Typography className={classes.contactOrderTitle}>
            Contact Details
          </Typography>
        </Stack>

        {/* FORMS */}
        <Stack padding='20px 24px 4px 24px'>
          {/* FULL NAME */}
          <Typography variant='body1' marginBottom='12px'>
            Full Name<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            required
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField
              error={(isValidatingForms && unloadingOrderForm.fullName === '') ? true : false}
              required
              placeholder='Enter full name'
              type='text'
              name='fullName'
              value={unloadingOrderForm.fullName}
              onChange={(event) => handleOrderForm(event)}
              disabled
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.fullName === '') &&
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* PHONE NUMBER */}
          <Typography variant='body1' marginBottom='12px'>
            Phone Number<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField
              disabled
              placeholder='Enter phone number'
              type='number'
              name='phoneNumber'
              className={classes.fieldNumber}
              InputProps={{
                startAdornment: <InputAdornment position='start' >
                  <Stack alignItems='center' direction='row' paddingRight='14px' borderRight='solid 1px #D9D9D9'>
                    <Box component='img' src={IndonesiaFlag} />
                    <Typography marginLeft='4px'> +62 </Typography>
                  </Stack>
                </InputAdornment>
              }}
              value={unloadingOrderForm.phoneNumber}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.phoneNumber === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.phoneNumber === '') &&
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* EMAIL */}
          <Typography variant='body1' marginBottom='12px'>
            Email<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField
              placeholder='Enter email'
              type='email'
              name='email'
              value={unloadingOrderForm.email}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.email === '') ? true : false}
              disabled
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.email === '') &&
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>

      {/* ORDER DETAIL */}
      <Stack className={classes.orderDetail}>
        {/* TITLE */}
        <Stack
          direction='row'
          alignItems='center'
          borderBottom='solid 1px #D9D9D9'
          padding='16px 24px '
        >
          <Box
            width='38px'
            component='img'
            src={PaperIcon}
            alt=''
          />
          <Typography className={classes.contactOrderTitle}>
            Order Details
          </Typography>
        </Stack>

        {/* FORMS */}
        <Stack padding='20px 24px 4px 24px'>
          {/* BOL NO */}
          <Stack
            direction='row'
            spacing={3}
            alignItems='center'
          >
            {/* FORM */}
            <Stack width='100%'>
              <Typography variant='body1' marginBottom='12px'>
                Bill of Lading No.<span className={classes.superscript}>*</span>
              </Typography>

              <FormControl
                variant='outlined'
                hiddenLabel
                className='formTextField'
                color='secondary'
                error
              >
                <TextField
                  placeholder='Enter bill of lading no.'
                  type='tex'
                  name='bolNo'
                  value={unloadingOrderForm.bolNo}
                  onChange={(event) => handleOrderForm(event)}
                  error={(isValidatingForms && unloadingOrderForm.bolNo === '') ? true : false}
                />
                {/* HELPER TEXT */}
                {(isValidatingForms && unloadingOrderForm.bolNo === '') &&
                  <FormHelperText>This field can't be empty</FormHelperText>}
              </FormControl>
            </Stack>

            {/* CHECK BUTTON */}
            <Stack
              width='110px'
              paddingTop='16px'
            >
              <Button
                variant='contained'
                sx={{ height: '47px' }}
                disabled={unloadingOrderForm.bolNo === '' ? true : false}
                onClick={handleCheckBOL}
              >
                Check
              </Button>
            </Stack>
          </Stack>

          {/* CONSIGNEE */}
          <Typography variant='body1' marginBottom='12px'>
            Consignee<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField
              placeholder='Enter consignee'
              type='tex'
              name='consignee'
              value={unloadingOrderForm.consignee}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.consignee === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.consignee === '') &&
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* NPWP */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField
              placeholder='Enter NPWP'
              type='number'
              name='npwp'
              className={classes.fieldNumber}
              value={unloadingOrderForm.npwp}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.npwp === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.npwp === '') &&
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ADDRESS */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP Company Address<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField
              placeholder='Enter address'
              type='tex'
              name='address'
              value={unloadingOrderForm.address}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.address === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.address === '') &&
              <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ITEMS */}
          {unloadingItemList.map((item, index) => (
            <Stack key={index}>
              {/* ITEM FORM */}
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                {/* FORM */}
                <Stack direction='row' spacing={4} alignItems='flex-start'>
                  {/* CONTAINER TYPE */}
                  <Stack>
                    <Typography variant='body1' marginBottom='12px'>
                      {item.titleType}<span className={classes.superscript}>*</span>
                    </Typography>

                    <Stack marginBottom='20px'>
                      <Autocomplete
                        disablePortal
                        disableClearable
                        options={depoListItem}
                        sx={{ width: 480 }}
                        value={item.value || null}
                        isOptionEqualToValue={(option, value) => option.item_name.id === value.item_name.id}
                        onChange={(event, value) => handleContainerTypeForm(event, item.id, value)}
                        renderInput={(params) => <TextField {...params} placeholder='Select items' />}
                        getOptionLabel={(option) => `${option?.item_name?.itemCode} - ${option?.item_name?.itemType}`}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Stack width='100%'>
                              {/* ITEM NAME & PRICE */}
                              <Stack
                                direction='row'
                                justifyContent='space-between'
                              >
                                <Typography
                                  variant='body2'
                                  sx={{ fontWeight: 500 }}
                                >
                                  {option?.item_name?.itemCode} - {option?.item_name?.itemType}
                                </Typography>

                                <Typography variant='caption'>
                                  {`Rp. ${addSeparatorsForNumber(option.price)}`}
                                </Typography>
                              </Stack>

                              {/* FLEET MANAGER */}
                              <Typography variant='caption'>
                                {option?.fleet?.fleet_manager_company}
                              </Typography>

                              {/* SKU */}
                              <Typography variant='caption'>
                                {option.sku}
                              </Typography>
                            </Stack>
                          </li>
                        )}
                      />
                      {/* CUSTOM HELPER TEXT */}
                      {(item?.value?.item_name?.id === undefined && isValidatingForms) &&
                        <Typography
                          margin='6px 12px'
                          color='error'
                          variant='caption'
                        >
                          This field can't be empty
                        </Typography>}
                    </Stack>
                  </Stack>

                  {/* CONTAINER NO */}
                  <Stack
                    width='100%'
                    maxWidth='180px'
                  >
                    <Typography
                      variant='body1'
                      marginBottom='12px'
                      sx={(theme) => ({ color: theme.palette.text.secondary })}
                    >
                      {item.titleNo}
                    </Typography>

                    <FormControl
                      variant='outlined'
                      hiddenLabel
                      className='formTextField'
                      color='secondary'
                      error
                    >
                      <TextField
                        error={(isValidatingForms && item.containerNo === '') ? true : false}
                        placeholder='Enter container no.'
                        type='text'
                        name={`containerNo${index}`}
                        value={item.containerNo}
                        onChange={(event) => handleContainerNoForm(item, event)}
                      />
                      {/* HELPER TEXT */}
                      {(isValidatingForms && item.containerNo === '') &&
                        <FormHelperText>This field can't be empty</FormHelperText>}
                    </FormControl>
                  </Stack>
                </Stack>

                {/* TOTAL PRICE */}
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  {/* TOTAL PRICE */}
                  <Stack marginRight='45px'>
                    <Typography
                      variant='body1'
                      marginBottom='12px'
                      sx={(theme) => ({ color: theme.palette.text.secondary })}
                    >
                      Total Price
                    </Typography>
                    <Stack
                      height='48px'
                      marginBottom='20px'
                      justifyContent='center'
                      paddingLeft='67px'
                    >
                      <Typography variant='body1'>
                        {`Rp. ${addSeparatorsForNumber(item.price ?? '')}`}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* BUTTON DELETE ITEM */}
                  <Stack direction='row'>
                    <IconButton
                      onClick={() => deleteContainerTypeForm(index, item.id)}
                      disabled={unloadingItemList.length > 1 ? false : true}
                      disableRipple
                    >
                      <DeleteIcon
                        sx={{ color: unloadingItemList.length > 1 ? '#F96649' : '#00000033' }}
                        className={classes.addNewFromIcon}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>

              {/* ITEM DESCRIPTION */}
              {item.fleet !== '' && item.description !== '' &&
                <Stack
                  direction='row'
                  marginBottom='20px'
                  alignItems='center'
                >
                  {/* FLEET */}
                  <Stack width='480px' marginRight='30px' paddingLeft='16px'>
                    <Typography
                      variant='body2'
                      textTransform='uppercase'
                    >
                      {item?.value?.fleet?.fleet_manager_company ?? '-'}
                    </Typography>
                  </Stack>

                  {/* DESCRIPTION */}
                  <CustomTooltipBlack
                    followCursor
                    title={item.description ?? '-'}
                    placement='bottom'
                  >
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      width='100%'
                      maxWidth='400px'
                    >
                      <IconInfo className={classes.descriptionIcon} />

                      <Typography
                        variant='body2'
                        className={classes.descriptionText}
                      >
                        {item.description ?? '-'}
                      </Typography>
                    </Stack>
                  </CustomTooltipBlack>
                </Stack>}

              {/* DIVIDER */}
              <Divider sx={{ marginBottom: '20px' }} />
            </Stack>
          ))}

          {/* BUTTON ADD ITEM */}
          <Stack
            width='150px'
            marginBottom='24px'
          >
            <Button
              variant='outlined'
              size='large'
              startIcon={<AddIcon />}
              sx={{ textTransform: 'none' }}
              onClick={() => handleAddNewItemForm()}
            >
              Add Items
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {/* FOOTER COMPONENT */}
      <UnloadingFooter
        totalPrice={totalPrice}
        totalItem={totalItem}
        checkOutButton={handleCheckoutButton}
      />

      {/* LOADING COMPONENT */}
      <LoadingComponent isLoading={isLoading} />
    </Stack>
  )
}

export default Order