import React from 'react'

// ASSETS
import NleConectLogo from 'assets/images/logos/product-nle-connect.svg'
import TransportaLogo from 'assets/images/logos/landing-transporta.svg'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './leftSectionUseStyles'

const LeftSection = () => {
  const classes = useStyles()

  return (
    <Stack marginLeft='60px' paddingRight='80px'>
      {/* NLE CONNECT LOGO */}
      <Stack marginBottom='24.41px'>
        <Box
          component='img'
          src={NleConectLogo}
          className={classes.nleConnenctLogo}
        />
      </Stack>

      {/* BY TRANSPORTA */}
      <Stack direction='row'>
        <Stack>
          NLE connect by 
        </Stack>
        <Stack>
          <Box
            component='img'
            src={TransportaLogo}
            className={classes.transportaLogo}
          />
        </Stack>
      </Stack>
      <Stack>Jakarta, Indonesia</Stack>

      {/* ADDRESS */}
      <Stack className={classes.address}>
        PIK Avenue Mall, Lt 6, Jalan Pantai Indah Kapuk Boulevard, Kel. Kamal Muara, Kec. Penjaringan, Kota Adm. Jakarta Utara, Prov. DKI Jakarta
      </Stack>

      {/* CUSTOMER SERVICE */}
      <Stack className={classes.customerService}>
        <Stack className={classes.csTitle}>Layanan Pengaduan Konsumen NLE Connect</Stack>
        <Stack>E-mail: admin@nle-connect.id</Stack>
      </Stack>

      {/* SOCIAL MEDIA */}
      <Stack
        direction='row'
      >
        <Stack>
          <InstagramIcon className={classes.instagramIcon} cursor='pointer'/>
        </Stack>
        <Stack margin='0 30px'>
          <TwitterIcon className={classes.twitterIcon} cursor='pointer'/>
        </Stack>
        <Stack>
          <LinkedInIcon className={classes.linkedInIcon} cursor='pointer'/>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default LeftSection