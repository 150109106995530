import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// STYLES
import useStyles from './OTPMobileVerificationUseStyles'

// MUIS
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import InfoIcon from '@mui/icons-material/Info'

// MUI OTP INPUT 
import { MuiOtpInput } from 'mui-one-time-password-input'

// SERVICE 
import { verificationdOtpNumber, sendOtpNumber} from 'service/booking'

// UTILS 
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const MobileVerification = (props) => {
  const { 
    isOtpMobileOpen,
    setIsOtpMobileOpen,
    phoneNumber,
    setIsContactDetailOpen
  } = props

  const classes = useStyles()

  const navigate = useNavigate()

  const {
    setAuth,
    setSnackbarObject, 
    setBookingContactDetail
  } = useContext(AllPagesContext)

  const [timer, setTimer] = useState(60)
  const [otpNumber, setOtpNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOtpInvalid, setIsOtpInvalid] = useState(false)

  // FUNCTION VERIFICATION OTP NUMBER API
  const verifOtpNumber = async () => {
    const abortController = new AbortController()
    setIsLoading(true)

    const resultOtpNumber = await verificationdOtpNumber(abortController.signal, otpNumber, phoneNumber)
    
    // WHILE VERIFICATION SUCCESS
    if(resultOtpNumber.status === 200) {
      setOtpNumber('')
      setIsOtpMobileOpen(false)
      setIsLoading(false)
      setIsOtpInvalid(false)

      // IF EMAIL & FULL NAME !== NULL
      if(resultOtpNumber?.data?.email !== null && resultOtpNumber?.data?.full_name !== null){
        // SET AUTHENTICATION USER
        setUserProfileToLocalStorage({
          phoneNumber: phoneNumber,
          accessToken: resultOtpNumber?.data?.access_token,
          email: resultOtpNumber?.data?.email,
          fullName: resultOtpNumber?.data?.full_name,
        })
        setAuth({
          phoneNumber: phoneNumber,
          accessToken: resultOtpNumber?.data?.access_token,
          email: resultOtpNumber?.data?.email,
          fullName: resultOtpNumber?.data?.full_name
        })
        // NAVIGATE TO CHECK ORDER PAGE
        navigate('/check-order')
      } 
      // IF EMAIL & FULL NAME === NULL
      else {
      // SET AUTHENTICATION USER
        setUserProfileToLocalStorage({
          phoneNumber: phoneNumber,
          accessToken: resultOtpNumber?.data?.access_token,
          email: resultOtpNumber?.data?.email,
          fullName: resultOtpNumber?.data?.full_name
        })
        setAuth({
          phoneNumber: phoneNumber,
          accessToken: resultOtpNumber?.data?.access_token,
          email: resultOtpNumber?.data?.email,
          fullName: resultOtpNumber?.data?.full_name
        })
        // SET BOOKING CONTACT DETAIL
        setBookingContactDetail(current => ({
          ...current,
          email: resultOtpNumber?.data?.email,
          fullName : resultOtpNumber?.data?.full_name
        }))
        // OPEN CONTACT DETAILS COMPONENT
        setIsContactDetailOpen(true)
      }
    }

    // WHILE VERIFICATION CODE INVALID
    else if(resultOtpNumber.status === 404 && resultOtpNumber.data.status === 'NOT_FOUND') {
      setOtpNumber('')
      setIsLoading(false)
      setIsOtpInvalid(true)
    }

    else {
      setIsLoading(false)
      setOtpNumber('')
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to verify the OTP number, please click the resend button.',
      })
    }
  }

  // FUNCTION RESEND OTP NUMBER
  const handleResendOtpNumber = async () => {
    const abortController = new AbortController()

    // CALL SEND OTP NUMBER API
    const resultSendOtpNumber = await sendOtpNumber(abortController.signal, phoneNumber)

    if(resultSendOtpNumber.status !== 200) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to send OTP number',
      })
    }
  }

  useEffect(() => {
    if(isOtpMobileOpen === true) {
      let time = 60
      let count = setInterval(() => {
        if(time < 1) clearInterval(count)
        time-=1
        setTimer(current => current-=1)
      }, 1000)
      
    }
  }, [isOtpMobileOpen])

  useEffect(() => {
    if(otpNumber.length === 6) {
      verifOtpNumber()
    }
  }, [otpNumber])

  return (
    <>
      <Modal open={isOtpMobileOpen}>
        <Stack className={classes.root}>
          {/* MAIN TITLE */}
          <Typography className={classes.mainTitle}>
            Mobile Verification
          </Typography>

          {/* CAPTION */}
          <Typography 
            variant='body2'
            color='#0000008A'
            textAlign='center'
            marginBottom='30px'
          >
            {`Enter the 6 digit OTP that we sent to your registered number ${phoneNumber} to proceed with your order`}
          </Typography>

          {/* OTP INPUT */}
          <MuiOtpInput 
            className={classes.otpInput}
            value={otpNumber} 
            length={6}
            onChange={(value) => setOtpNumber(value)}
          />

          {/* INVALID OTP TEXT */}
          <Stack
            direction='row'
            alignItems='center'
            marginTop='30px'
            spacing={1}
          >
            {isOtpInvalid && <InfoIcon className={classes.invalidOtpIcon}/>}
            {isOtpInvalid && <Typography className={classes.invalidOtptext}>
              Invalid code. Please try again.
            </Typography>}
          </Stack>

          {/* RESEND TEXT */}
          <Stack 
            marginTop='20px' 
            direction='row'
            alignItems='center'
          >
            <Typography 
              variant='body2'
              color='#0000008A'
              textAlign='center'
            >
              Didn’t receive it?
            </Typography>
            {timer < 0 ? 
              <Typography 
                className={classes.resendOtpText}
                sx={{cursor: 'pointer'}}
                onClick={() => handleResendOtpNumber()}
              >
                Resend
              </Typography> :
              <Typography className={classes.resendOtpText}>
                {`Resend OTP in 0:${timer < 10 ? '0' : ''}${timer}`}
              </Typography>}
          </Stack>
        </Stack> 
      </Modal>
      
      {/* LOADING COMPONENT */}
      <LoadingComponent isLoading={isLoading}/>
    </>
  )
}

export default MobileVerification