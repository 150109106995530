// APIS
import axios from 'apis/axios'

// GET ALL DEPO LIST (HOME PAGE)
export const getAllDepoList = async (inputSignal) => {
  try {
    const response = await axios.get(
      '/api/booking/depo/active',
      { signal : inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET DEPO LIST ORDERS (CHECK - ORDERS PAGE)
export const getDepoListOrders = async (inputSignal, inputToken) => {
  try {
    const response = await axios.get(
      'api/booking/phone',
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`}
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// SEARCH BOOKING ORDERS (CHECK - ORDERS)
export const searchBookingOrders = async (inputSignal, inputQueryParams, inputBodyParams, inputToken) => {
  try {
    const response = await axios.post(
      `/api/booking/search?size=${inputQueryParams}`,
      inputBodyParams,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`}
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET DEPO LIST ITEM FOR CREATE THE NEW BOOKING ORDER (BOOKING)
export const getDepoListItem = async (inputSignal, inputQueryParams) => {
  try {
    const response = await axios.get(
      `api/booking/depo/item?depo_id=${inputQueryParams.id}&type=${inputQueryParams.type}`,
      { signal : inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// CREATE UNLOADING BOOKING ORDER (BOOKING)
export const createUnloadingBookingOrder = async (inputSignal, inputBodyParams, inputToken) => {
  try {
    const response = await axios.post(
      '/api/booking/unloading',
      inputBodyParams,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`}
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// CREATE LOAD BOOKING ORDER (BOOKING)
export const createLoadBookingOrder = async (inputSignal, inputBodyParams, inputToken) => {
  try {
    const response = await axios.post(
      '/api/booking/loading',
      inputBodyParams,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`}
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET DETAIL ORDER BY ID (CHECK - ORDER / DETAIL)
export const getDetailOrder = async (inputSignal, inputQueryParams, inputToken) => {
  try {
    const response = await axios.get(
      `/api/booking?booking_id=${inputQueryParams.id}&phone_number=${inputQueryParams.phoneNumber}`,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`}
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// SEND OTP
export const sendOtpNumber = async (inputSignal, inputQueryParams) => {
  try {
    const response = await axios.post(
      `api/customer/otp/send?phoneNumber=${inputQueryParams}`,
      { signal : inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// VERIFICATION OTP NUMBER
export const verificationdOtpNumber = async (inputSignal, inputOtpNumber, inputQueryParams) => {
  try {
    const response = await axios.post(
      `api/customer/otp/verif?otp=${inputOtpNumber}&phone_number=${inputQueryParams}`,
      { signal : inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// REGISTER NEW USER ACCOUNT
export const registerNewuserAccount = async (inputSignal, inputBodyParams, inputToken) => {
  try {
    const response = await axios.put(
      '/api/customer/update',
      inputBodyParams,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`}
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// SELECT PAYMENT METHOD
export const selectPaymentMethod = async (inputSignal, inputBodyParams, inputToken) => {
  try {
    const response = await axios.post(
      'api/booking/payment',
      inputBodyParams,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`}
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// DOWNLOAD INVOICE
export const downloadInvoice = async (inputSignal, inputQueryParams, inputToken) => {
  try {
    const response = await axios.get(
      `api/export/invoice/${inputQueryParams}`,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
        responseType: 'blob'
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// DOWNLOAD BON
export const downloadBon = async (inputSignal, inputQueryParams, inputToken) => {
  try {
    const response = await axios.get(
      `api/export/bon/${inputQueryParams}`,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
        responseType: 'blob'
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET XENDIT PAYMENT LIST
export const getXenditPaymentList = async (inputSignal, inputToken) => {
  try {
    const response = await axios.get(
      'api/payment/bookinglist',
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET XENDIT PAYMENT LIST
export const getXenditPaymenById = async (inputSignal, inputToken, inputQueryParams) => {
  try {
    const response = await axios.get(
      `api/payment?booking_id=${inputQueryParams}`,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET INSW DATA
export const getInswData = async (inputSignal, inputToken, inputBOL, inputDepoID) => {
  try {
    const response = await axios.get(
      `api/insw/${inputBOL}?depoId=${inputDepoID}`,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// CANCEL ORDER API
export const cancelBookingOrder = async (inputSignal, inputToken, inputID) => {
  try {
    const response = await axios.put(
      `api/booking/cancel?booking_id=${inputID}`,
      {},
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// CONVERT KEMENKEU TOKEN
export const convertKemenkeuToken = async (inputSignal,inputToken) => {
  try {
    const response = await axios.get(
      `api/nlekemenkeu/convert-token?token=${inputToken}`,
      { 
        signal : inputSignal,
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// UPDATE PROFILE
export const UpdateUserProfile = async (inputSignal, inputToken, inputBodyParams) => {
  try {
    const response = await axios.put(
      '/api/customer/update',
      inputBodyParams,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}