// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  arrowBackIcon: {
    color: theme.palette.primary.main
  },
  arrowBackText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  detailInvoiceContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: '0px 4px 40px 0px #0000000D',
    marginTop: 16,
  },
  typography1: {
    fontSize: 16,
    fontWeight: 500,
  },
  typography2: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  typography3: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  typography4: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  countDownTimerContainer: {
    padding: '8px 12px',
    backgroundColor: '#FEF3EB',
    borderRadius: 4,
    '& .MuiTypography-root': {
      fontSize: 13,
      fontWeight: 400,
      color: '#C77700'
    }
  },
  totalPaymentContainer: {
    marginTop: 24,
    height: 57,
    backgroundColor: '#F5F5F5',
    borderRadius: 4,
    borderLeft: 'solid 5px #4CAF50',
    padding:'8px 16px 8px 11px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalPaymentText: {
    fontSize: 18,
    fontWeight: 600,
  },
  orderDetailContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: '0px 4px 40px 0px #0000000D',
    marginTop: 24,
  },
  orderDetailTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  iconInfo: {
    width: 14,
    height: 14,
    color: theme.palette.text.secondary
  },
  itemName: {
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zoom: 0.85
  }
}))

export default useStyles
