import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import UnloadingLogo from 'assets/images/logos/order-unloading-logo.svg'
import LoadingLogo from 'assets/images/logos/order-loading-logo.svg'
import MainBackground from 'assets/images/backgrounds/check-orders-bg.svg'

// COMPONENTS
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'
import CheckOrderFooter from 'components/CheckOrderFooter/CheckOrderFooter'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MOMENT
import moment from 'moment'

// MUIS
import { 
  Box, 
  Button, 
  IconButton, 
  Menu, 
  MenuItem, 
  Stack, 
  Typography, 
  Fade,
} from '@mui/material'

// MUI ICONS
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DownloadIcon from '@mui/icons-material/Download'

// SERVICE
import { downloadInvoice, downloadBon } from 'service/booking'

// STYLES
import useStyles from './completedCardUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const CompletedCard = (props) => {
  const { paidListOrders } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { setSnackbarObject, auth } = useContext(AllPagesContext)

  const [listData, setListData] = useState([])
  const [isMounted, setIsMounted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // HANDLE CLOSE MENU ITEM
  const handleMenuItemClose = (inputItem) => {
    const newListData = [...listData].map((item) => {
      if(item.id === inputItem.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)
  }

  // HANDLE DOWNLOAD INVOICE
  const handleDownloadInvoice = async (inputItem) => {
    setIsLoading(true)
    const abortController =  new AbortController()

    // CLOSE MEN ITEM
    const newListData = [...listData].map((item) => {
      if(item.id === inputItem.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)

    // DOWNLOAD INVOICE
    const resultInvoice = await downloadInvoice(abortController.signal, inputItem?.id, auth?.accessToken)
    if(resultInvoice.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resultInvoice.data]))
      let alink = document.createElement('a')
      alink.href = url
      alink.download = 'Invoice.pdf'
      alink.click()

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully download the file',
      })

      setIsLoading(false)
    } else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed download the file',
      })
    }

  }

  // HANDLE DOWNLOAD BON 
  const handleDownloadBon = async (inputItem) => {
    setIsLoading(true)
    const abortController =  new AbortController()

    // CLOSE MENU ITEM
    const newListData = [...listData].map((item) => {
      if(item.id === inputItem.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)
  
    // DOWNLOAD BON
    const resultBon = await downloadBon(abortController.signal, inputItem?.id, auth?.accessToken)
    if(resultBon.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resultBon.data]))
      let alink = document.createElement('a')
      alink.href = url
      alink.download = 'BON.pdf'
      alink.click()
  
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully download the file',
      })
  
      setIsLoading(false)
    } else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed download file',
      })
    }
      
  }

  // SHOW MENU ITEM
  const handleDownloadIconClick = (inputEvent, inputItem) => {
    const newListData = [...listData].map((item) => {
      if(item.id === inputItem.id) item.anchor = inputEvent.currentTarget
      else item.anchor = null
      return item
    })
    setListData(newListData)
  }

  // TOTAL PAYMENT
  const handleTotalPayment = (inputItem) => {
    let total = 0
    inputItem.forEach((item) => total += item.price)
    
    return `Rp ${addSeparatorsForNumber(total)}`
  }

  // STYLING FOOTER
  const stylingFooter = {
    position: listData.length > 1 ? 'unset' : 'absolute', 
    bottom: listData.length > 1 ? 'unset' : '0px'
  }

  useEffect(() => {
    if(paidListOrders) {
      setListData(paidListOrders)
      setIsMounted(true)
    }
  }, [paidListOrders])
  
  return (
    <Fade in={isMounted} timeout={1000}>
      <Box>
        {listData.map((item, index) => (
          <Stack key={index} className={classes.root}>
            {/* BOOKING TYPE */}
            <Stack 
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              marginBottom='20px'
            >
              {/* LOGO & TITLE */}
              <Stack
                direction='row'
                alignItems='center'
              >
                <Box
                  src={item.booking_type === 'UNLOADING' ? UnloadingLogo : LoadingLogo}
                  component='img'
                />
                <Typography className={classes.bookingTypetitle}>
                  {item.booking_type === 'UNLOADING' ? 'Unloading' : 'Load'}
                </Typography>
              </Stack>
    
              {/* BOOKING ID */}
              <Typography className={classes.bookingID}>
                Booking ID {item.id}
              </Typography>
    
            </Stack>
    
            {/* DETAIL PAYMENT */}
            <Stack
              border='1px solid #D9D9D9'
              height='144px'
              borderRadius='4px'
            >
              {/* HEADER */}
              <Stack
                padding='16px 20px'
                height='47px'
                alignItems='center'
                direction='row'
                borderBottom='1px solid #D9D9D9'
              >
                {/* BANK NAME */}
                <Typography className={classes.depoName}>
                  {item?.depo?.organizationName}
                </Typography>
              </Stack>
    
              {/* CONTENT */}
              {/* LOADING DATE */}
              <Stack 
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='16px 20px'
              >
                <Typography variant='body2'>
                  {item.booking_type === 'LOADING' ? 'Load date' : 'Unloading date'}
                </Typography>
    
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  <Typography className={classes.typography1}>
                    {moment(item.tx_date).format('DD MMMM YYYY')?? '-'}
                  </Typography>
                
                  <ContentCopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(moment(item.tx_date).format('DD MMMM YYYY')?? '-')
                      setSnackbarObject({
                        open: true,
                        severity: 'success',
                        title: '',
                        message: 'Successfully copied text',
                      })
                    }}
                    className={classes.copyIcon}
                  />
                </Stack>
    
              </Stack>
    
              {/* TOTAL PAYMENT */}
              <Stack 
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='0px 20px'
              >
                <Typography variant='body2'>
                  Total Payment
                </Typography>
    
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  <Typography className={classes.typography1}>
                    {handleTotalPayment(item.items)}
                  </Typography>
                
                  <ContentCopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(handleTotalPayment(item.items))
                      setSnackbarObject({
                        open: true,
                        severity: 'success',
                        title: '',
                        message: 'Successfully copied text',
                      })
                    }}
                    className={classes.copyIcon}
                  />
                </Stack>
    
              </Stack>
    
            </Stack>
    
            {/* BOOKING STATUS & SEE DETAIL */}
            <Stack 
              marginTop='20px'
              direction='row'
              alignitmes='center'
              justifyContent='space-between'
            >
              {/* BOOKING STATUS */}
              <Stack justifyContent='center' className={classes.bookingStatus}>
                <Typography className={classes.bookingStatusText}>
                  Booking Successful
                </Typography>
              </Stack>
    
              {/* SEE DETAIL */}
              <Stack 
                direction='row'
                alignItems='center'
                spacing={1.5}
              >
                <Button 
                  onClick={() => navigate(`/check-order/completed/${item.id}`)}
                  variant='outlined'
                >
                  See Detail
                </Button>
    
                <IconButton 
                  onClick={(event) => handleDownloadIconClick(event,item)}
                >
                  <DownloadIcon/>
                </IconButton>
    
                {/* MENU ITEMS */}
                <Menu
                  anchorEl={item.anchor}
                  open={Boolean(item.anchor)}
                  onClose={() => handleMenuItemClose(item)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={() => handleDownloadInvoice(item)}>Download Invoice</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClose(item)}>Download Faktur Pajak</MenuItem>
                  <MenuItem onClick={() => handleDownloadBon(item)}>Download Bon {item.booking_type === 'UNLOADING' ? 'Bongkar' : 'Muat'}</MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </Stack>
        ))}

        {/* FOOTER */}
        <CheckOrderFooter styling={stylingFooter}/>

        {/* LOADING COMPONENT */}
        <LoadingComponent isLoading={isLoading}/>

        {/* NLE DEFAULT BACKGROUND IMAGE */}
        {listData.length === 0 && 
        <Stack 
          width='100%'
          height='200%'
          justifyContent='center'
          alignItems='center'
        >
          <Box
            src={MainBackground}
            component='img'
          />
        </Stack>}
      </Box>
    </Fade>
  )
}

export default CompletedCard