// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    width: '100%',
    height: '100vh',
    position: 'relative'
  },
  backButtonContainer: {
    width: 47,
    background: 'white',
    borderRadius: 500,
    height: 47,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '8px',
    '& .MuiSvgIcon-root' : {
      color: theme.palette.primary.main
    }
  },
  contactDetailContainer: {
    width: '100%',
    background: 'white',
    boxShadow: '0px 4px 40px 0px #0000000D',
    borderRadius: 4,
  },
  superscript: {
    color: theme.palette.primary.main
  },
  typography1: {
    fontSize: 16,
    fontWeight: 500
  },
  footer: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  }
}))

export default useStyles