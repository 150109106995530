// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
    height: '117px',
    backgroundColor: 'white',
    boxShadow: '0px 4px 40px 0px #0000000D',
    borderRadius: 4,
    marginTop: 24,
  },
  dialogDateRangePicker: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
    },
  },
}))

export default useStyles
