import PropTypes from 'prop-types'

// MUIS
import { Button, 
  Stack, 
  Typography 
} from '@mui/material'

// MUI ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// STYLES
import useStyles from './bookingFooterUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string' 

const LoadFooter = (props) => {
  const { checkOutButton,
    isWithPaymentDetail,
    paymentDetailClick,
    totalPrice,
    totalItem,
  } = props

  const classes = useStyles()

  return (
    <Stack
      bottom={0}
      position='fixed'
      width='100%'
    >
      <Stack
        className={classes.root}
        direction='row'
        alignItems='center'
        maxWidth='1040px'
        justifyContent='space-between'
      >
        <Typography className={classes.totalPayment}>
          Total Payment
        </Typography>

        <Stack 
          direction='row'
          spacing={2}
          alignItems='center'
        >
          <Stack 
            alignItems='center'
            direction='row'
            spacing={1}
          >
            <Typography className={classes.totalBill}>
              {`Rp ${addSeparatorsForNumber(totalPrice)}`}
            </Typography>

            {isWithPaymentDetail &&
              <KeyboardArrowDownIcon 
                onClick={paymentDetailClick}
                className={classes.arrowIcon}
              />
            }
          </Stack>

          <Button
            className={classes.checkoutButton}
            variant='contained'
            size='large'
            onClick={checkOutButton}
          >
            {isWithPaymentDetail ? 'Pay Now' : `Checkout ${totalItem !== 0 ? `(${totalItem})` : ''}`}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

LoadFooter.defaultProps = {
  isWithPaymentDetail: false,
  paymentDetailClick: () => {},
  totalPrice: 0,
  totalItem: 0,
}

LoadFooter.propTypes = {
  isWithPaymentDetail: PropTypes.bool.isRequired,
  checkOutButton: PropTypes.func.isRequired,
  paymentDetailClick: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  totalItem: PropTypes.number.isRequired,
}

export default LoadFooter