import React from 'react'

// MUIS
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './doalogConfirmationUseStyles'

const DialogConfirmation = (props) => {
  const { 
    isDialogConfirmationOpen,
    dialogConfirmationTitle,
    dialogConfirmationDescription,
    cancelButtonText,
    continueButtonText,
    handleBackButtonClick,
    handleContinueButtonClick,
  } = props
  const classes = useStyles()
  return (
    <Modal 
      disableAutoFocus 
      open={isDialogConfirmationOpen}
    >
      <Stack className={classes.root}>
        {/* TITLE */}
        <Typography 
          marginBottom='16px'
          textAlign='center'
          className={classes.typography1}
        >
          {dialogConfirmationTitle}
        </Typography>

        {/* DESCRIPTION */}
        <Typography
          textAlign='center'
          className={classes.typography2}
        >
          {dialogConfirmationDescription}
        </Typography>

        {/* BUTTONS */}
        <Stack
          justifyContent='space-between'
          alignItems='center'
          direction='row'
          spacing={1.5}
          marginTop='40px'
          width='100%'
        >
          {/* CANCEL BUTTON */}
          <Stack width='50%'>
            <Button 
              sx={{height: '47px'}}
              variant='contained'
              onClick={() => handleBackButtonClick()}
            >
              {cancelButtonText}
            </Button>
          </Stack>

          {/* CONTINUE BUTTON */}
          <Stack width='50%'>
            <Button 
              onClick={() => handleContinueButtonClick()}
              variant='contained'
              sx={{height: '47px'}}
            >
              {continueButtonText}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>

  )
}

export default DialogConfirmation