import { useState, useEffect, useContext } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

// ASSETS
import BackgroundImage from 'assets/images/backgrounds/main-background.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import BookingAuthentication from 'components/BookingAuthentication/BookingAuthentication'
import OTPMobileVerivication from 'components/OTPMobileVerification/OTPMobileVerification'
import ContactDetail from 'components/ContactDetailModal/ContactDetail'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'
import SecondarySnackbar from 'components/SecondarySnackbar/SecondarySnackbar'

// MUIS
import { Stack } from '@mui/material'

// SERVICE
import { convertKemenkeuToken } from 'service/booking'

// UTILS 
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const AuthFromKemenkeu = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const {token} = (Object.fromEntries([...searchParams]))

  const { 
    bookingContactDetail, setBookingContactDetail, 
    setAuth, secondarySnackBarObject,
    setSecondarySnackBarObject,
  } = useContext(AllPagesContext)

  const [isBookingAuthOpen, setIsBookingAuthOpen] = useState(false)
  const [isOtpMobileOpen, setIsOtpMobileOpen] = useState(false)
  const [isContactDetailOpen, setIsContactDetailOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // HANDLE CLOSE SECONDARY SNACKBAR
  const handleSecondarySnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return

    setSecondarySnackBarObject({
      open: false,
      severity: 'info',
      message: '',
    })
  }

  // HANDLE NAVIGATE TO BOOKING ORDERS
  const handleNavigateToBookingOrders = async () => {
    navigate('/check-order')
  }

  const handleConvertKemenkeuToken = async (inputSignal) => {
    setIsLoading(true)
    const resultData = await convertKemenkeuToken(inputSignal, token)

    // HANDLE UNREGISTERED PHONE NUMBER
    if(resultData.status === 400) {
      setIsLoading(false)
      setIsBookingAuthOpen(true)
    }

    // HANDLE REGISTERED PHONE NUMBER
    else if(resultData.status === 200) {
      setIsLoading(false)
      // SET AUTHENTICATION USER
      setUserProfileToLocalStorage({
        phoneNumber: resultData?.data?.phone_number,
        accessToken: resultData?.data?.access_token,
        email: resultData?.data?.email,
        fullName: resultData?.data?.full_name
      })
      setAuth({
        phoneNumber: resultData?.data?.phone_number,
        accessToken: resultData?.data?.access_token,
        email: resultData?.data?.email,
        fullName: resultData?.data?.full_name
      })

      // IF UNREGISTERED EMAIL & NAME
      if(resultData?.data?.email === null || resultData?.data?.full_name === null){
        // SET BOOKING CONTACT DETAIL
        setBookingContactDetail(current => ({
          ...current,
          email: resultData?.data?.email,
          fullName : resultData?.data?.full_name
        }))
        // OPEN CONTACT DETAILS COMPONENT
        setIsContactDetailOpen(true)
      }
      // IF REGISTERED EMAIL & NAME
      else if(resultData?.data?.email !== null && resultData?.data?.full_name !== null) {
        handleNavigateToBookingOrders()
      }
    }

    // HANDLE ERROR 500
    else if(resultData.status === 500) {
      setIsLoading(false)
      setSecondarySnackBarObject({
        open: true,
        severity: 'info',
        message: 'Your token from NLE Kemenkeu has expired',
      })
    }
  }

  useEffect(() => {
    const abortController = new AbortController()

    handleConvertKemenkeuToken(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Stack width='100%'>
      <Stack     
      // maxWidth='1440px'
        width='100%'
        height='117.5vh'
        alignItems= 'center'
        sx={{ 
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />

      {/* BOOKING AUTHENTICATION */}
      <BookingAuthentication
        isBookingAuthOpen={isBookingAuthOpen}
        setIsBookingAuthOpen={setIsBookingAuthOpen}
        setIsOtpMobileOpen={setIsOtpMobileOpen}
      />

      {/* OTP MOBILE VERIFICATION */}
      <OTPMobileVerivication
        isOtpMobileOpen={isOtpMobileOpen}
        setIsOtpMobileOpen={setIsOtpMobileOpen}
        phoneNumber={`0${bookingContactDetail.phoneNumber}`}
        setIsContactDetailOpen={setIsContactDetailOpen}
      />

      {/* CONTACT DETAIL MODAL */}
      <ContactDetail
        isContactDetailOpen={isContactDetailOpen}
        setIsContactDetailOpen={setIsContactDetailOpen}
        handleContactDetailNavigate={handleNavigateToBookingOrders}
      />

      {/* LOADING COMPONENT */}
      <LoadingComponent isLoading={isLoading}/>

      {/* SECONDARY SNACKBAR */}
      <SecondarySnackbar
        openSnackbar={secondarySnackBarObject?.open}
        handleClose={handleSecondarySnackbarClose}
        message={secondarySnackBarObject?.message}
      />
    </Stack>
  )
}

export default AuthFromKemenkeu