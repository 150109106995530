// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
    boxShadow: '0px 4px 40px 0px #0000000D',
    width: 416,
    height: 553,
  },
  typography1: {
    fontSize: 16,
    fontWeight: 600,
  },
  typography2: {
    fontSize: 14,
    fontWeight: 400,
  },
  typography3: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary
  }
}))

export default useStyles
