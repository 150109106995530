import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

// COMPONENTS
import BookingFinishOrder from 'components/BookingFinishOrder/FinishOrder'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

const FinishOrder = () => {
  const navigate = useNavigate()
  const { invoiceNumber } = useContext(AllPagesContext)
  
  return (
    <>
      <BookingFinishOrder
        invoiceNo={invoiceNumber}
        continueButton={() => navigate('/check-order')}
      />
    </>
  )
}

export default FinishOrder