import { useContext } from 'react'

// ASSETS
import ContactOrderIcon from 'assets/images/logos/order-contact-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import {
  Stack, 
  Box, 
  Typography, 
  Button
} from '@mui/material'

// MUI ICONS
import EditIcon from '@mui/icons-material/Edit'

// SYLES
import useStyles from '../reviewUseStyles'

const Contact = ({ setIsEditingContact }) => {
  const classes = useStyles()

  const {
    unloadingOrderForm
  } = useContext(AllPagesContext)
  return (
    <Stack className={classes.contactOrderDetail}>
      {/* TITLE */}
      <Stack 
        direction='row' 
        alignItems='center'
        borderBottom='solid 1px #D9D9D9'
        padding='16px 24px '
      >
        <Box 
          width='38px'
          component='img'
          src={ContactOrderIcon}
          alt=''
        />
        <Typography className={classes.contactOrderTitle}>
          Contact Details
        </Typography>
      </Stack>

      {/* NAME */}
      <Stack 
        direction='row'
        height='79px'
        alignItems='center'
        padding='0px 24px'
        borderBottom='solid 1px #D9D9D9'
        justifyContent='space-between'
      >
        <Typography>
          {unloadingOrderForm.fullName}
        </Typography>

        {/* NOTE : TAKE OUT BY REQUEST */}
        {/* <Button 
          variant='outlined' 
          startIcon={<EditIcon/>}
          onClick={() => setIsEditingContact(true)}
        >
          Edit
        </Button> */}
      </Stack>

      {/* PHONE NUMBER */}
      <Stack padding='24px'>
        <Typography 
          variant='body2' 
          color='#0000008A' 
          marginBottom='12px'
        >
          Phone Number
        </Typography>
        <Typography className={classes.typography1}>
          +62{unloadingOrderForm.phoneNumber}
        </Typography>
      </Stack>

      {/* EMAIL */}
      <Stack padding='0px 24px 24px 24px'>
        <Typography 
          variant='body2' 
          color='#0000008A' 
          marginBottom='12px'
        >
          Email
        </Typography>
        <Typography className={classes.typography1}>
          {unloadingOrderForm.email}
        </Typography>
      </Stack>

    </Stack>
  )
}

export default Contact