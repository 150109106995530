export const values = {
  // ALL PAGES
  fontFamily: [ 'Poppins', 'sans-serif' ].join(','),
  
  // SNACKBAR
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },

  // SECONDARY SNACKBAR
  initialSecondarySnackBarObject: {
    open: false,
    severity: 'info',
    message: '',
  },
}

// UNLOADING PAGE
export const initialUnloadingOrderForm = {
  fullName: '',
  phoneNumber: '',
  email: '',
  bolNo: '',
  npwp: '',
  address: '',
  consignee: '',
}

export const initialUnloadingItemList = [
  {
    titleNo : 'Container No.',
    titleType : 'Items',
    id: 1,
    containerNo: '',
    containerType: '',
    description: '',
    sku: '',
    price: 0,
  },
]

export const initialBookingHeaderData = {
  date : new Date(),
  depo: '',
}

// LOAD PAGE
export const loadInitialFormOrder = {
  fullName: '',
  phoneNumber: '',
  email: '',
  delivNo: '',
  npwp: '',
  address: '',
  consignee: '',
}

export const initialLoadItemList = [
  {
    titleQuantity : 'Quantity',
    titleType : 'Items',
    id: 1,
    quantity: 1,
    containerType: '',
    description: '',
    sku: '',
    price: 0,
  },
]

// BOOKING CONTACT ORDER
export const intialBookingContactDetail = {
  phoneNumber : '',
  email: '',
  fullName: '',
}