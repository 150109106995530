// LAYOUT
import Load from 'layout/Load/Load'

const LoadRoutes = ({children}) => {
  return (
    <Load>
      {children}
    </Load>
  )
}

export default LoadRoutes