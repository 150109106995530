import { useContext, useEffect, useState} from 'react'

// ASSETS
import PaperIcon from 'assets/images/logos/order-paper-logo.svg'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
  TextField,
} from '@mui/material'

// MUI ICONS
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import IconInfo from '@mui/icons-material/InfoOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import SaveIcon from '@mui/icons-material/Save'

// SERVICE
import { getDepoListItem } from 'service/booking'

// STYLES
import useStyles from '../../Order/orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string' 

const EditDetailOrder = (props) => {
  const { setIsEditingDetailOrder } = props
  const classes = useStyles()

  const {
    loadOrderForm, setLoadOrderFrom,
    loadItemList, setLoadItemList,
    bookingHeaderData, setSecondarySnackBarObject
  } = useContext(AllPagesContext)

  const [depoListItem, setDepoListItem] = useState([])
  const [isValidatingForms, setIsValidatingForms] = useState(false)

  // LOAD ITEM LIST DATA
  const loadDepoListItem = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      id: bookingHeaderData?.depo.id,
      type: 'LOADING'
    }
    const resultLoadDepoListItem = await getDepoListItem(inputAbortController.signal, queryParams)
        
    if(resultLoadDepoListItem.status === 200 && inputIsMounted) {
      setDepoListItem(resultLoadDepoListItem.data)
    }
  }

  const handleLoadOrderForm = (event) => {
    setLoadOrderFrom(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleLoadItemListChange = (inputEvent,inputId,inputValue) => {
    // SET NEW ITEM LIST
    inputEvent.stopPropagation()
    const newList = [...loadItemList].map((item) => {
      if(item.id === inputId) {
        item.containerType = inputValue?.item_name
        item.sku = inputValue?.sku
        item.price = inputValue?.price
        item.tempPrice = inputValue?.price
        item.description = inputValue?.description
        item.value = {...inputValue}
        item.fleet = inputValue.fleet.name
        item.quantity = 1
      }
      return item
    })
    setLoadItemList(newList)
  }

  const handleAddNewItemForm = () => {
    let newForm = {
      titleQuantity : 'Quantity',
      titleType : 'Items',
      quantity: 1,
      containerType: '',
      price: 0,
      sku: '',
      description: '',
      id : Math.floor(Math.random() * 1000000),
    }

    setLoadItemList(current => [...current, newForm ])
  }

  const deleteLoadItemList = (inputIndex) => {
    const newList = [...loadItemList]
    newList.splice(inputIndex,1)
    setLoadItemList(newList)
  }

  const handleSubstractQuantity = (inputEvent, inputItem) => {
    inputEvent.stopPropagation()
    
    const newList = [...loadItemList].map((item) => {
      if(item.id === inputItem.id && item.quantity > 1) {
        item.quantity--
        item.price -= item.tempPrice
      }
      return item
    })
    setLoadItemList(newList)
  }

  const handleAddQuantity = (inputEvent, inputItem) => {
    inputEvent.stopPropagation()
    
    const newList = [...loadItemList].map((item) => {
      if(item.id === inputItem.id) {
        item.quantity++
        item.price += item.tempPrice
      } 
      return item
    })
    setLoadItemList(newList)
  }

  // HANDLE SAVE BUTTON
  const handleSaveButton = () => {
    // VALIDATING ALL FORMS
    if(
      loadOrderForm.delivNo === '' || loadOrderForm.consignee === '' ||
      loadOrderForm.npwp === '' || loadOrderForm.address === ''
    ) {
      setSecondarySnackBarObject({
        open: true,
        severity: 'error',
        message: 'Required fields must be filled in'
      })
      setIsValidatingForms(true)
    }
    
    else {
      setIsValidatingForms(false)
      setIsEditingDetailOrder(false)
    }
  }  
  

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoListItem(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <>
      {/* ORDER DETAIL */}
      <Stack className={classes.orderDetail}>
        {/* TITLE */}
        <Stack 
          direction='row' 
          alignItems='center'
          borderBottom='solid 1px #D9D9D9'
          padding='16px 24px '
          justifyContent='space-between'
        >
          <Stack
            direction='row'
            alignItems='center'
          >
            <Box 
              width='38px'
              component='img'
              src={PaperIcon}
              alt=''
            />
            <Typography className={classes.contactOrderTitle}>
              Order Details
            </Typography>
          </Stack>

          {/* SAVE BUTTON */}
          <Button 
            variant='outlined' 
            startIcon={<SaveIcon/>}
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </Stack>

        {/* FORMS */}
        <Stack padding='20px 24px 4px 24px'>
          {/* DELIVERY ORDER NO */}
          <Typography variant='body1' marginBottom='12px'>
            Delivery Order No.<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter delivery order no.'
              type='tex'
              name='delivNo'
              value={loadOrderForm.delivNo}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.delivNo === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.delivNo === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
            
          {/* CONSIGNEE */}
          <Typography variant='body1' marginBottom='12px'>
            Shipper<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter shipper'
              type='tex'
              name='consignee'
              value={loadOrderForm.consignee}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.consignee === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.consignee === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* NPWP */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter NPWP'
              type='number'
              className={classes.fieldNumber}
              name='npwp'
              value={loadOrderForm.npwp}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.npwp === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.npwp === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ADDRESS */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP Company Address<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter address'
              type='tex'
              name='address'
              value={loadOrderForm.address}
              onChange={(event) => handleLoadOrderForm(event)}
              error={(isValidatingForms && loadOrderForm.address === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && loadOrderForm.address === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ITEMS */}
          {loadItemList.map((item,index) => (
            <Stack  key={index}>
              {/* ITEM FORM */}
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                {/* FORM */}
                <Stack direction='row' spacing={4} alignItems='center'>
                  {/* CONTAINER TYPE */}
                  <Stack>
                    <Typography variant='body1' marginBottom='12px'>
                      {item.titleType}<span className={classes.superscript}>*</span>
                    </Typography>

                    <Stack marginBottom='20px'>
                      <Autocomplete
                        disablePortal
                        options={depoListItem}
                        sx={{ width: 480 }}
                        value={item.value || null}
                        isOptionEqualToValue={(option, value)=> option.item_name.id === value.item_name.id}
                        onChange={(event,value) => handleLoadItemListChange(event, item.id, value)}
                        renderInput={(params) => <TextField {...params} placeholder='Select items' />}
                        getOptionLabel={(option) => `${option?.item_name?.itemCode} - ${option?.item_name?.itemType}`}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Stack width='100%'>
                              {/* ITEM NAME & PRICE */}
                              <Stack 
                                direction='row'
                                justifyContent='space-between'
                              >
                                <Typography 
                                  variant='body2'
                                  sx={{fontWeight: 500}}
                                >
                                  {option?.item_name?.itemCode} - {option?.item_name?.itemType}
                                </Typography>

                                <Typography variant='caption'>
                                  {`Rp. ${addSeparatorsForNumber(option.price)}`}
                                </Typography>
                              </Stack>

                              {/* FLEET MANAGER */}
                              <Typography variant='caption'>
                                {option?.fleet?.fleet_manager_company}
                              </Typography>

                              {/* SKU */}
                              <Typography variant='caption'>
                                {option.sku}
                              </Typography>
                            </Stack>
                          </li>
                        )}
                      />
                      {/* CUSTOM HELPER TEXT */}
                      {(item?.value?.item_name?.id === undefined && isValidatingForms) &&
                      <Typography 
                        margin='6px 12px'
                        color='error' 
                        variant='caption'
                      >
                        This field can't be empty
                      </Typography>}
                    </Stack>
                  </Stack>

                  {/* QUANTITY */}
                  <Stack width='180px'>
                    <Typography 
                      variant='body1' 
                      marginBottom='12px'
                      sx={(theme) => ({color: theme.palette.text.secondary})}
                    >
                      {item.titleQuantity}
                    </Typography>
                    
                    {/* QUANTITY COUNTER */}
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      height='54px'
                      marginBottom='20px'
                      border='1px solid #D9D9D9'
                      borderRadius='4px'
                    >
                      {/* SUBSTRACT BUTTON */}
                      <Stack className={classes.substrackButton}>
                        <IconButton onClick={(event) => handleSubstractQuantity(event,item)}>
                          <RemoveIcon/>
                        </IconButton>
                      </Stack>

                      {/* VALUE */}
                      <Stack
                        width='100%'
                        height='100%'
                        borderRight='1px solid #D9D9D9'
                        borderLeft='1px solid #D9D9D9'
                        alignItems='center'
                        justifyContent='center'
                      >
                        {item.quantity}
                      </Stack>

                      {/* ADD BUTTON */}
                      <Stack className={classes.substrackButton}>
                        <IconButton 
                          disabled={item?.value !== undefined ? false : true}
                          onClick={(event) => handleAddQuantity(event,item)}
                        >
                          <AddIcon/>
                        </IconButton>
                      </Stack>

                    </Stack>
                  </Stack>
                </Stack>

                {/* TOTAL PRICE */}
                <Stack 
                  direction='row' 
                  alignItems='center'
                >
                  {/* TOTAL PRICE */}
                  <Stack marginRight='45px'>
                    <Typography
                      variant='body1' 
                      marginBottom='12px'
                      sx={(theme) => ({color: theme.palette.text.secondary})}
                    >
                      Total Price
                    </Typography>
                    <Stack
                      height='48px'
                      marginBottom='20px'
                      justifyContent='center'
                      paddingLeft='67px'
                    >
                      <Typography variant='body1'>
                        {`Rp. ${addSeparatorsForNumber(item.tempPrice * item.quantity)}`}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* BUTTON DELETE ITEM */}
                  <Stack direction='row'>
                    <IconButton 
                      onClick={() => deleteLoadItemList(index,item.id)}
                      disabled={loadItemList.length > 1 ? false : true}
                      disableRipple
                    >
                      <DeleteIcon 
                        sx={{color: loadItemList.length > 1 ? '#F96649' : '#00000033'}} 
                        className={classes.addNewFromIcon}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>

              {/* ITEM DESCRIPTION */}
              {item.fleet !== '' && item.description !== '' &&
              <Stack 
                direction='row' 
                marginBottom='20px'
                alignItems='center'
              >
                {/* FLEET */}
                <Stack width='480px' marginRight='30px' paddingLeft='16px'>
                  <Typography 
                    variant='body2'
                    textTransform='uppercase'
                  >
                    {item?.value?.fleet?.fleet_manager_company?? '-'}
                  </Typography>
                </Stack>

                {/* DESCRIPTION */}
                <CustomTooltipBlack
                  followCursor 
                  title={item.description?? '-'}
                  placement='bottom'
                >
                  <Stack 
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    width='100%'
                    maxWidth= '390px'
                  >
                    <IconInfo className={classes.descriptionIcon}/>

                    <Typography
                      variant='body2'
                      className={classes.descriptionText}
                    >
                      {item.description?? '-'}
                    </Typography>
                  </Stack>
                </CustomTooltipBlack>
              </Stack>}

              {/* DIVIDER */}
              <Divider sx={{marginBottom: '20px'}}/>
            </Stack>
          ))}

          {/* BUTTON ADD ITEM */}
          <Stack 
            width='150px'  
            marginBottom='24px'
          >
            <Button 
              variant='outlined'
              size='medium'
              startIcon={<AddIcon/>}
              sx={{textTransform: 'none'}}
              onClick={() => handleAddNewItemForm()}
            >
              Add Items
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default EditDetailOrder