import { useContext, useState } from 'react'

// ASSETS
import ContactOrderIcon from 'assets/images/logos/order-contact-logo.svg'
import IndonesiaFlag from 'assets/images/logos/order-flag-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// MUI ICONS
import SaveIcon from '@mui/icons-material/Save'

// STYLES
import useStyles from '../../Order/orderUseStyles'

const EditContact = (props) => {
  const { setIsEditingContact } = props
  const classes = useStyles()
  const { 
    unloadingOrderForm, 
    setUnloadingOrderForm,
    setSecondarySnackBarObject
  } = useContext(AllPagesContext)

  const [isValidatingForms, setIsValidatingForms] = useState(false)

  // HANDLE ORDER FORMS CHANGE
  const handleOrderForm = (event) => {
    setUnloadingOrderForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  // HANDLE SAVE BUTTON CLICK
  const handleSaveButton = () => {
    // VALIDATING ALL FORMS
    if(
      unloadingOrderForm.fullName === '' || 
      unloadingOrderForm.phoneNumber === '' ||
      unloadingOrderForm.email === ''
    ) {
      setSecondarySnackBarObject({
        open: true,
        severity: 'error',
        message: 'Required fields must be filled in'
      })
      setIsValidatingForms(true)
    }
    else {
      setIsValidatingForms(false)
      setIsEditingContact(false)
    }
  }

  return (
    <>
      {/* CONTACT ORDER */}
      <Stack className={classes.contactOrderDetail}>
        {/* TITLE */}
        <Stack 
          direction='row' 
          alignItems='center'
          borderBottom='solid 1px #D9D9D9'
          padding='16px 24px '
          justifyContent='space-between'
        >
          <Stack 
            direction='row' 
            alignItems='center'
          >
            <Box 
              width='38px'
              component='img'
              src={ContactOrderIcon}
              alt=''
            />
            <Typography className={classes.contactOrderTitle}>
              Contact Details
            </Typography>
          </Stack>

          {/* SAVE BUTTON */}
          <Button 
            variant='outlined' 
            startIcon={<SaveIcon/>}
            onClick={() => handleSaveButton()}
          >
            Save
          </Button>
        </Stack>

        {/* FORMS */}
        <Stack padding='20px 24px 4px 24px'>
          {/* FULL NAME */}
          <Typography variant='body1' marginBottom='12px'>
            Full Name<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter full name'
              type='text'
              name='fullName'
              value={unloadingOrderForm.fullName}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.fullName === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.fullName === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* PHONE NUMBER */}
          <Typography variant='body1' marginBottom='12px'>
            Phone Number<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              disabled
              className={classes.fieldNumber}
              placeholder='Enter phone number'
              type='number'
              name='phoneNumber'
              InputProps={{
                startAdornment : <InputAdornment position='start' > 
                  <Stack alignItems='center' direction='row' paddingRight='14px' borderRight='solid 1px #D9D9D9'>
                    <Box component='img' src={IndonesiaFlag}/>
                    <Typography marginLeft='4px'> +62 </Typography>
                  </Stack>
                </InputAdornment>
              }}
              value={unloadingOrderForm.phoneNumber}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.phoneNumber === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.phoneNumber === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* EMAIL */}
          <Typography variant='body1' marginBottom='12px'>
            Email<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter email'
              type='email'
              name='email'
              value={unloadingOrderForm.email}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.email === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.email === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>
    </>
  )
}

export default EditContact