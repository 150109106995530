// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  appBarMainContainer: {
    background: '#FFFFFF',
    width: '100%',
    padding: '23px 0',
  },
  appBarContainer:{
    maxWidth: 1440,
    padding: '0px 135px',
    width: '100%'
  },
  productLogo: {
    height: 38,
    cursor: 'pointer'
  },
  checkOrderIcon: {
    color:'#F96649',
    height: '18px',
    marginRight:'4px',
  },
  logInBtn: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 16,
    width: 174,
    height: 47
  },
  formButton: {
    
  },
}))

export default useStyles