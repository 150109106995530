import React from 'react'

// COMPONENTS
import LeftSection from './LeftSection/LeftSection'
import RightSection from './RightSection/RightSection'
import Copyright from './Copyright/Copyright'

// MUIS
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './homepageFooterUseStyles'

const Footer = () => {

  return (
    <>
      <Stack
        alignItems='center'
        padding='70px'
        paddingBottom='20px'
      >
        {/* CONTENT */}
        <Stack
          direction='row'
          lineHeight='32px'
          maxWidth='1400px'
          width='100%'
          justifyContent='center'
        >
          <LeftSection/>

          <RightSection/>
        </Stack>
      </Stack>
      {/* COPYRIGHT */}
      <Copyright/>
    </>
  )
}

export default Footer