// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  checklistIcon: {
    width: 100,
    height: 100,
    color: '#4CAF50',
    marginBottom: 34,
  },
  tyography1: {
    fontSize: 18,
    fontWeight: 600
  },
  tyography2: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  }
}))

export default useStyles