import PropTypes from 'prop-types'

// MUIS
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

const SecondarySnackbar = (props) => {
  const { openSnackbar, handleClose, message, severity } = props
  
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ 
        vertical: 'top', 
        horizontal: 'center', 
      }}
      sx={{zoom: 0.85}}
    >
      <MuiAlert 
        onClose={handleClose} 
        elevation={6} 
        variant='filled' 
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

SecondarySnackbar.defaultProps = {
  openSnackbar: false,
  message: '',
  severity: 'info'
}

SecondarySnackbar.propTypes = {
  openSnackbar: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
}


export default SecondarySnackbar