import React from 'react'

// MUIS
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './copyrightUseStyles'

const Copyright = () => {
  const classes = useStyles()
  const dinamicYear = new Date().getFullYear()

  return(
    <Stack
      className={classes.copyright}
    >
      Copyright ©NLE Connect by Transporta.id {dinamicYear} All rights reserved
    </Stack>
  )
}

export default Copyright