// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    width: '100%',
    height: '100vh',
    position: 'relative'
  },
}))

export default useStyles