import { useContext, useState } from 'react'

// COMPONENTS
import CreateOrderModal from 'components/CreateOrderModal/CreateOrderModal'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import { 
  Stack, 
  Typography,
  Button,
} from '@mui/material'

// MUI ICONS
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// STYLES
import useStyles from './leftSectionUseStyles'

// UTILS
import { isObjectEmpty } from 'utilities/validation'

const LeftSection = () => {
  const { auth } = useContext(AllPagesContext)
  const classes = useStyles()

  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = useState(false)

  return (
    <Stack className={classes.root}>
      {/* TITLE */}
      <Stack 
        padding='21px 24px 25px 24px'
        borderBottom='solid 1px #D9D9D9'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        height='70px'
      >
        <Typography className={classes.typography1}>
          {isObjectEmpty(auth) ? 'Check Order' : 'Transaction List'}
        </Typography>

        <InfoOutlinedIcon className={classes.iconInfo}/>
      </Stack>

      {/* CONTENT */}
      <Stack
        marginTop='25px'
        marginLeft='24px'
        width='177px'
      >
        <Button
          variant='outlined'
          sx={{height: '43px'}}
          onClick={() => setIsCreateOrderModalOpen(true)}
        >
          Create New Order
        </Button>
      </Stack>

      {/* CREATE ORDER MODAL */}
      <CreateOrderModal 
        isCreateOrderModalOpen={isCreateOrderModalOpen} 
        setIsCreateOrderModalOpen={setIsCreateOrderModalOpen}
      />
    </Stack>
  )
}

export default LeftSection