import { useNavigate, useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

// ASSETS
import UnloadingLogo from 'assets/images/logos/order-unloading-logo.svg'
import LoadingLogo from 'assets/images/logos/order-loading-logo.svg'

// COMPONENT
import CheckOrderFooter from 'components/CheckOrderFooter/CheckOrderFooter'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import { Stack, IconButton, Typography, Box, Button } from '@mui/material'

// MUI ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// SERVICE
import { getDetailOrder, downloadInvoice, downloadBon, getXenditPaymenById } from 'service/booking'

// STYLES
import useStyles from './completedDetailPageUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'
import { removeUserProfileFromLocalStorage } from 'utilities/localStorage'

const CompletedDetailPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { auth, setSecondarySnackBarObject, setAuth, setSnackbarObject } = useContext(AllPagesContext)
  const { id } = useParams()

  // TAKE OUT BY REQUEST
  const fee = 0

  const [detailOrder, setDetailOrder] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [detailPayment, setDetailPayment] = useState(null)

  // HANDLE SESSION EXPIRED
  const handleSessionExpired = () => {
    setSecondarySnackBarObject({
      open: true,
      severity: 'info',
      message: 'Session Expired. Please Log in again.'
    })
    removeUserProfileFromLocalStorage()
    setAuth({})
    navigate('/')
  }

  // LOAD DETAIL ORDER DATA
  const loadDetailORder = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      id: id,
      phoneNumber: auth?.user
    }

    // FETCH DETAIL ORDER
    const resultLoadAllDepoList = await getDetailOrder(inputAbortController.signal, queryParams, auth?.accessToken)
    if(resultLoadAllDepoList.status === 200 && inputIsMounted) {
      setDetailOrder(resultLoadAllDepoList.data)
    }
    // HANDLE SESSION JWT EXPIRED
    else if(resultLoadAllDepoList.status === 403 && resultLoadAllDepoList.data.error === 'Forbidden') {
      handleSessionExpired()
    }

    // FETCH XENDIT VA
    const resultXenditVa = await getXenditPaymenById(inputAbortController.signal, auth?.accessToken, id )
    if(resultXenditVa.status === 200 && inputIsMounted) {
      setDetailPayment(resultXenditVa.data)
    }
  }

  // HANDLE TOTAL PRICE
  const handleTotalPrice = (inputItem) => {
    let total = 0
    inputItem?.forEach((item) => total += item.price)
    
    return `Rp ${addSeparatorsForNumber(total)}`
  }

  // HANDLE TOTAL PAYMENT
  const handleTotalPayment = (inputItem) => {
    let total = fee
    inputItem?.forEach((item) => total += item.price)
    
    return `Rp ${addSeparatorsForNumber(total)}`
  }

  // HANDLE DOWNLOAD INVOICE 
  const handleDownloadInvoice = async () => {
    setIsLoading(true)
    const abortController =  new AbortController()

    const resultInvoice = await downloadInvoice(abortController.signal, id, auth?.accessToken)
    if(resultInvoice.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resultInvoice.data]))
      let alink = document.createElement('a')
      alink.href = url
      alink.download = 'Invoice.pdf'
      alink.click()

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully download the file',
      })
      
      setIsLoading(false)
    } 
    // HANDLE SESSION JWT EXPIRED
    else if(resultInvoice.status === 403 && resultInvoice.data.error === 'Forbidden') {
      handleSessionExpired()
    }
    // HANDLE FAILED DOWNLOAD THE ITEM
    else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed download the file',
      })
    }
  }

  // HANDLE DOWNLOAD BON 
  const handleDownloadBon = async () => {
    setIsLoading(true)
    const abortController =  new AbortController()

    const resultBon = await downloadBon(abortController.signal, id, auth?.accessToken)
    if(resultBon.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resultBon.data]))
      let alink = document.createElement('a')
      alink.href = url
      alink.download = 'BON.pdf'
      alink.click()

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully download the file',
      })

      setIsLoading(false)
    } 
    // HANDLE SESSION JWT EXPIRED
    else if(resultBon.status === 403 && resultBon.data.error === 'Forbidden') {
      handleSessionExpired()
    } 
    // HANDLE FAILED DOWNLOAD THE ITEM
    else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed download file',
      })
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDetailORder(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])
  
  return (
    <Stack
      width='100%'
      height='100%'
      maxWidth='649px'
    >
      {/* BACK BUTTON */}
      <Stack
        direction='row'
        marginTop='24px'
        alignItems='center'
        spacing={1}
      >
        <IconButton onClick={() => navigate('/check-order')}>
          <ArrowBackIcon className={classes.arrowBackIcon}/>
        </IconButton>
        <Typography className={classes.arrowBackText}>
          Back
        </Typography>

      </Stack>

      {/* DETAIL INVOICE */}
      <Stack className={classes.detailInvoiceContainer}>
        {/* BOOKING ID */}
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding='24px'
          height='69px'
          borderBottom='1px solid #D9D9D9'
        >
          <Stack
            spacing={2}
            alignItems='center'
            direction='row'
          >
            <Box
              src={detailOrder?.booking_type === 'UNLOADING' ? UnloadingLogo : LoadingLogo}
              component='img'
            />
            <Typography className={classes.typography1}>
              {detailOrder?.booking_type === 'UNLOADING' ? 'Unloading' : 'Load'}
            </Typography>
          </Stack>
          {/* BOOKING ID */}
          <Typography className={classes.typography2}>
            Booking ID {detailOrder?.id}
          </Typography>

        </Stack>

        {/* STATUS */}
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding='24px'
          height='71px'
          borderBottom='1px solid #D9D9D9'
        >
          <Typography className={classes.typography1}>
            Status
          </Typography>

          <Stack className={classes.bookingStatus}>
            <Typography>
              {detailOrder?.booking_status === 'SUCCESS' ? 'Booking Successful' : '-'}
            </Typography>
          </Stack>
        </Stack>

        {/* INVOICE NUMBER */}
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding='24px'
          height='83px'
          borderBottom='1px solid #D9D9D9'
        >
          <Stack>
            <Typography className={classes.typography3}>
              Invoice
            </Typography>
            <Typography className={classes.typography1}>
              {detailOrder?.invoice_no?? '-'}
            </Typography>
          </Stack>
          {/* DOWNLOAD BUTTON */}
          <Button
            sx={{textTransform: 'none'}}
            startIcon={<FileDownloadIcon/>}
            variant='outlined'
            onClick={() => handleDownloadInvoice()}
          >
            Download
          </Button>
        </Stack>

        {/* FAKTUR PAJAK */}
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding='24px'
          height='83px'
          borderBottom='1px solid #D9D9D9'
        >
          <Stack>
            <Typography className={classes.typography3}>
              Faktur Pajak
            </Typography>
            <Typography className={classes.typography1}>
              {detailOrder?.faktur_pajak?? '-'}
            </Typography>
          </Stack>
          {/* DOWNLOAD BUTTON */}
          <Button
            sx={{textTransform: 'none'}}
            startIcon={<FileDownloadIcon/>}
            variant='outlined'
            disabled
          >
            Download
          </Button>
        </Stack>

        {/* BON ULOADING */}
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding='24px'
          height='83px'
          borderBottom='1px solid #D9D9D9'
        >
          <Stack>
            <Typography className={classes.typography3}>
              Bon Unloading
            </Typography>
            <Typography className={classes.typography1}>
              {detailOrder.bon_no ? `${detailOrder?.bon_no?.length} Bon` : '-'}
            </Typography>
          </Stack>
          {/* DOWNLOAD BUTTON */}
          <Button
            sx={{textTransform: 'none'}}
            startIcon={<FileDownloadIcon/>}
            variant='outlined'
            onClick={() => handleDownloadBon()}
          >
            Download
          </Button>
        </Stack>

        {/* TOTAL PAYMENT */}
        <Stack padding='24px'>
          {/* TOTAL PRICE */}
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            height='40px'
            paddingBottom='16px'
            borderBottom='1px dashed #D9D9D9'
          >
            <Typography className={classes.typography1}>
              Total Price
            </Typography>
            <Typography className={classes.typography1}>
              {handleTotalPrice(detailOrder?.items)}
            </Typography>
          </Stack>

          {/* FEE */}
          {/* <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            height='61px'
            padding='16px 0px 24px 0px'
            borderBottom='1px solid #D9D9D9'
          >
            <Typography className={classes.typography3}>
              Transaction Fee
            </Typography>
            <Typography className={classes.typography3}>
              Rp {addSeparatorsForNumber(fee)}
            </Typography>
          </Stack> */}

          {/* TOTAL PAYMENT */}
          <Stack 
            direction='row'
            className={classes.totalPaymentContainer}
          >
            <Stack>
              <Typography className={classes.typography1}>
                Total Payment
              </Typography>
              <Typography className={classes.typography4}>
                {detailPayment?.bank_code?? 'Bank'}
              </Typography>
            </Stack>

            <Typography className={classes.totalPaymentText}>
              {handleTotalPayment(detailOrder?.items)}
            </Typography>
          </Stack>

        </Stack>

      </Stack>

      {/* ORDER DETAIL */}
      <Stack className={classes.orderDetailContainer}>
        {/* HEADER */}
        <Stack 
          height='67px'
          borderBottom='1px solid #D9D9D9'
          justifyContent='center'
          padding='24px'
        >
          <Typography className={classes.orderDetailTitle}>
            Order Detail
          </Typography>

        </Stack>

        {/* CONTENT */}
        <Stack
          padding='20px 24px 24px 24px'
        >
          {/* BOL NO */}
          <Stack spacing={1} marginBottom='24px'>
            <Typography className={classes.typography3}>
              Bill of Lading No.
            </Typography>
            <Typography className={classes.typography1}>
              {detailOrder?.bill_landing}
            </Typography>
          </Stack>

          {/* ITEMS */}
          {detailOrder?.items?.map((item, index) => (
            <Stack
              key={index}
              paddingBottom='14px'
              marginBottom='16px'
              borderBottom='solid 1px #D9D9D9'
            >
              {/* DETAILS */}
              <Stack 
                direction='row'
                spacing={2}
                alignItems='center'
                justifyContent='space-between'
              >
                {/* ITEM NAME */}
                <Stack 
                  maxWidth='150px'
                  width='100%'
                  spacing={1}
                >
                  {index === 0 && <Typography className={classes.typography3}>
                    Items
                  </Typography>}
                  <CustomTooltipBlack
                    followCursor 
                    title={`${item?.item_name.itemCode} - ${item?.item_name.itemType}`}
                    placement='bottom'
                  >
                    <Stack>
                      <Typography className={classes.itemName}>
                        {item?.item_name.itemCode} - {item?.item_name.itemType}
                      </Typography>
                    </Stack>
                  </CustomTooltipBlack>
                </Stack>

                {/* CONTAINER NO */}
                <Stack 
                  maxWidth='150px'
                  width='100%'
                  spacing={1}
                >
                  {index === 0 && 
                  <Typography className={classes.typography3}>
                    {item?.container_number ? 'Container No.' : 'Quantity'}
                  </Typography>}
                  {item?.container_number && <Typography className={classes.typography1}>
                    {item?.container_number?? '-'}
                  </Typography>}
                  {item?.quantity && <Typography className={classes.typography1}>
                    {item?.quantity?? '-'}
                  </Typography>}
                </Stack>
                {/* SKU NO */}
                <Stack 
                  maxWidth='150px'
                  width='100%'
                  spacing={1}
                >
                  {index === 0 && <Typography className={classes.typography3}>
                    SKU
                  </Typography>}
                  <Typography className={classes.typography1}>
                    {item?.sku?? '-'}
                  </Typography>
                </Stack>
                {/* PRICE */}
                <Stack 
                  maxWidth='134px'
                  width='100%'
                  spacing={1}
                >
                  {index === 0 && <Typography className={classes.typography3}>
                    Price
                  </Typography>}
                  <Typography textAlign='end' className={classes.typography1}>
                    {addSeparatorsForNumber(item?.price)}
                  </Typography>
                </Stack>

              </Stack>

              {/* DESCRIPTION */}
              <Stack
                direction='row'
                spacing={0.5}
                alignItems='center'
                marginTop='8px'
              >
                <InfoOutlinedIcon className={classes.iconInfo}/>
                <Typography className={classes.typography3}>
                  {item.description}
                </Typography>
              </Stack> 
            </Stack>
          ))}

          {/* SUB TOTAL */}
          <Stack 
            justifyContent='space-between'
            direction='row'
          >
            <Stack>
              <Typography className={classes.typography1}>
                Subtotal
              </Typography>
              <Typography className={classes.typography3}>
                Price ({detailOrder?.items?.length} items)
              </Typography>
            </Stack>

            <Typography className={classes.typography1}>
              {handleTotalPrice(detailOrder?.items)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* FOOTER */}
      <CheckOrderFooter/>

      {/* LOADING COMPONENT */}
      <LoadingComponent isLoading={isLoading}/>
    </Stack>
  )
}

export default CompletedDetailPage