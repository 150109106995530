// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  contactOrderDetail : {
    backgroundColor: 'white',
    boxShadow: '0px 4px 40px #0000000D',
    borderRadius: 4,
  },
  contactOrderTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 12,
  },
  typography1 : {
    fontSize: 16,
    fontWeight: 500,
  },
  typography2: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  typography3: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  totalPayment: {
    fontSize: 18,
    fontWeight: 600,
  },
  orderDetail: {
    marginTop: 24,
    height: '100%',
    boxShadow: '0px 4px 40px #0000000D',
    borderRadius: 4,
    backgroundColor: 'white',
  },
  iconInfo: {
    color: theme.palette.text.secondary,
    width: 13,
    height: 13,
    zoom: 0.85
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zoom: 0.85
  }
}))

export default useStyles
