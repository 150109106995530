import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  copyright: {
    fontSize: 14,
    width: '100%',
    textAlign: 'center',
    fontWeight: 400,
    color: '#FFFFFF',
    backgroundColor: '#F96649',
    padding: 16,
    marginTop: 20,
  }
}))

export default useStyles