import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

// CONSTANTS
import { 
  loadInitialFormOrder,
  initialBookingHeaderData,
} from 'constants/values'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import ContactOrder from './ContactOrder/ContactOrder'
import Detailorder from './DetailOrder/DetailOrder'
import EditContact from './EditContact/EditContact'
import EditDetailorder from './EditDetailOrder/EditDetailOrder'
import LoadHeader from 'components/BookingHeader/BookingHeader'
import LoadFooter from 'components/BookingFooter/BookingFooter'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'
import PaymentDetails from 'components/PaymentDetails/PaymentDetails'
import PaymentMethod from 'components/PaymentMethod/PaymentMethod'

// MUIS
import { 
  Stack, 
  Slide
} from '@mui/material'

// SERVICE
import { 
  createLoadBookingOrder,
  selectPaymentMethod,
} from 'service/booking'

// UTILS
import { removeUserProfileFromLocalStorage } from 'utilities/localStorage'

const Review = () => {
  const navigate = useNavigate()

  const {
    loadOrderForm, setLoadOrderFrom,
    bookingHeaderData, setBookingHeaderData,
    loadItemList, setLoadItemList,
    setSnackbarObject, auth,
    setSecondarySnackBarObject, setAuth,
    setInvoiceNumber,
  } = useContext(AllPagesContext)

  // NOTE : TAKED OUT BY REQUEST
  const fee = '0'

  const initialLoadItemList = [
    {
      titleQuantity : 'Quantity',
      titleType : 'Items',
      id: 1,
      quantity: 1,
      containerType: '',
      description: '',
      sku: '',
      price: 0,
    },
  ]

  const [isEditingContact, setIsEditingContact] = useState(false)
  const [isEditingDetailOrder, setIsEditingDetailOrder] = useState(false)
  const [isPaymentDetailOpen, setIsPaymentDetailOpen] = useState(false)
  const [isMounted, setIsMounted] =  useState(false)
  const [isPaymentMethodShown, setIsPaymentMethodShown] = useState(false)
  const [bookingOrderID, setBookingOrderID] = useState(null)
  const [selectedBank, setSelectedBank] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // FUNCTION HANDLE SESSION EXPIRED
  const handleJwtTokenExpired = () => {
    setSecondarySnackBarObject({
      open: true,
      severity: 'info',
      message: 'Session Expired. Please Log in again.'
    })
    removeUserProfileFromLocalStorage()
    setAuth({})
    navigate('/')
  }

  // HANDLE CREATE LOADING ORDER
  const createLoadingOrder = async () => {
    setIsLoading(true)
    const abortController = new AbortController()
    let bodyParams = {
      booking_type: 'LOADING',
      depo_id: bookingHeaderData?.depo?.id,
      tx_date: moment(bookingHeaderData?.date).format('YYYY-MM-DDTHH:mm:ss.00Z'),
      phone_number: `0${loadOrderForm?.phoneNumber}`,
      full_name: loadOrderForm?.fullName,
      email: loadOrderForm?.email,
      bill_landing: loadOrderForm?.delivNo,
      consignee: loadOrderForm?.consignee,
      npwp: loadOrderForm.npwp,
      npwp_address: loadOrderForm.address,
      detailRequests: loadItemList.map(item => {
        let tempData = {}
        tempData.itemId = item.value.id
        tempData.price = item.price
        tempData.quantity = item.quantity
        return tempData
      })
    }
    
    const resultCreateNewBookingOrder = await createLoadBookingOrder(abortController.signal, bodyParams, auth.accessToken)

    if(resultCreateNewBookingOrder.status === 200) {
      setBookingOrderID(resultCreateNewBookingOrder?.data?.id)
      setIsPaymentMethodShown(true)
      setInvoiceNumber(resultCreateNewBookingOrder?.data?.invoice_no)
      setIsLoading(false)
    } 

    // HANDLE SESSION JWT EXPIRED
    else if(resultCreateNewBookingOrder.status === 403 && resultCreateNewBookingOrder.data.error === 'Forbidden') {
      handleJwtTokenExpired()
    }
    
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to create the new booking order',
      })
      setIsLoading(false)
    }
  }

  // FUNCTION HANDLE SELECT PAYMENT METHOD API
  const handleSelectPaymentMethod = async () => {
    setIsLoading(true)
    const abortController = new AbortController()
    let bodyParams = {
      depo_id: bookingHeaderData?.depo?.id,
      booking_header_id: bookingOrderID,
      bank_code: selectedBank,
      name: loadOrderForm?.fullName,
      phone_number: `0${loadOrderForm?.phoneNumber}`,
      expected_amount: totalPrice
    }
    const resultselectPaymentMethod = await selectPaymentMethod(abortController.signal, bodyParams, auth.accessToken)
  
    if(resultselectPaymentMethod.status === 200) {
      // OPEN XENDIT URL
      window.open(`${resultselectPaymentMethod?.data?.invoice_url}`, '_blank', 'noopener' )

      // RESET ALL DATA STATE
      setLoadOrderFrom(loadInitialFormOrder)
      setBookingHeaderData(initialBookingHeaderData)
      setLoadItemList(initialLoadItemList)
      setBookingOrderID(null)
      setSelectedBank('')
      setIsPaymentMethodShown(false)
      
      // SHOW SNACKBAR
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully create the new booking order',
      })
      setIsLoading(false)
      navigate('/load/finish')
    }

    // HANDLE SESSION JWT EXPIRED
    else if(resultselectPaymentMethod.status === 403 && resultselectPaymentMethod.data.error === 'Forbidden') {
      handleJwtTokenExpired()
    }
  
    else {
      setIsPaymentMethodShown(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to create the new booking order',
      })
      setIsLoading(false)
    }
  }

  // HANDLE TOTAL PRICE
  let totalPrice = 0
  for (let i = 0; i < loadItemList.length; i++) {
    totalPrice += loadItemList[i].price
  }
  
  useEffect(() => {
    setIsMounted(true)
  }, [])
  
  return (
    <Slide direction='left' in={isMounted} mountOnEnter unmountOnExit>
      <Stack
        marginTop='24px'
        width='100%'
        maxWidth='1040px'
        position='relative'
      >
        {/* HEADER */}
        <LoadHeader type='load'/>

        {/* MAIN CONTENT */}
        <Stack marginBottom='92px'>
          {!isEditingContact ? 
          // CONTACT ORDER
            <ContactOrder setIsEditingContact={setIsEditingContact}/> :

          // EDIT CONTACT
            <EditContact setIsEditingContact={setIsEditingContact}/>
          }

          {!isEditingDetailOrder ? 
          // DETAIL ORDER
            <Detailorder 
              setIsEditingDetailOrder={setIsEditingDetailOrder}
              totalPrice={totalPrice}
            /> :

          // EDIT DETAIL ORDER
            <EditDetailorder setIsEditingDetailOrder={setIsEditingDetailOrder}/>
          }

          {/* FOOTER */}
          <LoadFooter 
            totalPrice={(totalPrice + +fee)}
            isWithPaymentDetail={true}
            checkOutButton={() => createLoadingOrder()}
            paymentDetailClick={() => setIsPaymentDetailOpen(true)}
          />

          {/* PAYMENT DETAILS */}
          <PaymentDetails 
            totalItems={loadItemList.length}
            orderSummary={totalPrice}
            fee={fee}
            isPaymentDetailOpen={isPaymentDetailOpen}
            setIsPaymentDetailOpen={setIsPaymentDetailOpen}
          />

          {/* PAYMENT METHOD */}
          <PaymentMethod
            isPaymentMethodShown={isPaymentMethodShown}
            setSelectedBank={setSelectedBank}
            handlePaymentButton={handleSelectPaymentMethod}
          />

          {/* LOADING COMPONENT */}
          <LoadingComponent isLoading={isLoading}/>
        </Stack>
      </Stack>
    </Slide>
  )
}

export default Review