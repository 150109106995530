// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: 'center',
    marginBottom: '240px',
    position: 'relative'
  },
  caption: {
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '60px',
    letterSpacing: '0em',
    color: 'white',
    position: 'relative',
    textAlign: 'center',
    marginTop: 46,
  },
  subcaption: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    color: 'white',
    position: 'relative',
    textAlign: 'center',
    marginTop: 21,
  },
  title1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '25.6px',
    textAlign: 'justify',
    color: theme.palette.text.primary,
  },
  contentIcon: {
    width: '32px',
    position: 'relative',
    color: theme.palette.primary.main,
  },
  contentTitle: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
  contentDescription: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    whiteSpace : 'pre-wrap',
    color: theme.palette.text.primary,
  },
}))

export default useStyles