import React from 'react'

// COMPONENTS
import Header from '../Home/Header/Header'
import Content from './Content/Content'
import Footer from 'components/HomepageFooter/HomepageFooter'

// MUIS
import Stack from '@mui/material/Stack'

const PrivacyPolicy = () => {
  
  return (
    <>
      <Stack overflow='hidden'>
        {/* HEADER */}
        <Header/>

        {/* CONTENT */}
        <Content/>
      
        {/* FOOTER */}
        <Footer/>
      </Stack>
    </>
  )
}

export default PrivacyPolicy