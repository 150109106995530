// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow: '0px 15px 40px 0px #0000001A'
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  dateRangeListContainer: {
    paddingLeft: 22,
    padding: '8px 0px',
    cursor: 'pointer',
    '&:hover' : {
      backgroundColor: theme.palette.background.main
    },
  },
  typography1: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  typography2: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  countDays: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginRight: 'auto'
  },
  cancelButton: {
    textTransform: 'none',
    color: theme.palette.text.secondary
  },
  selectButton: {
    textTransform: 'none',
    marginLeft: 59,
  }
}))

export default useStyles
