import { useContext, useEffect } from 'react'

// ASSETS
import ContactOrderIcon from 'assets/images/logos/order-contact-logo.svg'
import IndonesiaFlag from 'assets/images/logos/order-flag-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import {
  Modal, 
  Stack, 
  Box, 
  Typography, 
  FormControl, 
  TextField, 
  InputAdornment, 
  Button,
} from '@mui/material'

// SERVICE
import { registerNewuserAccount } from 'service/booking'

// STYLES
import useStyles from './contactDetailUseStyles'

// UTILS 
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const ContactDetail = (props) => {
  const {
    isContactDetailOpen,
    setIsContactDetailOpen,
    handleContactDetailNavigate,
  } = props
  const classes = useStyles()

  const {
    bookingContactDetail, 
    setBookingContactDetail, 
    setAuth,
    auth,
    setSnackbarObject
  } = useContext(AllPagesContext)
  
  // HANDLE ORDER FORMS CHANGE
  const handleContactDetailChange = (event) => {
    setBookingContactDetail(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  // HANDLE DISABLE SAVE BUTTON
  const handleDisableSaveButton = () => {
    const regexEmailvalidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if(
      bookingContactDetail.fullName !== '' && 
      bookingContactDetail.email !== null && 
      regexEmailvalidation.test(bookingContactDetail.email) &&
      bookingContactDetail.phoneNumber !== ''
    ) return false
    else return true
  }

  // HANDLE REGISTER NEW USER ACCOUNT
  const registerNewUserAccount = async () => {
    const abortController = new AbortController()
    const bodyParams = {
      full_name: bookingContactDetail.fullName,
      email: bookingContactDetail.email
    }
    const resultData = await registerNewuserAccount(abortController.signal, bodyParams, auth.accessToken)
    
    if(resultData.status === 200) {
      // SET AUTHENTICATION USER
      setUserProfileToLocalStorage({
        phoneNumber: auth.phoneNumber,
        accessToken: auth.accessToken,
        email: resultData?.data?.email,
        fullName: resultData?.data?.full_name
      })
      setAuth( current => {
        return {
          ...current,
          email:  resultData?.data?.email,
          fullName: resultData?.data?.full_name,
        }
      })

      // CLOSE MODAL & NAVIGATE TO CHECK ORDERS PAGE
      setIsContactDetailOpen(false)
      handleContactDetailNavigate()
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to register new user account',
      })
    }
  }

  // HANDLE SAVE BUTTON
  const handleSaveButton = () => {
    if(auth.email === null || auth.fullName === null) {
      registerNewUserAccount()
    } 
    else {
      // CLOSE MODAL & NAVIGATE TO CHECK ORDERS PAGE
      setIsContactDetailOpen(false)
      handleContactDetailNavigate()
    }
  }

  return (
    <Modal disableAutoFocus open={isContactDetailOpen}>
      <Stack className={classes.root}>
        {/* TITLE */}
        <Stack
          padding='16px 25px'
          borderBottom='solid 1px #D9D9D9'
          width='100%'
        >
          <Stack 
            direction='row'
            alignItems='center'
            spacing={1.5}
          >
            <Box 
              width='38px'
              component='img'
              src={ContactOrderIcon}
              alt=''
            />
            <Typography className={classes.mainTitle}>
              +62{bookingContactDetail.phoneNumber}
            </Typography>
          </Stack>
        </Stack>

        {/* CONTENTS */}
        <Stack 
          width='100%'
          padding='24px'
        >
          {/* FULL NAME */}
          <Typography variant='body1' marginBottom='12px'>
            Full Name<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            required
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
          >
            <TextField 
              required
              placeholder='Enter full name'
              type='text'
              name='fullName'
              value={bookingContactDetail.fullName}
              onChange={(event) => handleContactDetailChange(event)}
            />
          </FormControl>

          {/* 
            PHONE NUMBER 
            NOTE : THIS COMPONENT TAKE OUT BY REQUEST
          */}
          {/* <Typography variant='body1' marginBottom='12px'>
            Phone Number<span className={classes.superscript}>*</span>
          </Typography> */}

          {/* <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
          >
            <TextField 
              disabled
              placeholder='Enter phone number'
              type='number'
              name='phoneNumber'
              className={classes.fieldNumber}
              InputProps={{
                startAdornment : <InputAdornment position='start' > 
                  <Stack alignItems='center' direction='row' paddingRight='14px' borderRight='solid 1px #D9D9D9'>
                    <Box component='img' src={IndonesiaFlag}/>
                    <Typography marginLeft='4px'> +62 </Typography>
                  </Stack>
                </InputAdornment>
              }}
              value={bookingContactDetail.phoneNumber}
              onChange={(event) => handleContactDetailChange(event)}
            />
          </FormControl> */}

          {/* EMAIL */}
          <Typography variant='body1' marginBottom='12px'>
            Email<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
          >
            <TextField 
              placeholder='Enter email'
              type='email'
              name='email'
              value={bookingContactDetail.email}
              onChange={(event) => handleContactDetailChange(event)}
            />
          </FormControl>

          {/* SAVE BUTTON */}
          <Button
            variant='contained'
            sx={{
              height: '47px',
              textTransform: 'none',
              marginTop: '12px'
            }}
            disabled={handleDisableSaveButton()}
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default ContactDetail