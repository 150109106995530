// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  contactOrderDetail : {
    backgroundColor: 'white',
    boxShadow: '0px 4px 40px #0000000D',
    borderRadius: 4,
  },
  contactOrderTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 12,
  },
  orderDetail: {
    marginTop: 24,
    height: '100%',
    boxShadow: '0px 4px 40px #0000000D',
    borderRadius: 4,
    backgroundColor: 'white',
    marginBottom: 92,
  },
  addNewFromIcon: {
    width: 26,
    height: 26,
    marginTop: 16,
  },
  coontentRightContainer: {
    boxShadow: '0px 4px 40px #0000000D',
    position: 'sticky',
    top: 108,
    width: 367,
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  typography1: {
    fontSize: 16,
    fontWeight: 500,
  },
  typography2: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  totalPayment: {
    fontSize: 18,
    fontWeight: 600,
  },
  menuContainer: {
    '& .MuiMenu-paper' : {
      width: 246,
      height: 230,
      overFlowX: 'scroll',
      borderRadius: 4,
    }
  },
  searchIcon: {
    width: 16,
    height: 16,
    marginLeft: 18,
  },
  menuItemText: {
    fontSize: 16,
  },
  searchBarContainer: {
    paddingBottom: 10,
    '& .MuiInputBase-root' : {
      paddingBottom: 8,
    }
  },
  superscript: {
    color: theme.palette.primary.main
  },
  descriptionIcon: {
    width: 14,
    height: 14,
    color: theme.palette.text.secondary,
    zoom: 0.85
  },
  substrackButton: {
    height: 54,
    width: 54,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& .MuiSvgIcon-root' : {
      color: theme.palette.text.secondary
    },
  },
  descriptionText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zoom: 0.85
  },
  fieldNumber : {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    }
  },
}))

export default useStyles
