import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CONSTANTS
import { 
  initialUnloadingOrderForm,
  initialBookingHeaderData,
} from 'constants/values'

// COMPONENTS
import Contact from './ContactOrder/ContactOrder'
import DetailOrders from './DetailOrder/DetailOrders'
import EditContact from './EditContact/EditContact'
import EditDetailorder from './EditDetailOrder/EditDetailOrder'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'
import UnloadingHeader from 'components/BookingHeader/BookingHeader'
import UnloadingFooter from 'components/BookingFooter/BookingFooter'
import PaymentDetails from 'components/PaymentDetails/PaymentDetails'
import PaymentMethod from 'components/PaymentMethod/PaymentMethod'

// MUIS
import {
  Stack, 
  Slide
} from '@mui/material'

// SERVICES
import { 
  createUnloadingBookingOrder,
  selectPaymentMethod,
} from 'service/booking'

// UTILS
import { removeUserProfileFromLocalStorage } from 'utilities/localStorage'

const Review = () => {

  const navigate = useNavigate()

  const {
    unloadingOrderForm, setUnloadingOrderForm,
    bookingHeaderData, setBookingHeaderData,
    unloadingItemList, setUnloadingItemList,
    auth, setSnackbarObject, 
    setSecondarySnackBarObject, setAuth,
    setInvoiceNumber,
  } = useContext(AllPagesContext)

  // TAKED OUT TRANSACTION FEE BY REQUEST
  const fee = '0'

  let initialUnloadingItemList = [
    {
      titleNo : 'Container No.',
      titleType : 'Items',
      id: 1,
      containerNo: '',
      containerType: '',
      description: '',
      sku: '',
      price: 0,
    },
  ]

  const [isEditingContact, setIsEditingContact] = useState(false)
  const [isEditingDetailOrder, setIsEditingDetailOrder] = useState(false)
  const [isPaymentDetailOpen, setIsPaymentDetailOpen] = useState(false)
  const [isMounted, setIsMounted] =  useState(false)
  const [isPaymentMethodShown, setIsPaymentMethodShown] = useState(false)
  const [bookingOrderID, setBookingOrderID] = useState(null)
  const [selectedBank, setSelectedBank] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // HANDLE TOTAL PRICE
  let totalPrice = 0
  for (let i = 0; i < unloadingItemList.length; i++) {
    totalPrice += unloadingItemList[i].price
  }

  // FUNCTION HANDLE SESSION EXPIRED
  const handleJwtTokenExpired = () => {
    setSecondarySnackBarObject({
      open: true,
      severity: 'info',
      message: 'Session Expired. Please Log in again.'
    })
    removeUserProfileFromLocalStorage()
    setAuth({})
    navigate('/')
  }

  // FUNCTION CREATE UNLOADING ORDER
  const createUnloadingOrder = async () => {
    setIsLoading(true)
    const abortController = new AbortController()
    let bodyParams = {
      booking_type: 'UNLOADING',
      depo_id: bookingHeaderData?.depo?.id,
      tx_date: moment(bookingHeaderData?.date).format('YYYY-MM-DDTHH:mm:ss.00Z'),
      phone_number: `0${unloadingOrderForm?.phoneNumber}`,
      full_name: unloadingOrderForm?.fullName,
      email: unloadingOrderForm?.email,
      bill_landing: unloadingOrderForm?.bolNo,
      consignee: unloadingOrderForm?.consignee,
      npwp: unloadingOrderForm.npwp,
      npwp_address: unloadingOrderForm.address,
      detailRequests: unloadingItemList.map(item => {
        let tempData = {}
        tempData.itemId = item.value.id
        tempData.price = item.value.price
        tempData.container_number = item.containerNo
        return tempData
      })
    }

    const resultCreateNewBookingOrder = await createUnloadingBookingOrder(abortController.signal, bodyParams, auth.accessToken )
    
    if(resultCreateNewBookingOrder.status === 200) {
      setBookingOrderID(resultCreateNewBookingOrder?.data?.id)
      setIsPaymentMethodShown(true)
      setInvoiceNumber(resultCreateNewBookingOrder?.data?.invoice_no)
      setIsLoading(false)
    } 

    // HANDLE SESSION JWT EXPIRED
    else if(resultCreateNewBookingOrder.status === 403 && resultCreateNewBookingOrder.data.error === 'Forbidden') {
      handleJwtTokenExpired()
    }
    
    else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to create the new booking order',
      })
      setIsLoading(false)
    }
  }

  // FUNCTION HANDLE SELECT PAYMENT METHOD API
  const handleSelectPaymentMethod = async () => {
    setIsLoading(true)
    const abortController = new AbortController()
    let bodyParams = {
      depo_id: bookingHeaderData?.depo?.id,
      booking_header_id: bookingOrderID,
      bank_code: selectedBank,
      name: unloadingOrderForm?.fullName,
      phone_number: `0${unloadingOrderForm?.phoneNumber}`,
      expected_amount: totalPrice
    }
    const resultselectPaymentMethod = await selectPaymentMethod(abortController.signal, bodyParams, auth.accessToken)

    if(resultselectPaymentMethod.status === 200) {
      // OPEN XENDIT URL
      window.open(`${resultselectPaymentMethod?.data?.invoice_url}`, '_blank', 'noopener' )

      // RESET ALL DATA STATE
      setUnloadingOrderForm(initialUnloadingOrderForm)
      setBookingHeaderData(initialBookingHeaderData)
      setUnloadingItemList(initialUnloadingItemList)
      setBookingOrderID(null)
      setSelectedBank('')
      setIsPaymentMethodShown(false)

      // SHOW SNACKBAR
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully create the new booking order',
      })
      setIsLoading(false)
      navigate('/unloading/finish')
    }

    // HANDLE SESSION JWT EXPIRED
    else if(resultselectPaymentMethod.status === 403 && resultselectPaymentMethod.data.error === 'Forbidden') {
      handleJwtTokenExpired()
    }

    else {
      setIsPaymentMethodShown(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to create the new booking order',
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])
  
  return (
    <Slide direction='left' in={isMounted} mountOnEnter unmountOnExit>
      <Stack 
        marginTop='24px'
        width='100%'
        maxWidth='1040px'
        position='relative'
      >
        {/* HEADER */}
        <UnloadingHeader/>

        {/* MAIN CONTENT */}
        <Stack marginBottom='92px'>
          {!isEditingContact ? 
          // CONTACT ORDER
            <Contact setIsEditingContact={setIsEditingContact}/> :

          // EDIT CONTACT
            <EditContact setIsEditingContact={setIsEditingContact}/>
          }
          
          {!isEditingDetailOrder ? 
          // DETAIL ORDERS
            <DetailOrders 
              setIsEditingDetailOrder={setIsEditingDetailOrder}
              totalPrice={totalPrice}
            /> :

          // EDIT DETAIL ORDER
            <EditDetailorder setIsEditingDetailOrder={setIsEditingDetailOrder}/>
          }
        </Stack>

        {/* FOOTER */}
        <UnloadingFooter 
          totalPrice={(totalPrice + +fee)}
          isWithPaymentDetail={true}
          checkOutButton={() => createUnloadingOrder()}
          paymentDetailClick={() => setIsPaymentDetailOpen(true)}
        />

        {/* PAYMENT DETAILS */}
        <PaymentDetails 
          totalItems={unloadingItemList.length}
          orderSummary={totalPrice}
          fee={fee}
          isPaymentDetailOpen={isPaymentDetailOpen}
          setIsPaymentDetailOpen={setIsPaymentDetailOpen}
        />

        {/* PAYMENT METHOD */}
        <PaymentMethod
          isPaymentMethodShown={isPaymentMethodShown}
          setSelectedBank={setSelectedBank}
          handlePaymentButton={handleSelectPaymentMethod}
        />

        {/* LOADING COMPONENT */}
        <LoadingComponent isLoading={isLoading}/>
      </Stack>
    </Slide>
  )
}

export default Review