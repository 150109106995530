// APIS
import { axiosWorx } from 'apis/axios'

// GET PRIVACY POLICY
export const getPrivacyPolicy = async (inputSignal) => {
  try {
    const response = await axiosWorx.get(
      '/api/rules-privacy-policy?populate=*',
      { signal : inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET SUPPORT REGULATION
export const getSupportRegulation = async (inputSignal) => {
  try {
    const response = await axiosWorx.get(
      '/api/rules-support-regulation?populate=*',
      { signal : inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

// GET TERMS OF USE
export const getToU = async (inputSignal) => {
  try {
    const response = await axiosWorx.get(
      '/api/rules-terms-of-service?populate=*',
      { signal : inputSignal }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}
