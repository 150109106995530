import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginBottom: 16,
  },
  bankLogo: {
    height: 50,
    width: 70,
    marginRight: 16,
    marginBottom: 40
  },
  contactIcon: {
    height: 20,
    marginTop: 5,
    marginRight: 7,
    color: '#F96649',
  },
  kemenkeuLogo: {
    height: '40.00666046142578px',
    width: '154px',
    marginBottom: '16px',
  },
  nleLogo: {
    height: '40px',
    width: '119.9058837890625px',
    borderRadius: '4px',
  },
  contact: {
    fontSize: 16,
    marginBottom:'16px',
  },
  link: {
    fontSize: 16,
    marginBottom:'16px',
    '&:hover':{
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },

}))

export default useStyles