// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 68,
    padding: '11px 24px',
    borderRadius: 4,
    backgroundColor: 'white',
    boxShadow: '0px 4px 40px #0000000D',
  },
  totalPayment: {
    fontSize: 18,
    fontWeight: 500,
  },
  totalBill: {
    fontSize: 20,
    fontWeight: 600,
  },
  checkoutButton: {
    width: 222,
    textTransform: 'none'
  },
  arrowIcon: {
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}))

export default useStyles
