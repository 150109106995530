import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import CompanyLogo from 'assets/images/logos/order-page-nle-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import { 
  Box, 
  Stack, 
  Typography 
} from '@mui/material'

const CheckOrderAppBar = () => {
  const {auth} = useContext(AllPagesContext)
  const navigate = useNavigate()
  
  return (
    <Stack
      direction='row' 
      justifyContent='space-between'
      alignItems='center' 
      padding='0px 135px'
      height='84px'
      width='100%'
    >
      {/* NLE LOGO */}
      <Box 
        component='img'
        src={CompanyLogo}
        alt='nle-logo'
      />

      <Typography 
        marginLeft='6px'
        sx={{
          fontSize: '18px',
          fontWeight: 500,
          color: '#F96649',
          cursor: 'pointer'
        }}
        onClick={() => navigate('/profile')}
      >
        Hi, {auth?.fullName?? ''}
      </Typography>
    </Stack>
  )
}

export default CheckOrderAppBar