import { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

// ASSETS
import LogoProductIconOnly from 'assets/images/logos/product-nle-connect-icon-only.svg'
import LogoProductIconOnlyPng from 'assets/images/logos/product-nle-connect-icon-only.png'


// COMPONENT
import Snackbar from 'components/Snackbar/Snackbar'
import Unloading from 'components/Routes/UnloadingRoutes'
import CheckOrder from 'components/Routes/CheckOrderRoutes'
import Load from 'components/Routes/LoadRoutes'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// ROUTES
import routes from 'routes/routes'

function App() {
  const { snackbarObject, setSnackbarObject } = useContext(AllPagesContext)

  const getRouteComponent = (inputItem) => {
    if (inputItem.routeType === 'unloading') {
      return (
        <Unloading>
          {inputItem.element}
        </Unloading>
      )
    }
    else if(inputItem.routeType === 'checkOrder') {
      return (
        <CheckOrder>
          {inputItem.element}
        </CheckOrder>
      )
    }
    else if(inputItem.routeType === 'load') {
      return (
        <Load>
          {inputItem.element}
        </Load>
      )
    }
    else return inputItem.element
  }

  useEffect(() => {
    // UPDATE FAVICON
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = LogoProductIconOnly

    const appleTouchIconElement = document.getElementById('apple-touch-icon')
    appleTouchIconElement.href = LogoProductIconOnlyPng

  }, [])

  return (
    <>
      <Routes>
        {routes.map((item, index) => (
          <Route 
            key={index}
            path={item.path} 
            element={getRouteComponent(item)}
          />
        ))}
      </Routes>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarObject.open}
        setToast={setSnackbarObject}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />
    </>
  )
}

export default App
