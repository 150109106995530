import PropTypes from 'prop-types'

// ASSETS
import BankMandiri from 'assets/images/logos/PaymentMethods/bank-mandiri.svg'
import BankBCA from 'assets/images/logos/PaymentMethods/bank-bca.svg'
import BankBRI from 'assets/images/logos/PaymentMethods/bank-bri.svg'
import BankBNI from 'assets/images/logos/PaymentMethods/bank-bni.svg'
import BankPermata from 'assets/images/logos/PaymentMethods/bank-permata.svg'
import BankBSI from 'assets/images/logos/PaymentMethods/bank-bsi.svg'
import BankBJB from 'assets/images/logos/PaymentMethods/bank-bjb.svg'

// MUIS
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'

// STYLES
import useStyles from './paymentMethodUseStyles'
import { Box } from '@mui/material'
import { useState } from 'react'

const PaymentMethod = (props) => {
  const classes = useStyles()
  const { 
    isPaymentMethodShown,
    setSelectedBank,
    handlePaymentButton,
  } = props

  const initialListPayment = [
    {
      name: 'BRI Virtual Account',
      logo: BankBRI,
      value: 'BRI',
      isChecked: false,
    },
    {
      name: 'BNI Virtual Account',
      logo: BankBNI,
      value: 'BNI',
      isChecked: false,
    },
    {
      name: 'Permata Bank Virtual Account',
      logo: BankPermata,
      value: 'PERMATA',
      isChecked: false,
    },
    {
      name: 'BSI Virtual Account',
      logo: BankBSI,
      value: 'BSI',
      isChecked: false,
    },
    {
      name: 'Bank BJB Virtual Account',
      logo: BankBJB,
      value: 'BJB',
      isChecked: false,
    },
    {
      name: 'Mandiri Virtual Account',
      value: 'MANDIRI',
      logo: BankMandiri,
      isChecked: false,
    },
    {
      name: 'BCA Virtual Account',
      logo: BankBCA,
      value: 'BCA',
      isChecked: false,
    },
  ]

  const [listPayment, setListPayment] = useState(initialListPayment)

  // HANDLE LIST PAYMENT CLICK
  const handleListPaymentClick = (inputitem) => {
    const newListPayment = [...listPayment].map((item) => {
      if(item.name === inputitem.name) item.isChecked = true
      else item.isChecked = false
      return item
    })

    setListPayment(newListPayment)
    setSelectedBank(inputitem.value)
  }

  return (
    <Modal open={isPaymentMethodShown}>
      <Stack className={classes.root}>
        {/* HEADER */}
        <Stack padding='24px 37px'>
          <Typography className={classes.typography1}>
            Payment Method
          </Typography>
        </Stack>

        {/* CONTENT */}
        {listPayment.map((item, index) => (
          <Stack
            key={index}
            height='53px'
            padding='16px 27px'
            direction='row'
            alignItems='center'
            borderBottom='1px solid #D9D9D9'
          >
            <Stack 
              direction='row'
              alignItems='center'
              spacing={1}
              width='42%'
            >
              {/* RADIO */}
              <Radio
                checked={item.isChecked}
                onChange={() => handleListPaymentClick(item)}
              />

              {/* LOGO BANK */}
              <Box
                component='img'
                src={item.logo}
              />
            </Stack>

            <Typography 
              className={classes.typography2}
            >
              {item.name}
            </Typography>
          </Stack>
        ))}

        {/* FOTER */}
        <Stack
          position='absolute'
          padding='0px 62px 16px 62px'
          sx={{
            bottom: 0,
            left: 0
          }}
        >
          <Button
            variant='contained'
            size='large'
            sx={{width: '290px'}}
            onClick={handlePaymentButton}
          >
            Pay Now
          </Button>
        </Stack>
        
      </Stack>
    </Modal>
  )
}

PaymentMethod.defaultProps = {
  isPaymentMethodShown: false
}

PaymentMethod.propTypes = {
  isPaymentMethodShown: PropTypes.bool.isRequired,
  setSelectedBank: PropTypes.func.isRequired,
  handlePaymentButton: PropTypes.func.isRequired,
}

export default PaymentMethod