import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

//ASSETS
import BackgroundImage from 'assets/images/backgrounds/home-background.png'
import UnloadingIcon from 'assets/images/logos/unloading.svg'
import UnloadingIconOff from 'assets/images/logos/unloading-off.svg'
import LoadingIcon from 'assets/images/logos/loading.svg'
import LoadingIconOff from 'assets/images/logos/loading-off.svg'

// COMPONENTS
import BookingAuthentication from 'components/BookingAuthentication/BookingAuthentication'
import OTPMobileVerivication from 'components/OTPMobileVerification/OTPMobileVerification'
import ContactDetail from 'components/ContactDetailModal/ContactDetail'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

// MUI ICONS
import IconDate from '@mui/icons-material/DateRange'
import IconDepoLocation from '@mui/icons-material/LocationCity'

// RAMDA
import { isEmpty } from 'ramda'

// SERVICE
import { getAllDepoList } from 'service/booking'

// STYLES
import useStyles from './orderUseStyles'
import { Typography } from '@mui/material'

const Order = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { 
    bookingHeaderData, 
    setBookingHeaderData, 
    bookingContactDetail,
  } = useContext(AllPagesContext)

  const initalListButton = [
    {
      title: 'Unloading',
      isActived : true,
    },
    {
      title: 'Load',
      isActived : false,
    },
  ]

  const [bookingTypeListBtn, setBookingTypeLisBtn] = useState(initalListButton)
  const [bookingType, setBookingType] = useState('Unloading')
  const [depoList, setDepoList] = useState([])
  const [isBookingAuthOpen, setIsBookingAuthOpen] = useState(false)
  const [isOtpMobileOpen, setIsOtpMobileOpen] = useState(false)
  const [isContactDetailOpen, setIsContactDetailOpen] = useState(false)

  const defaultProps = {
    options: depoList,
    getOptionLabel: (option) => option.organizationName,
  }

  const getBookingTypeIcon = (inputItem) => {
    if(inputItem.title === 'Unloading' && inputItem.isActived === true) return UnloadingIcon
    else if(inputItem.title === 'Unloading' && inputItem.isActived === false) return UnloadingIconOff
    else if(inputItem.title === 'Load' && inputItem.isActived === true) return LoadingIcon
    else if(inputItem.title === 'Load' && inputItem.isActived === false) return LoadingIconOff
  }

  const handleBookingTypeListBtn = (inputItem) => {
    const newList = [...bookingTypeListBtn].map((item) => {
      if(item.title === inputItem.title) item.isActived = true
      else item.isActived = false

      return item
    })
    setBookingTypeLisBtn(newList)
    setBookingType(inputItem.title)
    setBookingHeaderData(current => {
      return {
        ...current,
        type: inputItem.title
      }
    })
  } 

  const loadAllDepoList = async (inputIsMounted, inputAbortController) => {
    const resultLoadAllDepoList = await getAllDepoList(inputAbortController.signal)
    if(resultLoadAllDepoList.status === 200 && inputIsMounted) {
      setDepoList(resultLoadAllDepoList.data)
    }
  }

  // HANDLE CONTACT DETAIL NAVIGATE
  const handleContactDetailNavigate = () => {
    bookingType === 'Unloading' ? navigate('/unloading') : navigate('/load')
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadAllDepoList(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack className={classes.root}>
      {/* BACKGROUND */}
      <Stack     
        // maxWidth='1440px'
        width='100%'
        height='485px'
        alignItems= 'center'
        sx={{ 
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        {/* CAPTION */}
        <Stack 
          width='100%'
          maxWidth='1440px'
          paddingLeft='135px'
        >
          <Typography className={classes.caption}>
            Order loading and unloading containers, now easier and faster
          </Typography>
        </Stack>

        {/* ORDERS */}
        <Stack
          position='absolute'
          top= '332px'
          className={classes.orderContainer}
        >
          {/* BOOKING TYPE BUTTON */}
          <Stack direction='row'>
            {bookingTypeListBtn.map((item, index) => (
              <Stack
                key={index}
                className={classes.loadUnload}
                width='179px'
                height='84px'
                borderRadius={item.title === 'Unloading' ? '12px 0 0 0' : 0}
                alignItems='center'
                backgroundColor= {item.isActived ? '#FFF4F5' : 'white'}
                onClick={() => handleBookingTypeListBtn(item)}
              >        
                <Box
                  component='img'
                  src={getBookingTypeIcon(item)}
                  className={classes.menuIcons}
                />  
                <Typography>
                  {item.title}
                </Typography>
              </Stack>
            ))}
          </Stack>

          <Divider variant='fullWidth'/>

          <Stack margin='40px 52px' direction='row'> 
            {/* DEPO / LOCATION CITY */}
            <Stack 
              maxWidth='410px' 
              marginRight='23px'
              width='100%'
            >
              <Typography className={classes.fieldTitle}>
                Depo
              </Typography>
              <Autocomplete
                {...defaultProps}
                disablePortal
                disableClearable
                onChange={(event,newValue) => {setBookingHeaderData(current => ({
                  ...current,
                  depo: newValue,
                  type: bookingType,
                }))}}
                renderInput={(params) => (
                  <TextField {...params}
                    className={classes.fieldInput}
                    placeholder='Select depo'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <InputAdornment position='start' > 
                        <IconDepoLocation/>
                      </InputAdornment>
                    }}
                  />
                )}
              />
            </Stack>

            {/* DATE PICK */}
            <Stack
              width='100%'
              maxWidth='410px' 
              marginRight='23px'
            >
              <Typography className={classes.fieldTitle}>
                {bookingType === 'Unloading' ? 'Unloading Date' : 'Load Date'}
              </Typography>        
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  inputFormat='D MMM YYYY'
                  disablePast
                  // openTo='year'
                  // views={[ 'year', 'month', 'day']}
                  value={bookingHeaderData.date}
                  onChange={(newValue) => {setBookingHeaderData(current => ({
                    ...current,
                    date: newValue.$d,
                  }))}}
                  renderInput={( params ) =>
                    <TextField {...params}
                      className={classes.fieldInput}
                      placeholder={bookingType === 'Unloading' ? 'Select unloading date' : 'Select load date'}
                      helperText={null}
                      InputProps={{
                        startAdornment: <InputAdornment position='start' > 
                          <IconDate/>
                        </InputAdornment>
                      }}
                    />}
                />
              </LocalizationProvider>
            </Stack>

            {/* BUTTON */}
            <Stack marginTop='34px'>
              <Button
                disabled={(!isEmpty(bookingHeaderData.depo) && !isEmpty(bookingHeaderData.date)) ? false : true}
                className={classes.formButton}
                variant='contained'
                onClick={() => setIsBookingAuthOpen(true)}
              >
                Request Order
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* BOOKING AUTHENTICATION */}
      <BookingAuthentication
        isBookingAuthOpen={isBookingAuthOpen}
        setIsBookingAuthOpen={setIsBookingAuthOpen}
        setIsOtpMobileOpen={setIsOtpMobileOpen}
      />

      {/* OTP MOBILE VERIFICATION */}
      <OTPMobileVerivication
        isOtpMobileOpen={isOtpMobileOpen}
        setIsOtpMobileOpen={setIsOtpMobileOpen}
        phoneNumber={`0${bookingContactDetail.phoneNumber}`}
        setIsContactDetailOpen={setIsContactDetailOpen}
      />

      {/* CONTACT DETAIL MODAL */}
      <ContactDetail
        isContactDetailOpen={isContactDetailOpen}
        setIsContactDetailOpen={setIsContactDetailOpen}
        handleContactDetailNavigate={handleContactDetailNavigate}
      />
    </Stack>
  )
}

export default Order