import { useNavigate } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'

// ASSETS
import ContactOrderIcon from 'assets/images/logos/order-contact-logo.svg'
import IndonesiaFlag from 'assets/images/logos/order-flag-logo.svg'

// COMPONENTS
import CheckOrderAppBar from 'components/CheckOrderAppBar/CheckOrderAppBar'
import SecondarySnackbar from 'components/SecondarySnackbar/SecondarySnackbar'
import CheckOrderFooter from 'components/CheckOrderFooter/CheckOrderFooter'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import { 
  Button,
  Box, 
  IconButton, 
  InputAdornment, 
  Stack, 
  Typography, 
  TextField, 
} from '@mui/material'

// MUI ICON
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

// SERVICE
import { UpdateUserProfile } from 'service/booking'

// STYLES
import useStyles from './profileUSeStyles'

// UTILS 
import { setUserProfileToLocalStorage, removeUserProfileFromLocalStorage } from 'utilities/localStorage'

const Profile = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { 
    auth, setAuth,
    secondarySnackBarObject, setSecondarySnackBarObject,
  } = useContext(AllPagesContext)
  
  // STYLING FOOTER
  const stylingFooter = {
    position: 'absolute', 
    bottom: '28px',
    zoom: 0.85,
  }

  const initialContactDetailForms = {
    fullName: '',
    phoneNumber: '',
    email: ''
  }

  const [contactDetailForm, setContactDetailForm] = useState(initialContactDetailForms)
  const [tempAuthData, setTempAuthData] = useState({})
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  
  // HANDLE CONTACT DETAIL FORMS CHANGE
  const handleContactDetailChange = (inputEvent) => {
    setContactDetailForm(current => {
      return {
        ...current,
        [inputEvent.target.name] : inputEvent.target.value
      }
    })
  }

  // HANDLE SAVE BUTTON
  const handleSaveButton = async () => {
    const abortController = new AbortController()

    const bodyParams = {
      full_name: contactDetailForm.fullName,
      email: contactDetailForm.email
    }

    const resultUpdateProfile = await UpdateUserProfile(abortController.signal, auth.accessToken, bodyParams)
    if(resultUpdateProfile.status === 200) {
      // SET AUTH
      setAuth(current => {
        return {
          ...current,
          fullName: resultUpdateProfile?.data?.full_name,
          email: resultUpdateProfile?.data?.email
        }
      })

      // SET AUTHENTICATION USER
      setUserProfileToLocalStorage({
        phoneNumber: auth.phoneNumber,
        accessToken: auth.accessToken,
        fullName: resultUpdateProfile?.data?.full_name,
        email: resultUpdateProfile?.data?.email
      })

      setSecondarySnackBarObject({
        open: true,
        severity: 'info',
        message: 'Successfully update the profile',
      })

      navigate('/check-order')
    } else {
      setSecondarySnackBarObject({
        open: true,
        severity: 'info',
        message: 'Failed to update the profile',
      })
    }
    
  }

  // HANDLE LOG OUT BUTTON
  const handleLogOutBtn = () => {
    setAuth({})
    removeUserProfileFromLocalStorage()
    navigate('/')
  }

  // HANDLE CLSOE SNACKBAR
  const handleSecondarySnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return

    setSecondarySnackBarObject({
      open: false,
      severity: 'info',
      message: '',
    })
  }
  
  // HANDLE AUTO FILL CONTACT DETAIL FORMS
  useEffect(() => {
    setContactDetailForm(current => {
      return {
        fullName: auth?.fullName,
        phoneNumber: auth?.phoneNumber?.slice(1),
        email: auth?.email,
      }
    })

    setTempAuthData({
      fullName: auth?.fullName,
      phoneNumber: auth?.phoneNumber?.slice(1),
      email: auth?.email,
    })
  }, [])

  // HANDLE USER ACCESS
  useEffect(() => {
    if(auth.accessToken === undefined) navigate('/')
  }, [])

  // HANDLE DISABLE SAVE BUTTON
  useEffect(() => {
    if(contactDetailForm.email !== tempAuthData.email || contactDetailForm.fullName !== tempAuthData.fullName) setIsSaveButtonDisabled(false)
    else setIsSaveButtonDisabled(true)
  }, [contactDetailForm])

  return (
    <Stack 
      alignItems='center' 
      className={`${classes.root} no-zoom`}
    >
      {/* HEADER */}
      <Stack 
        className='zoom'
        width='100%' 
        alignItems='center'
        position='sticky'
        top='0px'
        zIndex='22'
        sx={{backgroundColor: 'white'}}
      >
        <Stack 
          width='100%' 
          maxWidth='1540px'
          padding='0px 50px'
          alignItems='center'
        >

          {/* HEADER */}
          <CheckOrderAppBar/>
        </Stack>
      </Stack>

      {/* CONTENT */}
      <Stack
        width='100%' 
        maxWidth='1540px'
        padding='0px 185px'
        marginTop='24px'
        className='zoom'
        direction='row'
        justifyContent='center'
        spacing={4}
      >
        {/* BACK BUTTON */}
        <Stack className={classes.backButtonContainer}>
          <IconButton 
            disableRipple
            onClick={() => navigate('/check-order')}
          >
            <ArrowBackIosIcon/>
          </IconButton>
        </Stack>

        {/* CONTACT DETAIL & SAVE BUTTON */}
        <Stack className={classes.contactDetailContainer}>
          {/* TITLE */}
          <Stack
            padding='16px 24px'
            borderBottom='1px solid #D9D9D9'
            direction='row'
            alignItems='center'
            spacing={1.5}
          >
            <Box
              component='img'
              src={ContactOrderIcon}
            />

            <Typography className={classes.typography1}>
              Contact Details
            </Typography>
          </Stack>

          {/* FORMS */}
          <Stack padding='24px'>
            {/* FULL NAME */}
            <Stack marginBottom='20px'>
              <Typography variant='body1' marginBottom='12px'>
                Full Name<span className={classes.superscript}>*</span>
              </Typography>
              <TextField 
                required
                placeholder='Enter full name'
                type='text'
                name='fullName'
                value={contactDetailForm.fullName}
                onChange={(event) => handleContactDetailChange(event)}
              />
            </Stack>

            {/* PHONE NUMBER */}
            <Stack marginBottom='20px'>
              <Typography variant='body1' marginBottom='12px'>
                Phone Number<span className={classes.superscript}>*</span>
              </Typography>
              <TextField 
                disabled
                placeholder='Enter phone number'
                type='number'
                name='phoneNumber'
                className={classes.fieldNumber}
                InputProps={{
                  startAdornment : <InputAdornment position='start' > 
                    <Stack alignItems='center' direction='row' paddingRight='14px' borderRight='solid 1px #D9D9D9'>
                      <Box component='img' src={IndonesiaFlag}/>
                      <Typography marginLeft='4px'> +62 </Typography>
                    </Stack>
                  </InputAdornment>
                }}
                value={contactDetailForm.phoneNumber}
                onChange={(event) => handleContactDetailChange(event)}
              />
            </Stack>

            {/* EMAIL */}
            <Stack marginBottom='40px'>
              {/* EMAIL */}
              <Typography variant='body1' marginBottom='12px'>
                Email<span className={classes.superscript}>*</span>
              </Typography>
              <TextField 
                placeholder='Enter email'
                type='email'
                name='email'
                value={contactDetailForm.email}
                onChange={(event) => handleContactDetailChange(event)}
              />
            </Stack>

            {/* SAVE BUTTON */}
            <Button 
              sx={{
                height: '47px',
                textTransform: 'unset'
              }}
              variant='contained'
              disabled={isSaveButtonDisabled}
              onClick={handleSaveButton}
            >
              Save
            </Button>

          </Stack>
        </Stack>

        {/* LOG OUT BUTTON */}
        <Stack width='107px'>
          <Button 
            variant='outlined'
            sx={{
              textTransform: 'unset',
              background: 'white',
              height: '43px'
            }}
            onClick={handleLogOutBtn}
          >
            Log Out
          </Button>

        </Stack>
      </Stack>

      {/* FOOTER */}
      <CheckOrderFooter styling={stylingFooter}/>

      {/* SECONDARY SNACKBAR */}
      <SecondarySnackbar
        openSnackbar={secondarySnackBarObject?.open}
        handleClose={handleSecondarySnackbarClose}
        message={secondarySnackBarObject?.message}
      />
    </Stack>
  )
}

export default Profile