import React, { useState, useContext } from 'react'

// COMPONENTS
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// STYLES
import useStyles from '../checkOrderUseStyles'

// MOMENT
import moment from 'moment'

// MUIS
import { 
  Autocomplete, 
  Button, 
  Dialog, 
  FormControl, 
  InputAdornment,
  Stack, 
  TextField, 
  Typography,
} from '@mui/material'

// MUI ICONS
import DateRangeIcon from '@mui/icons-material/DateRange'
import SearchIcon from '@mui/icons-material/Search'

const CheckOrderHeader = (props) => {
  const { 
    bookingType, setBookingType,
    dateRangeValue, setDateRangeValue,
    setSelectedFilterTabs, bookingTypeList,
    search, setSearch,
  } = props
  const classes = useStyles()
  const { setSecondarySnackBarObject } = useContext(AllPagesContext)

  const initialListButton = [
    {
      title: 'To Pay',
      isActive : true,
    },
    {
      title: 'Completed',
      isActive : false,
    },
    {
      title: 'Incomplete',
      isActive : false,
    },
  ]

  const lastTwoWeekDateFormat = [
    moment().subtract(13, 'days').format('DD MMM YYYY'),
    moment().endOf('day').format('DD MMM YYYY'),
  ]

  const [toPayListButton, setToPayListutton] = useState(initialListButton)
  const [isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen] = useState(false)
  const [dateRangeInpuValue, setDateRangeInputValue] = useState(`${lastTwoWeekDateFormat[0]} - ${lastTwoWeekDateFormat[1]}`)
  
  const handleResetFilter = () => {
    const lastTwoWeekDateRange = [
      moment().subtract(13, 'days').toDate(),
      moment().endOf('day').toDate(),
    ]

    setBookingType({label: 'All Booking'})
    setDateRangeValue(lastTwoWeekDateRange)
    setDateRangeInputValue(`${lastTwoWeekDateFormat[0]} - ${lastTwoWeekDateFormat[1]}`)
    setSearch('')
    setSecondarySnackBarObject({
      open: true,
      severity: 'info',
      message: 'Reset filter is active. All transactions have been displayed as before'
    })
  }

  const handleListButtonClick = (inputItem) => {
    const newListButton = [...toPayListButton].map((item) => {
      if(item.title === inputItem.title) item.isActive = true
      else item.isActive = false
      return item
    })
    setToPayListutton(newListButton)

    if(inputItem.title === 'To Pay') setSelectedFilterTabs('toPay')
    else if(inputItem.title === 'Completed') setSelectedFilterTabs('completed')
    else if(inputItem.title === 'Incomplete') setSelectedFilterTabs('incomplete')
  }

  return (
    <>
      <Stack className={classes.headerContainer}>
        {/* FILTERS */}
        <Stack
          borderBottom='1px solid #D9D9D9'
          padding='16px'
          height='70px'
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          {/* BOOKING TYPE */}
          <Autocomplete
            size='small'
            disablePortal
            value={bookingType}
            options={bookingTypeList}
            sx={{ width: 197 }}
            isOptionEqualToValue={(options,value) => options.label === value.label}
            renderInput={(params) => <TextField {...params} />}
            onChange={(event, newValue) => {
              setBookingType(newValue)
            }}
          />

          {/* DATE RANGE */}
          <Stack width='197px'>
            <FormControl>
              <TextField
                value={dateRangeInpuValue}
                onClick={() => setIsDateRangeTimePickerOpen(true)}
                size='small'
                placeholder='Transaction Date'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>
                    <DateRangeIcon sx={{
                      width: '16px',
                      height: '16px'
                    }}/>
                  </InputAdornment>
                }}
              />
            </FormControl>
          </Stack>

          {/* SEACRH */}
          <Stack width='197px'>
            <FormControl>
              <TextField
                size='small'
                placeholder='Seacrh'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>
                    <SearchIcon sx={{
                      width: '16px',
                      height: '16px'
                    }}/>
                  </InputAdornment>
                }}
              />
            </FormControl>
          </Stack>

        </Stack>

        {/* BUTTON GROUPS */}
        <Stack
          alignItems='center'
          justifyContent='space-between'
          padding='0px 16px'
          direction='row'
        >
          {/* TO PAY & COMPLETED BUTTON */}
          <Stack 
            alignItems='center'
            direction='row'
          >
            {toPayListButton.map((item, index) => (
              <Stack 
                key={index}
                height='46px'
                justifyContent='center'
                alignItems='center'
                onClick={() => handleListButtonClick(item)}
                sx={{
                  width: item.title === 'To Pay' ? '86px' : '120px',
                  borderBottom: item.isActive ? '3px solid #F96649' : '3px solid transparent',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant='body2'
                  sx={(theme) => ({color: theme.palette.text.secondary})}
                >
                  {item.title}
                </Typography>
              </Stack>
            ))}

          </Stack>

          {/* RESET FILTER */}
          <Button 
            onClick={() => handleResetFilter()}
            sx={{textTransform: 'none'}}>
            Reset Filter
          </Button>

        </Stack>
      </Stack>

      {/* DIALOG DATE RANGE PICKER */}
      <Dialog 
        open={isDateRangeTimePickerOpen}
        onClose={() => setIsDateRangeTimePickerOpen(false)}
        className={classes.dialogDateRangePicker}
      >
        <DateRangePicker
          setIsDateRangeTimePickerOpen={setIsDateRangeTimePickerOpen}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
          setDateRangeInputValue={setDateRangeInputValue}
        />
      </Dialog>
    </>
  )
}

export default CheckOrderHeader