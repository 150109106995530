// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: 'center',
    marginBottom: '254px',
    position: 'relative'
  },
  menuIcons: {
    height: '21.550701141357422px',
    marginBottom: '12px',
    marginTop: '20px',
    width: '32px',
    left: '4px',
    top: '4px',
  },
  caption: {
    maxWidth: 454,
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '60px',
    letterSpacing: '0em',
    color: 'white',
    position: 'relative',
    marginTop: 76,
    // right: 340,
  },
  orderContainer: {
    height: 257,
    maxWidth: 1170,
    width: '100%',
    borderRadius: 12,
    background: '#FFFFFF',
    boxShadow: '0px 4px 40px 0px #0000000D',
  },
  loadUnload: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: 12,
  },
  fieldInput: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '27px'
  },
  formButton: {
    textTransform: 'none',
    width: '199px',
    height: '55px',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
    boxShadow: 'none',
    
    '&:hover': {
      boxShadow: 'none'
    }}
}))

export default useStyles