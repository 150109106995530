// PAGES
import Home from 'pages/Home/Home'
import Unloading from 'pages/Unloading/Order/Order'
import UnloadingCheckout from 'pages/Unloading/Review/Review'
import FinishOrder from 'pages/Unloading/FinishOrder/FinishOrder'
import CheckOrder from 'pages/CheckOrder/CheckOrder'
import UnpaidDetailPage from 'pages/CheckOrder/UnpaidDetailPage/UnpaidDetailPage'
import CompletedDetailPage from 'pages/CheckOrder/CompletedDetailPage/CompletedDetailPage'
import ExpiredDetailPage from 'pages/CheckOrder/ExpiredDetailPage/ExpiredDetailPage'
import Load from 'pages/Load/Order/Order'
import LoadCheckout from 'pages/Load/Review/Review'
import LoadFinishOrder from 'pages/Load/FinishOrder/FinishOrder'
import Profile from 'pages/Profile/Profile'
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy'
import SupportRegulation from 'pages/SupportRegulation/SupportRegulation'
import ToU from 'pages/ToU/ToU'
 
// AUTH FROM KEMENKEU
import AuthFromKemenkeu from 'pages/AuthFromKemenkeu/AuthFromKemenkeu'

const routes = [
  // HOME 
  {
    path: '/',
    element: <Home/>,
  },
  {
    path: '/profile',
    element: <Profile/>,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy/>
  },
  {
    path: '/support-regulation',
    element: <SupportRegulation/>
  },
  {
    path: '/terms-of-use',
    element: <ToU/>
  },

  // AUTH FROM KEMENKEU
  {
    path: '/kemenkeu-authentication',
    element: <AuthFromKemenkeu/>,
  },
  // UNLOADING
  {
    path: '/unloading',
    element: <Unloading/>,
    routeType: 'unloading'
  },
  {
    path: '/unloading/checkout',
    element: <UnloadingCheckout/>,
    routeType: 'unloading'
  },
  {
    path: '/unloading/finish',
    element: <FinishOrder/>,
    routeType: 'unloading'
  },

  // CHECK ORDER
  {
    path: '/check-order',
    element: <CheckOrder/>,
    routeType: 'checkOrder'
  },
  {
    path: '/check-order/unpaid/:id',
    element: <UnpaidDetailPage/>,
    routeType: 'checkOrder'
  },
  {
    path: '/check-order/expired/:id',
    element: <ExpiredDetailPage/>,
    routeType: 'checkOrder'
  },
  {
    path: '/check-order/completed/:id',
    element: <CompletedDetailPage/>,
    routeType: 'checkOrder'
  },

  // LOAD
  {
    path: '/load',
    element: <Load/>,
    routeType: 'load'
  },
  {
    path: '/load/checkout',
    element: <LoadCheckout/>,
    routeType: 'load'
  },
  {
    path: '/load/finish',
    element: <LoadFinishOrder/>,
    routeType: 'load'
  },
]

export default routes