// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 291,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: '0px 4px 40px 0px #0000000D',
    marginTop: 12,
    padding: 24,
  },
  bookingTypetitle: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 8,
  },
  bookingID: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  bankName: {
    fontSize: 14,
    fontWeight: 600
  },
  typography1: {
    fontSize: 16,
    fontWeight: 600,
  },
  copyIcon: {
    width: 16,
    height: 16,
    color: theme.palette.primary.main,
    marginLeft: 8,
    cursor: 'pointer'
  },
  countDownTime: {
    padding: '8px 12px',
    backgroundColor: '#FEF3EB',
    borderRadius: 4,
  },
  countDownText: {
    fontSize: 13,
    fontWeight: 400,
    color: '#C77700'
  }
}))

export default useStyles
