// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '367px',
    backgroundColor: 'white',
    boxShadow: '0px 4px 40px 0px #0000000D',
    borderRadius: 4,
    marginRight: 24,
    marginTop: 24,
    position: 'sticky',
    top: '24px',
    height: 161
  },
  typography1: {
    fontSize: 16,
    fontWeight: 500,
  },
  iconInfo: {
    width: 20,
    height: 20,
    color: '#2196F3'
  }, 
}))

export default useStyles
