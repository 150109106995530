// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
    boxShadow: '0px 4px 40px 0px #0000000D',
    width: 444,
    alignItems: 'center'
  },
  mainTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  superscript: {
    color: theme.palette.primary.main
  },
  typography1: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  fieldNumber : {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    }
  },
}))

export default useStyles
