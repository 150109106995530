import { useContext } from 'react'

// ASSETS
import PaperIcon from 'assets/images/logos/order-paper-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import {
  Stack, 
  Box, 
  Typography, 
  Button, 
  Divider,
} from '@mui/material'

// MUI ICONS
import EditIcon from '@mui/icons-material/Edit'
import IconInfo from '@mui/icons-material/InfoOutlined'

// STYLES
import useStyles from '../reviewUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string' 

const DetailOrders = ({ setIsEditingDetailOrder, totalPrice }) => {
  const classes = useStyles()

  const {
    unloadingOrderForm, 
    unloadingItemList, 
  } = useContext(AllPagesContext)
  
  return (
    <Stack className={classes.orderDetail}>
      {/* TITLE */}
      <Stack 
        direction='row' 
        alignItems='center'
        borderBottom='solid 1px #D9D9D9'
        padding='16px 24px '
      >
        <Box 
          width='38px'
          component='img'
          src={PaperIcon}
          alt=''
        />
        <Typography className={classes.contactOrderTitle}>
          Order Details
        </Typography>
      </Stack>

      {/* BOL NO */}
      <Stack 
        direction='row'
        height='79px'
        alignItems='center'
        padding='0px 24px'
        borderBottom='solid 1px #D9D9D9'
        justifyContent='space-between'
      >
        <Typography>
          {unloadingOrderForm.bolNo}
        </Typography>

        <Button 
          variant='outlined'
          startIcon={<EditIcon/>}
          onClick={() => setIsEditingDetailOrder(true)}
        >
          Edit
        </Button>
      </Stack>

      {/* CONSIGNEE */}
      <Stack padding='24px'>
        <Typography 
          variant='body2' 
          color='#0000008A' 
          marginBottom='12px'
        >
          Consignee
        </Typography>
        <Typography className={classes.typography1}>
          {unloadingOrderForm.consignee}
        </Typography>
      </Stack>

      {/* NPWP */}
      <Stack padding='0px 24px 24px 24px'>
        <Typography 
          variant='body2' 
          color='#0000008A' 
          marginBottom='12px'
        >
          NPWP
        </Typography>
        <Typography className={classes.typography1}>
          {unloadingOrderForm.npwp}
        </Typography>
      </Stack>

      {/* ADDRESS */}
      <Stack padding='0px 24px 24px 24px'>
        <Typography 
          variant='body2' 
          color='#0000008A' 
          marginBottom='12px'
        >
          NPWP Company Address
        </Typography>
        <Typography className={classes.typography1}>
          {unloadingOrderForm.address}
        </Typography>
      </Stack>

      {/* ITEMS */}
      <Stack padding='0px 24px'>
        {/* CONTENT */}
        {unloadingItemList.map((item, index) => (
          <Stack key={index}>
            <Stack 
              direction='row'
              justifyContent='space-between'
            >
              {/* ITEMS */}
              <Stack
                width='480px'
              >
                {index === 0 && 
                <Typography className={classes.typography3}>
                  Items
                </Typography>}

                {/* CONTAINER TYPE */}
                <Typography
                  sx={{marginTop: index === 0 ? '12px' : '20px'}}
                  className={classes.typography1}
                >
                  {item?.containerType?.itemCode} - {item?.containerType?.itemType}
                </Typography>
              </Stack>

              {/* CONTAINER NO */}
              <Stack maxWidth='180px' width='100%' paddingRight='12px'>
                {index === 0 && 
                <Typography className={classes.typography3}>
                  Container No.
                </Typography>}

                <Typography
                  sx={{marginTop: index === 0 ? '12px' : '20px'}}
                  className={classes.typography1}
                >
                  {item.containerNo}
                </Typography>
              </Stack>

              {/* PRICE */}
              <Stack width='200px'>
                {index === 0 && 
                <Typography className={classes.typography3}>
                  Price
                </Typography>}
                    
                <Typography
                  sx={{marginTop: index === 0 ? '12px' : '20px'}}
                  className={classes.typography1}
                  textAlign='end'
                >
                  {`Rp ${addSeparatorsForNumber(item.price)}`}
                </Typography>
              </Stack>
            </Stack>

            {/* FLEET MANAGER & DESCRIPTION */}
            <Stack 
              direction='row' 
              marginTop='12px'
              alignItems='flex-start'
            >
              <Stack 
                marginRight='64px'
                width='480px'
              >
                <Typography 
                  className={classes.typography1}
                  textTransform='uppercase'
                >
                  {item?.value?.fleet?.fleet_manager_company?? '-'}
                </Typography>
              </Stack>

              <CustomTooltipBlack
                followCursor 
                title={item.description?? '-'}
                placement='bottom'
              >
                <Stack  
                  direction='row' 
                  spacing={1}
                  alignItems='center'
                  width='100%'
                  maxWidth= '380px'
                >
                  <IconInfo className={classes.iconInfo}/>
                  <Typography className={classes.descriptionText}>
                    {item.description?? '-'}
                  </Typography>
                </Stack>
              </CustomTooltipBlack>
            </Stack>

            <Divider sx={{marginTop: '20px'}}/>
          </Stack>
        ))}

        {/* SUB TOTAL */}
        <Stack
          direction='row'
          justifyContent='space-between'
          marginTop='20px'
          marginBottom='24px'
        >
          <Stack>
            <Typography className={classes.typography1}>
              Subtotal
            </Typography>
            <Typography className={classes.typography3}>
              {`Price (${unloadingItemList.length} items)`}
            </Typography>
          </Stack>

          <Typography className={classes.typography1}>
            {`Rp ${addSeparatorsForNumber(totalPrice)}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DetailOrders