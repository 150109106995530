import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIs
import { 
  Autocomplete, 
  Button,
  InputAdornment, 
  Modal, 
  Stack, 
  TextField, 
  Typography, 
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

// MUI ICONS
import IconDate from '@mui/icons-material/DateRange'
import IconDepoLocation from '@mui/icons-material/LocationCity'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

// SERVICE
import { getAllDepoList } from 'service/booking'

// STYLES
import useStyles from './createOrderModalUseStyles'

const CreateOrderModal = (props) => {
  const classes = useStyles()
  const { isCreateOrderModalOpen, setIsCreateOrderModalOpen } = props
  const navigate = useNavigate()
  const { 
    bookingHeaderData, 
    setBookingHeaderData, 
  } = useContext(AllPagesContext)

  const bookingTypeList = [
    {
      label: 'Unloading',
    },
    {
      label: 'Load'
    }
  ]

  const [depoList, setDepoList] = useState([])
  const [bookingType, setBookingType] = useState({label: 'Unloading'})

  const loadAllDepoList = async (inputIsMounted, inputAbortController) => {
    const resultLoadAllDepoList = await getAllDepoList(inputAbortController.signal)
    if(resultLoadAllDepoList.status === 200 && inputIsMounted) {
      setDepoList(resultLoadAllDepoList.data)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadAllDepoList(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  const handleDisableSaveBtn = () => {
    if(bookingHeaderData.date === '' || bookingHeaderData.depo === '' ) return true
    else return false
  }

  return (
    <Modal 
      open={isCreateOrderModalOpen}
      onClose={() => setIsCreateOrderModalOpen(false)}
    >
      <Stack className={classes.root}>
        {/* BOOKING TYPE */}
        <Stack
          width='100%'
          spacing={1.5}
          marginBottom='20px'
        >
          {/* TITLE */}
          <Typography className={classes.typography1}>
            Booking
          </Typography>

          {/* FORM */}
          <Autocomplete
            value={bookingType}
            disableClearable
            disablePortal
            options={bookingTypeList}
            fullWidth
            renderInput={(params) => (
              <TextField 
                {...params} 
                placeholder='Select booking type' 
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position='start' > 
                    <LocalShippingIcon/>
                  </InputAdornment>
                }}
              />
            )}
            onChange={(event, value) => {
              setBookingType(value)
              setBookingHeaderData(current => {
                return {
                  ...current,
                  type: value.label
                }
              })
            }}
            isOptionEqualToValue={(option, value) => value.label === option.label}
          />
        </Stack>

        {/* DEPO */}
        <Stack
          width='100%'
          spacing={1.5}
          marginBottom='20px'
        >
          {/* TITLE */}
          <Typography className={classes.typography1}>
            Depo
          </Typography>

          {/* FORM */}
          <Autocomplete
            disableClearable
            disablePortal
            options={depoList}
            getOptionLabel= {(option) => option.organizationName}
            isOptionEqualToValue={(option, value) => option.organizationName === value.organizationName}
            fullWidth
            onChange={(event,newValue) => {setBookingHeaderData(current => ({
              ...current,
              depo: newValue,
              type: bookingType.label,
            }))}}
            renderInput={(params) => (
              <TextField 
                {...params}
                placeholder='Select depo'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position='start' > 
                    <IconDepoLocation/>
                  </InputAdornment>
                }}
              />
            )}
          />
        </Stack>

        {/* DATE */}
        <Stack
          width='100%'
          spacing={1.5}
          marginBottom='20px'
        >
          {/* TITLE */}
          <Typography className={classes.typography1}>
            {bookingType.label === 'Unloading' ? 'Unloading Date' : 'Loading Date'}
          </Typography>

          {/* FORM */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat='D MMM YYYY'
              disablePast
              // openTo='year'
              // views={[ 'year', 'month', 'day']}
              value={bookingHeaderData.date}
              onChange={(newValue) => {setBookingHeaderData(current => ({
                ...current,
                date: newValue.$d,
              }))}}
              renderInput={( params ) =>
                <TextField {...params}
                  className={classes.fieldInput}
                  placeholder={bookingType === 'Unloading' ? 'Select unloading date' : 'Select load date'}
                  helperText={null}
                  InputProps={{
                    startAdornment: <InputAdornment position='start' > 
                      <IconDate/>
                    </InputAdornment>
                  }}
                />}
            />
          </LocalizationProvider>
        </Stack>

        {/* SAVE BUTTON */}
        <Stack 
          marginTop='4px'
          width='100%'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        > 
          <Button 
            className={classes.saveBtn}
            variant='outlined'
            onClick={() => setIsCreateOrderModalOpen(false)}
          >
            Cancel
          </Button>

          <Button 
            className={classes.saveBtn}
            variant='contained'
            onClick={() => bookingType.label === 'Unloading' ? navigate('/unloading') : navigate('/load')}
            disabled={handleDisableSaveBtn()}
          >
            Request Order
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default CreateOrderModal