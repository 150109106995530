import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import IndonesiaFlag from 'assets/images/logos/order-flag-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'

// MUIS
import { 
  Button,
  Box,
  IconButton,
  InputAdornment,
  Modal, 
  Stack, 
  Typography,
  TextField,
} from '@mui/material'

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close'

// SERVICE
import { sendOtpNumber } from 'service/booking'

// STYLES
import useStyles from './bookingAuthenticationUseStyles'

const BookingAuthentication = (props) => {
  const { isBookingAuthOpen, setIsBookingAuthOpen, setIsOtpMobileOpen } = props
  const classes = useStyles()
  const { 
    bookingContactDetail,
    setBookingContactDetail,
    setSnackbarObject
  } = useContext(AllPagesContext)
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  // HANDLE PHONE NUMBER FIELD CHANGE
  const handleFormChange = (inputEvent) => {
    setBookingContactDetail(current => {
      return {
        ...current,
        phoneNumber: inputEvent.target.value
      }
    })
  }

  // HANDLE LOGIN BUTTON
  const handleLoginButton = async () => {
    setIsLoading(true)
    const abortController = new AbortController()
    const phoneNumber = `0${bookingContactDetail.phoneNumber}`

    // CALL SEND OTP NUMBER API
    const resultSendOtpNumber = await sendOtpNumber(abortController.signal, phoneNumber)
    if(resultSendOtpNumber.status === 200) {
      setIsLoading(false)
      setIsBookingAuthOpen(false)
      // OPEN OTP MOBILE VERIFICATION COMPONENT
      setIsOtpMobileOpen(true)
    } else {
      setIsLoading(false)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to send OTP number',
      })
    }
  }

  return (
    <Modal open={isBookingAuthOpen}>
      <Stack className={classes.root}>
        {/* CLOSE BUTTON */}
        <Stack width='100%' alignItems='flex-end'>
          <IconButton onClick={() => setIsBookingAuthOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </Stack>

        {/* TITLE */}
        <Typography className={classes.mainTitle}>
          Log in to NLE Connect
        </Typography>

        <Typography className={classes.typography1}>
          Enter the telephone number
        </Typography>

        {/* PHONE NUMBER */}
        <Stack
          margin='40px 0px'
          width='319px'
        >
          <TextField 
            fullWidth
            placeholder='Enter phone number'
            type='number'
            name='phoneNumber'
            className={classes.fieldNumber}
            InputProps={{
              startAdornment : <InputAdornment position='start' > 
                <Stack alignItems='center' direction='row' paddingRight='14px' borderRight='solid 1px #D9D9D9'>
                  <Box component='img' src={IndonesiaFlag}/>
                  <Typography marginLeft='4px'> +62 </Typography>
                </Stack>
              </InputAdornment>
            }}
            value={bookingContactDetail.phoneNumber}
            onChange={(event) => handleFormChange(event)}
          />
        </Stack>

        {/* FOOTER */}
        <Stack width='319px'>
          <Typography textAlign='center' className={classes.typography1}>
            By logging in or registering, I agree to 
            NLE Connect’s <span onClick={() => navigate('/terms-of-use')} style={{color: '#F96649', cursor: 'pointer'}}>Terms of Use</span> and <span onClick={() => navigate('/privacy-policy')} style={{color: '#F96649', cursor: 'pointer'}}>Privacy Policy</span>
          </Typography>

          <Button 
            variant='contained'
            sx={{marginTop: '20px', height: '55px'}}
            onClick={handleLoginButton}
            disabled={bookingContactDetail.phoneNumber.length < 10 ? true : false}
          >
            Log in
          </Button>
        </Stack>

        {/* LOADING COMPONENT */}
        <LoadingComponent isLoading={isLoading}/>
      </Stack>
    </Modal>
  )
}

export default BookingAuthentication