import { useState, useEffect } from 'react'

//ASSETS
import HeaderImage from 'assets/images/backgrounds/information-header.png'

// CUSTOM COMPONENTS
import CustomAccordion from 'components/Customs/CustomAccordion'
import CustomAccordionSummary from 'components/Customs/CustomAccordionSummary'

// MUIS
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import ContentPasteIcon from '@mui/icons-material/ContentPaste'

// SERVICES
import { getToU } from 'service/information'

// STYLES
import useStyles from './contentUseStyles'

const Content= () => {
  const classes = useStyles()
  
  const [ listData, setListData ] = useState([])

  const loadToUData = async (inputIsMounted, inputAbortController) => {
    const resultToUData = await getToU(inputAbortController.signal)
  
    if(resultToUData !== 200 && inputIsMounted) {
      setListData(resultToUData.data)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadToUData(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack className={classes.root}>
      {/* HEADER */}
      <Stack
        width='100%'
        height='212px'
        alignItems= 'center'
        sx={{ 
          backgroundImage: `url(${HeaderImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        {/* CAPTION */}
        <Stack 
          width='100%'
          maxWidth='1440px'
        >
          <Typography className={classes.caption}>
            Terms of Use
          </Typography>
        </Stack>
        
        {/* SUB CAPTION */}
        <Stack 
          width='100%'
          maxWidth='1440px'
        >
          <Typography className={classes.subcaption}>
            Effective Date: { listData?.data?.attributes?.EffectiveDate }
          </Typography>
        </Stack>
      </Stack>

      {/* CONTENT */}
      <Stack 
        marginTop='64px'
        width='100%'
        alignItems='center'
      >
        <Stack 
          maxWidth='1270px'
          padding='0px 50px'
          width='100%'
        >
          {/* TITLE */}
          <Stack
            alignItems='center'
            marginBottom='24px'
          >
            <Typography className={classes.title1}>
              { listData?.data?.attributes?.Opening
                .replaceAll('Worx.id', 'NLE Connect')
                .replaceAll('Terms of Service', 'Terms of Use')
              }
            </Typography>
          </Stack>
          <Stack>
            { listData?.data?.attributes?.Contents?.map((item, index) =>
              <CustomAccordion
                key={index}
              >
                <CustomAccordionSummary>
                  <ContentPasteIcon className={classes.contentIcon}/>
                  <Typography className={classes.contentTitle}>
                    { item.Title }
                  </Typography>
                </CustomAccordionSummary>
                <Divider variant='middle' sx={{marginBottom:'8px'}}/>
                <AccordionDetails>
                  <Typography className={classes.contentDescription} paragraph='true'>
                    {
                      item.Description
                        .replaceAll('Worx.id', 'NLE Connect')
                        .replaceAll('WORX.ID', 'NLE CONNECT')
                        .replaceAll('Terms of Service', 'Terms of Use')
                        .replaceAll('TERMS OF SERVICE', 'TERMS OF USE')
                        .replaceAll('terms of service', 'terms of use')
                    }
                  </Typography>
                </AccordionDetails>
              </CustomAccordion>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )

}

export default Content