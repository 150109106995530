import React from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/PhoneAndroid'
import KemenkeuLogo from 'assets/images/logos/landing-kemenkeu-medium.svg'
import NleLogo from 'assets/images/logos/landing-nle.svg'
import BCALogo from 'assets/images/logos/PaymentMethods/bank-bca-small.svg'
import BJBLogo from 'assets/images/logos/PaymentMethods/bank-bjb-small.svg'
import BNILogo from 'assets/images/logos/PaymentMethods/bank-bni-small.svg'
import BRILogo from 'assets/images/logos/PaymentMethods/bank-bri-small.svg'
import BSILogo from 'assets/images/logos/PaymentMethods/bank-bsi-small.svg'
import MandiriLogo from 'assets/images/logos/PaymentMethods/bank-mandiri-small.svg'
import MasterCardLogo from 'assets/images/logos/PaymentMethods/mastercard-small.svg'
import PermataBankLogo from 'assets/images/logos/PaymentMethods/bank-permata-small.svg'
import VisaLogo from 'assets/images/logos/PaymentMethods/visa-small.svg'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './rightSectionUseStyles'

const RightSection = () => {
  const classes = useStyles()
  
  const navigate = useNavigate()

  return(
    <Stack>
      {/* UPPER */}
      <Stack>
        <Stack width='131px' marginTop='40px'>
          <Stack className={classes.title}>
            Pembayaran
          </Stack>
        </Stack>
        <Stack direction='row'>
          <Box
            component='img'
            src={BRILogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={MandiriLogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={BNILogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={BCALogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={PermataBankLogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={BSILogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={BJBLogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={VisaLogo}
            className={classes.bankLogo}
          />
          <Box
            component='img'
            src={MasterCardLogo}
            className={classes.bankLogo}
          />
        </Stack>
      </Stack>

      {/* LOWER */}
      <Stack direction='row'>
        {/* FIRST ROW */}
        <Stack width='131px' marginRight='40px'>
          <Stack className={classes.title}>
            ID Container
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Tentang Kami
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Homepage
          </Stack>
        </Stack>
  
        {/* SECOND ROW */}
        <Stack width='154px' marginRight='45px'>
          <Stack className={classes.title}>
            Support
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => 
            {
              navigate('/privacy-policy')
              window.scrollTo(0, 0)
            }}
          >
            Privacy Policy
          </Stack>
          {/* <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Return and Refund
          </Stack> */}
          <Stack
            className={classes.link}
            onClick={() => 
            {
              navigate('/support-regulation')
              window.scrollTo(0, 0)
            }}
          >
            Support Regulation
          </Stack>
          <Stack
            className={classes.link}
            onClick={() =>
            {
              navigate('/terms-of-use')
              window.scrollTo(0, 0)
            }}
          >
            Cara Kerja
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Manfaat
          </Stack>
        </Stack>
  
        {/* THIRD ROW */}
        <Stack width='217px' marginRight='40px'>
          <Stack className={classes.title}>
            Hubungi Kami
          </Stack>
          <Stack direction='row' className={classes.contact}>
            <EmailIcon className={classes.contactIcon}/> admin@nle-connect.id
          </Stack>
          <Stack direction='row' className={classes.contact}>
            <PhoneIcon className={classes.contactIcon}/> 021 21584255
          </Stack>
        </Stack>
          
        {/* FOURTH ROW */}
        <Stack width='168px'>
          <Stack
            className={classes.title}
            marginBottom='27.82px'
          >
            Didukung oleh
          </Stack>
          {/* KEMENKEU LOGO */}
          <Stack>
            <Box
              component='img'
              src={KemenkeuLogo}
              className={classes.kemenkeuLogo}
            />
          </Stack>
          {/* NLE LOGO */}
          <Stack>
            <Box
              component='img'
              src={NleLogo}
              className={classes.nleLogo}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default RightSection