import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import Header from './Header/Header'
import Order from './Order/Order'
import Footer from 'components/HomepageFooter/HomepageFooter'
import SecondarySnackbar from 'components/SecondarySnackbar/SecondarySnackbar'

// MUIS
import Stack from '@mui/material/Stack'
import { useEffect } from 'react'

const Home = () => {
  const {
    auth,
    secondarySnackBarObject,
    setSecondarySnackBarObject,
  } = useContext(AllPagesContext)
  const navigate = useNavigate()

  const [isMounted, setIsMounted] = useState(false)

  const handleSecondarySnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return

    setSecondarySnackBarObject({
      open: false,
      severity: 'info',
      message: '',
    })
  }

  // HANDLE USER ACCESS (IF USER HAVE TOKEN = NAVIGATE CHECK ORDER PAGE)
  useEffect(()=> {
    if(auth.accessToken !== undefined) navigate('/check-order')
    else setIsMounted(true)
  },[])
  
  return (
    <>
      {isMounted && <Stack overflow='hidden'>
        {/* HEADER */}
        <Header/>

        {/* ORDER */}
        <Order/>
      
        {/* FOOTER */}
        <Footer/>
      </Stack>}

      {/* SECONDARY SNACKBAR */}
      <SecondarySnackbar
        openSnackbar={secondarySnackBarObject?.open}
        handleClose={handleSecondarySnackbarClose}
        message={secondarySnackBarObject?.message}
      />
    </>
  )
}

export default Home