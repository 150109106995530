// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
    boxShadow: '0px 4px 40px 0px #0000000D',
    width: 458,
    padding: 24,
    alignItems: 'center'
  },
  typography1: {
    fontSize: 16,
    fontWeight: 400,
  },
  saveBtn: {
    height: 55,
    fontSize: 18,
    fontWeight: 600,
    textTransform: 'unset',
    width: '47.5%'
  }
}))

export default useStyles
