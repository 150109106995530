// MUIS
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Stack } from '@mui/material'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip 
    {...props} 
    classes={{ popper: className }} 
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
    fontSize: 14,
    borderRadius: 0,
    maxWidth: 500,
  },
  '@media only screen and (max-height: 820px)': {
    [`& .${tooltipClasses.tooltip}`]: {
      zoom: 0.85,
    },
  }
}))

const CustomTooltipBlack = (props) => {
  return (
    <Stack 
      className='no-zoom'
      sx={{
        '@media only screen and (max-height: 820px)': {
          '& .MuiIconButton-root .MuiSvgIcon-root .MuiTypography-root': {
            zoom: 0.85,
          }
        }
      }}
    >
      <StyledTooltip className='no-zoom' {...props}/>
    </Stack>
  )
}

export default CustomTooltipBlack