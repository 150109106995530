// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8,
    boxShadow: '0px 4px 40px 0px #0000000D',
    padding: '28px 28px 32px 28px',
    width: 416,
    alignItems: 'center'
  },
  mainTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 16,
  },
  resendOtpText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: 4
  },
  otpInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderBottom: '2px solid black',
      borderRadius: 0,
    },
    '& .MuiInputBase-input': {
      fontSize: 27,
    }
  },
  invalidOtpIcon: {
    width: 18,
    height: 18,
    color: theme.palette.error.dark
  },
  invalidOtptext: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.error.dark
  }
}))

export default useStyles
