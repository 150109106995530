import React from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import CompanyLogo from 'assets/images/logos/order-page-nle-logo.svg'

// MUIS
import { 
  Box, 
  Stack, 
  Typography 
} from '@mui/material'

// MUI ICONS
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// STYLES
import useStyles from './bookingAppBarUseStyles'

const OrderHeader = (props) => {
  const navigate = useNavigate()
  const { stepperList } = props

  const classes = useStyles()
  return (
    <Stack 
      direction='row' 
      justifyContent='space-between'
      alignItems='center' 
      padding='0px 135px'
      className={classes.root}>
      {/* COMPANY LOGO */}
      <Box 
        component='img'
        src={CompanyLogo}
        alt='nle-logo'
        sx={{cursor: 'pointer'}}
        onClick={() => navigate('/check-order')}
      />

      {/* STEPPER */}
      <Stack direction='row'>
        {stepperList.map((item,index) => (
          <Stack key={index} direction='row' marginRight='22px'>
            {/* STEPPER NUMBER */}
            <Stack 
              color='white' 
              alignItems='center' 
              justifyContent='center' 
              className={classes.stepperNumber}
              sx={{
                backgroundColor: item.isActived? '#F96649' : '#00000033'
              }}
            >
              <Typography variant='caption'>
                {item.id}
              </Typography>
            </Stack>

            {/* STEPPER TITLE */}
            <Typography className={classes.stepperTitle}>
              {item.title}
            </Typography>
            {item.isWithIcon && <ArrowForwardIosIcon className={classes.stepperConnector}/>}
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default OrderHeader