import PropTypes from 'prop-types'

// MUIS
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close'

// STYLES
import useStyles from './paymentDetailsUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const PaymentDetails = (props) => {
  const { totalItems,
    orderSummary,
    fee,
    isPaymentDetailOpen,
    setIsPaymentDetailOpen,
  } = props

  const classes = useStyles()

  const totalPayment = +orderSummary + +fee
  return (
    <Modal open={isPaymentDetailOpen}>
      <Stack className={classes.root}>
        {/* HEADER */}
        <Stack
          direction='row'
          alignItems='center'
          padding='16px 24px'
          justifyContent='space-between'
          width='100%'
        >
          <Typography className={classes.typography1}>
            Payment Details
          </Typography>
          
          <IconButton onClick={() => setIsPaymentDetailOpen(false)}>
            <CloseIcon sx={{color: '#0000008A'}}/>
          </IconButton>
        </Stack>

        <Divider/>

        {/* DETAILS */}
        <Stack
          padding='24px 24px 0px 24px'
        >
          {/* ORDER SUMMARY */}
          <Typography className={classes.typography2}>
            Order Summary
          </Typography>

          <Stack 
            width='100%'
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            marginTop='20px'
            marginBottom='28px'
          >
            <Typography className={classes.typography3}>
              {`Total price (${totalItems} items)`}
            </Typography>
            <Typography className={classes.typography3}>
              {`Rp ${addSeparatorsForNumber(orderSummary)}`}
            </Typography>
          </Stack>

          {/* OTHERS FEE || NOTE : TAKED OUT BY REQUEST */}
          {/* <Stack marginTop='36px' marginBottom='28px'>
            <Typography className={classes.typography2}>
              Others Fee
            </Typography>

            <Stack 
              width='100%'
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              marginTop='20px'
            >
              <Typography className={classes.typography3}>
                Transaction Fee
              </Typography>
              <Typography className={classes.typography3}>
                {`Rp ${addSeparatorsForNumber(fee)}`}
              </Typography>
            </Stack>
          </Stack> */}
          <Divider/>
        </Stack>

        {/* TOTAL PAYMENT */}
        <Stack
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          padding='28px 24px'
        >
          <Typography className={classes.typography2}>
            Total Payment
          </Typography>

          <Typography className={classes.typography1}>
            {`Rp ${addSeparatorsForNumber(totalPayment)}`}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  )
}

PaymentDetails.defaultProps = {
  totalItems: 0,
  orderSummary: 0,
  fee: '',
  isPaymentDetailOpen: false,
}

PaymentDetails.propTypes = {
  totalItems: PropTypes.number.isRequired,
  orderSummary: PropTypes.number.isRequired,
  fee: PropTypes.string.isRequired,
  isPaymentDetailOpen: PropTypes.bool.isRequired,
  setIsPaymentDetailOpen: PropTypes.func.isRequired
}

export default PaymentDetails