// MUIS
import { Stack, Typography } from '@mui/material'

const CheckOrderFooter = ({styling}) => {
  const dynamicYear = new Date().getFullYear()
  
  return (
    <Stack 
      marginTop='80px'
      paddingBottom='28px'
      sx={styling}
    >
      <Typography sx={(theme) => ({
        fontSize: '14px',
        fontWeight: 400,
        color: theme.palette.text.secondary
      })}>
        © NLEConnect. {dynamicYear}. All rights reserved
      </Typography>
    </Stack>
  )
}

export default CheckOrderFooter