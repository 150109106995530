import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS 
import CheckOrderHeader from './CheckOrderHeader/CheckOrderHeader'
import UnpaidCard from './UnpaidCard/UnpaiCard'
import CompletedCard from './CompletedCard/CompletedCard'
import ExpiredCard from './ExpiredCard/ExpiredCard'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MOMENT
import moment from 'moment'

// MUIS
import { Stack } from '@mui/material'

// SERVICE
import { searchBookingOrders, getXenditPaymentList } from 'service/booking'

// UTILS
import { removeUserProfileFromLocalStorage } from 'utilities/localStorage'

const CheckOrder = () => {
  const { 
    auth, 
    setSecondarySnackBarObject, 
    setAuth 
  } = useContext(AllPagesContext)
  const navigate = useNavigate()

  const bookingTypeList = [
    {
      label: 'All Booking'
    },
    {
      label: 'Unloading'
    },
    {
      label: 'Load'
    }
  ]

  const lastTwoWeekDate = [
    moment().subtract(13, 'days').toDate(),
    moment().endOf('day').toDate(),
  ]

  // HEADER STATES
  const [bookingType, setBookingType] = useState(bookingTypeList[0])
  const [dateRangeValue, setDateRangeValue] = useState(lastTwoWeekDate)
  const [selectedFilterTabs, setSelectedFilterTabs] = useState('toPay')
  const [unpaidListOrders, setUnpaidListOrders] = useState([])
  const [paidListOrders, setPaidListOrders] = useState([])
  const [expiredListOrders, setExpiredListOrders] = useState([])
  const [search, setSearch] = useState('')

  const getBookingType = () => {
    if(bookingType.label === 'Unloading') return 'UNLOADING'
    else if(bookingType.label === 'Load') return 'LOADING'
    else return null
  }
  
  const loadAllBookingOrders = async (inputIsMounted, inputAbortController) => {
    let bodyParams = {
      booking_type: getBookingType(),
    }
    if(search !== '') bodyParams.globalSearch = search
    if(dateRangeValue[0] !== null) bodyParams.from = moment(dateRangeValue[0]).format('YYYY-MM-DD')
    if(dateRangeValue[1] !== null) bodyParams.to = moment(dateRangeValue[1]).format('YYYY-MM-DD')

    const resultLoadAllBookingOrders = await searchBookingOrders(inputAbortController.signal, '1000', bodyParams, auth?.accessToken)

    const resultXenditPaymentList = await getXenditPaymentList(inputAbortController.signal, auth?.accessToken)
    
    if(resultLoadAllBookingOrders.status === 200 && inputIsMounted) {
      const combineAPI = resultLoadAllBookingOrders?.data?.rows?.map((item) => {
        resultXenditPaymentList.data.forEach((newItem) => {
          if(item.id === newItem.bookingId) item.payment = newItem
          else item.payment = null
        })
        return item
      })
      
      setUnpaidListOrders(combineAPI.filter((item) => item.booking_status === 'WAITING' && item.invoice_url !== null))
      setExpiredListOrders(combineAPI.filter((item) => item.booking_status === 'WAITING' && item.invoice_url === null))
      setPaidListOrders(combineAPI.filter((item) => item.booking_status === 'SUCCESS'))
    }
    // HANDLE SESSION JWT EXPIRED
    else if(resultLoadAllBookingOrders.status === 403 && resultLoadAllBookingOrders.data.error === 'Forbidden') {
      setSecondarySnackBarObject({
        open: true,
        severity: 'info',
        message: 'Session Expired. Please Log in again.'
      })
      removeUserProfileFromLocalStorage()
      setAuth({})
      navigate('/')
    }
  }

  // HANDLE RENDER LIST CARD
  const handleRenderListCard = () => {
    if(selectedFilterTabs === 'toPay') return <UnpaidCard 
      unpaidListOrders={unpaidListOrders}
      reloadData={loadAllBookingOrders}
    />
    else if(selectedFilterTabs === 'completed') return <CompletedCard paidListOrders={paidListOrders}/>
    else if(selectedFilterTabs === 'incomplete') return <ExpiredCard expiredListOrders={expiredListOrders}/>
  }
  
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadAllBookingOrders(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [bookingType, search, dateRangeValue])
  
  return (
    <Stack 
      width='100%'
      height='100%'
      maxWidth='649px'
    >
      {/* HEADER */}
      <CheckOrderHeader
        bookingTypeList={bookingTypeList}
        bookingType={bookingType}
        setBookingType={setBookingType}
        dateRangeValue={dateRangeValue}
        setDateRangeValue={setDateRangeValue}
        setSelectedFilterTabs={setSelectedFilterTabs}
        search={search}
        setSearch={setSearch}
      />

      {/* CARDS */}
      {handleRenderListCard()}
      
    </Stack>
  )
}

export default CheckOrder