import { useEffect, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

// MUIS
import { 
  Box, 
  Button, 
  Stack, 
  Typography, 
  Fade,
} from '@mui/material'

// ASSETS
import LoadingLogo from 'assets/images/logos/order-loading-logo.svg'
import UnloadingLogo from 'assets/images/logos/order-unloading-logo.svg'
import MainBackground from 'assets/images/backgrounds/check-orders-bg.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import CheckOrderFooter from 'components/CheckOrderFooter/CheckOrderFooter'

// MUI ICONS
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

// STYLES
import useStyles from '../UnpaidCard/unpaidCardUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const ExpiredCard = (props) => {
  const { expiredListOrders } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { setSnackbarObject } = useContext(AllPagesContext)
  
  const [listData, setListData] = useState([])
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  // HANDLE CONTINE CANCEL ORDER
  // const handleContinueButtonClick = () => {
  //   setIsDialogConfirmationOpen(false)
  // CLOSE MENU ITEM
  //   const newListData = [...listData].map((item) => {
  //     if(item.id) item.anchor = null
  //     else item.anchor = null
  //     return item
  //   })
  //   setListData(newListData)
  // }

  // HANDLE DIALOG CONFIRMATION BACK BUTTON
  const handleBackButtonClick = () => {
    setIsDialogConfirmationOpen(false)
    // CLOSE MENU ITEM
    const newListData = [...listData].map((item) => {
      if(item.id) item.anchor = null
      else item.anchor = null
      return item
    })
    setListData(newListData)
  }

  // HANDLE CANCEL ORDER
  // const handleCancelOrderClick = (event) => {
  //  event.stopPropagation()
  //  setIsDialogConfirmationOpen(true)
  // }

  // TOTAL PAYMENT
  const handleTotalPayment = (inputItem) => {
    let total = 0
    inputItem.forEach((item) => total += item.price)
    
    return `Rp ${addSeparatorsForNumber(total)}`
  }

  // STYLING FOOTER
  const stylingFooter = {
    position: listData.length > 1 ? 'unset' : 'absolute', 
    bottom: listData.length > 1 ? 'unset' : '0px'
  }

  useEffect(() => {
    if(expiredListOrders) {
      setListData(expiredListOrders)
      setIsMounted(true)
    }
  }, [expiredListOrders])

  return (
    <Fade in={isMounted} timeout={1000}>
      <Box>
        {listData && listData.map((item, index) => (
          <Stack key={index} className={classes.root}>
            {/* BOOKING TYPE */}
            <Stack 
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              marginBottom='20px'
            >
              {/* LOGO & TITLE */}
              <Stack
                direction='row'
                alignItems='center'
              >
                <Box
                  src={item.booking_type === 'UNLOADING' ? UnloadingLogo : LoadingLogo}
                  component='img'
                />
                <Typography className={classes.bookingTypetitle}>
                  {item.booking_type === 'UNLOADING' ? 'Unloading' : 'Load'}
                </Typography>
              </Stack>
  
              {/* BOOKING ID */}
              <Typography className={classes.bookingID}>
                Booking ID {item.id}
              </Typography>
  
            </Stack>
  
            {/* DETAIL PAYMENT */}
            <Stack
              border='1px solid #D9D9D9'
              height='144px'
              borderRadius='4px'
            >
              {/* HEADER */}
              <Stack
                padding='16px 20px'
                height='47px'
                alignItems='center'
                justifyContent='space-between'
                direction='row'
                borderBottom='1px solid #D9D9D9'
              >
                {/* BANK NAME */}
                <Typography className={classes.bankName}>
                  {item?.payment?.bankCode?? 'Bank'}
                </Typography>
              </Stack>
  
              {/* CONTENT */}
              {/* BOOKING DATE */}
              <Stack 
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='16px 20px'
              >
                <Typography variant='body2'>
                  {item.booking_type === 'UNLOADING' ? 'Unloading date' : 'Loading date'}
                </Typography>
                <Typography className={classes.typography1}>
                  {moment(item?.tx_date).format('DD MMMM YYYY')?? '-'}
                </Typography>
  
              </Stack>
  
              {/* TOTAL PAYMENT */}
              <Stack 
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                padding='0px 20px'
              >
                <Typography variant='body2'>
                  Total Payment
                </Typography>
  
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  <Typography className={classes.typography1}>
                    {handleTotalPayment(item.items)}
                  </Typography>
              
                  <ContentCopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(handleTotalPayment(item.items))
                      setSnackbarObject({
                        open: true,
                        severity: 'success',
                        title: '',
                        message: 'Successfully copied text',
                      })
                    }}
                    className={classes.copyIcon}
                  />
                </Stack>
              </Stack>
            </Stack>
  
            {/* EXPIRED DATE PAYMENT & SEE DETAIL */}
            <Stack 
              marginTop='20px'
              direction='row'
              alignitmes='center'
              justifyContent='space-between'
            >
              {/* EXPIRED DATE */}
              <Stack justifyContent='center' className={classes.countDownTime}>
                <Typography className={classes.countDownText}>
                  Expired
                </Typography>
              </Stack>
  
              {/* SEE DETAIL */}
              <Button 
                onClick={() => navigate(`/check-order/expired/${item.id}`)}
                variant='outlined'>
                See Detail
              </Button>
            </Stack>
          </Stack>
        ))}

        {/* NLE DEFAULT BACKGROUND IMAGE */}
        {listData.length === 0 && 
        <Stack 
          width='100%'
          height='200%'
          justifyContent='center'
          alignItems='center'
        >
          <Box
            src={MainBackground}
            component='img'
          />
        </Stack>}

        {/* FOOTER */}
        <CheckOrderFooter styling={stylingFooter}/>

        {/* DIALOG CONFIRMATION */}
        <DialogConfirmation
          isDialogConfirmationOpen={isDialogConfirmationOpen}
          setIsDialogConfirmationOpen={setIsDialogConfirmationOpen}
          // handleContinueButtonClick={handleContinueButtonClick}
          handleBackButtonClick={handleBackButtonClick}
          dialogConfirmationTitle='Are you sure you want to cancel the transaction?'
          dialogConfirmationDescription='Confirm Your Transaction Cancellation'
          cancelButtonText='Back'
          continueButtonText='Yes, Cancel'
        />
      </Box>
    </Fade>
  )
}

export default ExpiredCard