import React, { useContext } from 'react'

// COMPONENTS
import CheckOrderAppBar from 'components/CheckOrderAppBar/CheckOrderAppBar'
import SecondarySnackbar from 'components/SecondarySnackbar/SecondarySnackbar'
import LeftSection from './LeftSection'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './checkOrderUSeStyles'

const CheckOrder = (props) => {
  const { children } = props

  const classes = useStyles()

  const { 
    secondarySnackBarObject,
    setSecondarySnackBarObject,
  } = useContext(AllPagesContext)

  const handleSecondarySnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return

    setSecondarySnackBarObject({
      open: false,
      severity: 'info',
      message: '',
    })
  }

  return (
    <Stack 
      alignItems='center' 
      className={`${classes.root} no-zoom`}
    >
      <CssBaseline/>

      {/* APP BAR */}
      <Stack 
        className='zoom'
        width='100%' 
        alignItems='center'
        position='sticky'
        top='0px'
        zIndex='22'
        sx={{backgroundColor: 'white'}}
      >
        <Stack 
          width='100%' 
          maxWidth='1540px'
          padding='0px 50px'
          alignItems='center'>

          {/* HEADER */}
          <CheckOrderAppBar/>
        </Stack>
      </Stack>

      {/* MAIN COMPONENT */}
      <Stack
        component='main'
        className='zoom'
        direction='row'
        width='100%'
        height='100%'
        justifyContent='center'
        padding='0px 50px'
        sx={{overflowY: 'auto'}}
      >
        {/* AUTHENTICATION COMPONENT */}
        <LeftSection/>

        {/* CHILDREN COMPONENTS */}
        <>
          {children}
        </>
        
      </Stack>

      {/* SECONDARY SNACKBAR */}
      <SecondarySnackbar
        openSnackbar={secondarySnackBarObject?.open}
        handleClose={handleSecondarySnackbarClose}
        message={secondarySnackBarObject?.message}
      />
    </Stack>
  )
}

export default CheckOrder