import { useState, useRef, useEffect, useContext} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'

// COMPONENTS
import OrderHeader from 'components/BookingAppBar/BookingAppBar'
import SecondarySnackbar from 'components/SecondarySnackbar/SecondarySnackbar'

// STYLES 
import useStyles from './unloadingUseStyles'

const Unloading = (props) => {
  const { children } = props
  const { pathname } = useLocation()
  const classes = useStyles()

  const { 
    secondarySnackBarObject,
    setSecondarySnackBarObject,
    auth,
  } = useContext(AllPagesContext)
  const navigate = useNavigate()

  const initialStepperList = [
    {
      id: 1,
      title: 'Order',
      isActived: false,
      isWithIcon: true,
    },
    {
      id: 2,
      title: 'Checkout',
      isActived: false,
      isWithIcon: true,
    },
    {
      id: 3,
      title: 'Payment',
      isActived: false,
      isWithIcon: true,
    },
    {
      id: 4,
      title: 'Finish',
      isActived: false,
      isWithIcon: false,
    },
  ]

  const contentRef = useRef(null)
  const [stepperList, setStepperList] = useState(initialStepperList)

  // HANDLE CLOSE SECONDARY SNACKBAR
  const handleSecondarySnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return

    setSecondarySnackBarObject({
      open: false,
      severity: 'info',
      message: '',
    })
  }

  // HANDLE STEPPER LIST CONDITION
  useEffect(() => {
    if(pathname === '/unloading') {
      setStepperList(current => current.map((item) => {
        if(item.id === 1) item.isActived = true
        else item.isActived = false
        return item
      }))
    }
    else if(pathname === '/unloading/checkout'){
      setStepperList(current => current.map((item) => {
        if(item.id === 2 || item.id === 1) item.isActived = true
        else item.isActived = false
        return item
      }))
    }
    else if(pathname === '/unloading/finish'){
      setStepperList(current => current.map((item) => {
        if(item.id === 1|| item.id === 2 || item.id === 3 || item.id === 4) item.isActived = true
        else item.isActived = false
        return item
      }))
    }
  }, [pathname])

  // HANDLE SCROLL TO TOP ON CHECKOUT PAGE
  useEffect(() => {
    const div = contentRef.current
    if(pathname === '/unloading/checkout') div?.scroll({top:0})
  }, [pathname])

  // HANDLE USER ACCESS
  useEffect(() => {
    if(auth.accessToken === undefined || auth.email === undefined || auth.phoneNumber === undefined) navigate('/')
  }, [])

  return (
    <Stack className={`${classes.root} no-zoom`}>
      <CssBaseline/>
      
      {/* APP BAR */}
      <Stack 
        className='zoom'
        width='100%' 
        alignItems='center'
        position='sticky'
        top='0px'
        zIndex='22'
        sx={{backgroundColor: 'white'}}
      >
        <Stack 
          width='100%' 
          maxWidth='1540px'
          padding='0px 50px'
          alignItems='center'>
          {/* HEADER */}
          <OrderHeader stepperList={stepperList}/>
        </Stack>
      </Stack>

      {/* CONTENT CONTAINER */}
      <Stack
        ref={contentRef}
        component='main'
        className='zoom'
        alignItems='center'
        height='100%'
        sx={{overflowY: 'auto'}}
      >
        {children}
      </Stack>

      {/* SECONDARY SNACKBAR */}
      <SecondarySnackbar
        openSnackbar={secondarySnackBarObject?.open}
        handleClose={handleSecondarySnackbarClose}
        message={secondarySnackBarObject?.message}
        severity={secondarySnackBarObject.severity}
      />
    </Stack>
  )
}

export default Unloading