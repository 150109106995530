//MUI
import MuiAccordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'

const CustomAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  marginBottom: '16px',
  backgroundColor: '#F9F9F9',
  '&:before': {
    display: 'none',
  },
}))

export default CustomAccordion