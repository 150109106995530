// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  nleConnenctLogo:{
    height: '40px',
    width: '181.33px',
    cursor: 'pointer'
  },
  transportaLogo: {
    margin: '5.6px 5.99px',
    height: '15.96px',
    cursor: 'pointer'
  },
  instagramIcon: {
    height: '41.6px',
    width: '41.6px',
    color: '#0000008A'
  },
  twitterIcon: {
    height: '41.6px',
    width: '49.166px',
    color: '#0000008A'
  },
  linkedInIcon: {
    height: '41.6px',
    width: '41.6px',
    color: '#0000008A'
  },
  address: {
    maxWidth: 400,
    padding: '32px 0',
  },
  customerService: {
    maxWidth: 400,
    paddingBottom: 32,
  },
  csTitle: {
    fontWeight: 700
  }
}))

export default useStyles