export const addSeparatorsForNumber = (inputNumber) => {
  let newValue = inputNumber

  if (typeof newValue === 'object') newValue = ''
  else if (typeof newValue === 'string') newValue = parseInt(newValue)

  if (Number.isNaN(newValue)) return ''
  else if (typeof newValue === 'number') return newValue.toLocaleString().replaceAll(',', '.')
}

export const capitalizeEachWord = (inputString) => {
  if (!inputString || inputString === '') return ''
  return inputString
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const getDefaultErrorMessage = (inputResultObject) => {
  if(inputResultObject.status === 'No Server Response') return 'No Server Response'
  else return `Error ${inputResultObject.status}: ${inputResultObject.data.message}` 
}