import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// MOMENT JS
import moment from 'moment'

// ASSETS
import UnloadingIcon from 'assets/images/logos/order-unloading-logo.svg'
import LoadIcon from 'assets/images/logos/order-loading-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import { 
  Box, 
  Stack, 
  Typography,
} from '@mui/material'

// RAMDA
import { isEmpty } from 'ramda'

const LoadHeader = (props) => {
  const { type } = props
  const { bookingHeaderData } = useContext(AllPagesContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(isEmpty(bookingHeaderData.date) || isEmpty(bookingHeaderData.depo)) navigate('/check-order')
  }, [])
  
  return (
    <Stack 
      borderRadius='4px'
      sx={{backgroundColor: 'white'}}
      marginBottom='24px'    
    >
      {/* MAIN TITLE */}
      <Stack
        borderLeft='solid 4px #F96649'
        paddingLeft='24px'
        margin='16px 0px'
        direction='row'
        height='38px'
        alignItems='center'
        spacing={1}
      >
        <Box
          component='img'
          src={type === 'load' ? LoadIcon : UnloadingIcon}
          width='32px'
        />
        <Typography 
          variant='subtitle1'
          sx={{fontWeight: 500}}
        >
          {type === 'load' ? 'Load' : 'Unloading'}
        </Typography>
      </Stack>

      {/* CONTENT */}
      <Stack
        direction='row'
        justifyContent='space-between'
        padding='0px 24px 28px'
      >
        {/* UNLOADING DATE */}
        <Stack marginRight='55px'>
          <Typography 
            sx={{color: '#0000008A'}}
            variant='body2'
          >
            {type === 'load' ? 'Load Date' : 'Unloading Date'}
          </Typography>
          <Typography 
            variant='body2'
            sx={{fontWeight: 500}}
          >
            {moment(bookingHeaderData?.date).format('DD MMMM YYYY')}
          </Typography>
        </Stack>

        {/* DEPO */}
        <Stack marginRight='55px'>
          <Typography 
            sx={{color: '#0000008A'}}
            variant='body2'
          >
            Depo
          </Typography>
          <Typography 
            variant='body2'
            sx={{fontWeight: 500}}
          >
            {bookingHeaderData?.depo?.organizationName?? '-'}
          </Typography>
        </Stack>

        {/* ADDRESS */}
        <Stack width='300px'>
          <Typography 
            sx={{color: '#0000008A'}}
            variant='body2'
          >
            Address
          </Typography>
          <Typography 
            variant='body2'
            sx={{
              fontWeight: 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {bookingHeaderData?.depo?.address?? '-'}
          </Typography>
        </Stack>

      </Stack>

    </Stack>
  )
}

export default LoadHeader