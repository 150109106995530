import { useState, useEffect } from 'react'

//ASSETS
import HeaderImage from 'assets/images/backgrounds/information-header.png'

// CUSTOM COMPONENTS
import CustomAccordion from 'components/Customs/CustomAccordion'
import CustomAccordionSummary from 'components/Customs/CustomAccordionSummary'

// MUIS
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import ContentPasteIcon from '@mui/icons-material/ContentPaste'

// SERVICES
import { getSupportRegulation } from 'service/information'

// STYLES
import useStyles from './contentUseStyles'

const Content= () => {
  const classes = useStyles()
  
  const [ listData, setListData ] = useState([])

  const loadSupportRegulationData = async (inputIsMounted, inputAbortController) => {
    const resultSupportRegulation = await getSupportRegulation(inputAbortController.signal)
  
    if(resultSupportRegulation !== 200 && inputIsMounted) {
      setListData(resultSupportRegulation.data)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadSupportRegulationData(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack className={classes.root}>
      {/* HEADER */}
      <Stack
        width='100%'
        height='212px'
        alignItems= 'center'
        sx={{ 
          backgroundImage: `url(${HeaderImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        {/* CAPTION */}
        <Stack 
          width='100%'
          maxWidth='1440px'
        >
          <Typography className={classes.caption}>
            Support Regulation
          </Typography>
        </Stack>
        
        {/* SUB CAPTION */}
        <Stack 
          width='100%'
          maxWidth='1440px'
        >
          <Typography className={classes.subcaption}>
            Effective Date: { listData?.data?.attributes?.EffectiveDate }
          </Typography>
        </Stack>
      </Stack>

      {/* CONTENT */}
      <Stack 
        marginTop='64px'
        width='100%'
        alignItems='center'
      >
        <Stack 
          maxWidth='1270px'
          padding='0px 50px'
          width='100%'
        >
          {/* TITLE */}
          <Stack
            alignItems='center'
            marginBottom='24px'
          >
            <Typography className={classes.title1}>
              { listData?.data?.attributes?.Opening.replaceAll('Worx.id', 'NLE Connect')}
            </Typography>
          </Stack>
          <Stack>
            { listData?.data?.attributes?.Contents?.map((item, index) =>
              <CustomAccordion
                key={index}
              >
                <CustomAccordionSummary>
                  <ContentPasteIcon className={classes.contentIcon}/>
                  <Typography className={classes.contentTitle}>
                    { item.Title }
                  </Typography>
                </CustomAccordionSummary>
                <Divider variant='middle' sx={{marginBottom:'8px'}}/>
                <AccordionDetails>
                  <Typography className={classes.contentDescription} paragraph='true'>
                    {
                      item.Description
                        .replaceAll('Worx.id', 'NLE Connect')
                        .replaceAll('hi@worx.id', 'admin@nle-connect.id')
                    }
                  </Typography>
                </AccordionDetails>
              </CustomAccordion>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )

}

export default Content