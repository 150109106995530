// LAYOUT
import CheckOrder from 'layout/CheckOrder/CheckOrder'

const CheckOrderRoutes = (props) => {
  const { children } = props
  return (
    <CheckOrder>
      {children}
    </CheckOrder>
  )
}

export default CheckOrderRoutes