import { useContext, useState, useEffect } from 'react'

// ASSETS
import PaperIcon from 'assets/images/logos/order-paper-logo.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
  TextField,
} from '@mui/material'

// MUI ICONS
import IconInfo from '@mui/icons-material/InfoOutlined'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

// SERVICE 
import { getDepoListItem } from 'service/booking'

// STYLES
import useStyles from '../../Order/orderUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string' 

const EditDetailOrder = (props) => {
  const { setIsEditingDetailOrder } = props
  const classes = useStyles()

  const {
    unloadingOrderForm, setUnloadingOrderForm,
    unloadingItemList, setUnloadingItemList,
    bookingHeaderData, setSecondarySnackBarObject
  } = useContext(AllPagesContext)

  const [depoListItem, setDepoListItem] = useState([])
  const [isValidatingForms, setIsValidatingForms] = useState(false)

  // HANDLE SAVE BUTTON CLICK
  const handleSaveButton = () => {
    // CHECK IS ITEMS CHOOSED & CHECK CONTAINER NUMBER FIELD
    let isItemChoosed = true
    unloadingItemList.forEach((item) => {
      if(item.fleet === '' || item.containerType === '' || item.containerNo === '') isItemChoosed = false
    })
    
    // VALIDATING ALL FORMS
    if(
      unloadingOrderForm.bolNo === '' || unloadingOrderForm.consignee === '' ||
      unloadingOrderForm.npwp === '' || unloadingOrderForm.address === '' || !isItemChoosed
    ) {
      setSecondarySnackBarObject({
        open: true,
        severity: 'error',
        message: 'Required fields must be filled in'
      })
      setIsValidatingForms(true)
    } 
    
    else {
      setIsValidatingForms(false)
      setIsEditingDetailOrder(false)
    }
  }

  const handleOrderForm = (event) => {
    setUnloadingOrderForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }
  
  const handleContainerNoForm = (inputItem,inputEvent) => {
    const newList = [...unloadingItemList].map((item) => {
      if(item.id === inputItem.id) item.containerNo = inputEvent.target.value
      return item
    })
    setUnloadingItemList(newList)
  }

  const handleContainerTypeForm = (inputEvent,inputId,inputValue) => {
    // SET CONTAINER TYPE LIST
    inputEvent.stopPropagation()
    const newList = [...unloadingItemList].map((item) => {
      if(item.id === inputId) {
        item.containerType = inputValue?.item_name
        item.sku = inputValue?.sku
        item.price = inputValue?.price
        item.description = inputValue?.description
        item.value = {...inputValue}
        item.fleet = inputValue.fleet.name
      }
      return item
    })
    setUnloadingItemList(newList)
  }

  const handleAddNewContainerTypeForm = () => {
    let newForm = {
      titleNo : 'Container No.',
      titleType : 'Items',
      containerNo: '',
      containerType: '',
      price: 0,
      sku: '',
      description: '',
      id : Math.floor(Math.random() * 1000000),
    }

    setUnloadingItemList(current => [...current, newForm ])
  }

  const deleteContainerTypeForm = (inputIndex) => {
    const newList = [...unloadingItemList]
    newList.splice(inputIndex,1)
    setUnloadingItemList(newList)
  }

  // LOAD ITEM LIST DATA
  const loadDepoListItem = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      id: bookingHeaderData?.depo.id,
      type: 'UNLOADING'
    }
    const resultLoadDepoListItem = await getDepoListItem(inputAbortController.signal, queryParams)
      
    if(resultLoadDepoListItem.status === 200 && inputIsMounted) {
      setDepoListItem(resultLoadDepoListItem.data)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadDepoListItem(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <>
      {/* ORDER DETAIL */}
      <Stack className={classes.orderDetail}>
        {/* TITLE */}
        <Stack 
          direction='row' 
          alignItems='center'
          borderBottom='solid 1px #D9D9D9'
          padding='16px 24px '
          justifyContent='space-between'
        >
          <Stack
            direction='row'
            alignItems='center'
          >
            <Box 
              width='38px'
              component='img'
              src={PaperIcon}
              alt=''
            />
            <Typography className={classes.contactOrderTitle}>
              Order Details
            </Typography>
          </Stack>

          {/* SAVE BUTTON */}
          <Button 
            variant='outlined' 
            startIcon={<SaveIcon/>}
            onClick={() => handleSaveButton()}
          >
            Save
          </Button>
        </Stack>

        {/* FORMS */}
        <Stack padding='20px 24px 4px 24px'>
          {/* BOL NO */}
          <Typography variant='body1' marginBottom='12px'>
            Bill of Lading No.<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter bill of lading no.'
              type='tex'
              name='bolNo'
              value={unloadingOrderForm.bolNo}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.bolNo === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.bolNo === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* CONSIGNEE */}
          <Typography variant='body1' marginBottom='12px'>
            Consignee<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter consignee'
              type='tex'
              name='consignee'
              value={unloadingOrderForm.consignee}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.consignee === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.consignee === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* NPWP */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter npwp'
              className={classes.fieldNumber}
              type='number'
              name='npwp'
              value={unloadingOrderForm.npwp}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.npwp === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.npwp === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ADDRESS */}
          <Typography variant='body1' marginBottom='12px'>
            NPWP Company Address<span className={classes.superscript}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            hiddenLabel
            className='formTextField'
            color='secondary'
            error
          >
            <TextField 
              placeholder='Enter address'
              type='tex'
              name='address'
              value={unloadingOrderForm.address}
              onChange={(event) => handleOrderForm(event)}
              error={(isValidatingForms && unloadingOrderForm.address === '') ? true : false}
            />
            {/* HELPER TEXT */}
            {(isValidatingForms && unloadingOrderForm.address === '') && 
            <FormHelperText>This field can't be empty</FormHelperText>}
          </FormControl>

          {/* ITEMS */}
          {unloadingItemList.map((item,index) => (
            <Stack  key={index}>
              {/* ITEM FORM */}
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                {/* FORM */}
                <Stack direction='row' spacing={4} alignItems='center'>
                  {/* CONTAINER TYPE */}
                  <Stack>
                    <Typography variant='body1' marginBottom='12px'>
                      {item.titleType}<span className={classes.superscript}>*</span>
                    </Typography>

                    <Stack marginBottom='20px'>
                      <Autocomplete
                        disablePortal
                        options={depoListItem}
                        sx={{ width: 480 }}
                        value={item.value || null}
                        isOptionEqualToValue={(option, value)=> option.item_name.id === value.item_name.id}
                        onChange={(event,value) => handleContainerTypeForm(event, item.id, value)}
                        renderInput={(params) => <TextField {...params} placeholder='Select items' />}
                        getOptionLabel={(option) => `${option?.item_name?.itemCode} - ${option?.item_name?.itemType}`}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Stack width='100%'>
                              {/* ITEM NAME & PRICE */}
                              <Stack 
                                direction='row'
                                justifyContent='space-between'
                              >
                                <Typography 
                                  variant='body2'
                                  sx={{fontWeight: 500}}
                                >
                                  {option?.item_name?.itemCode} - {option?.item_name?.itemType}
                                </Typography>

                                <Typography variant='caption'>
                                  {`Rp. ${addSeparatorsForNumber(option.price)}`}
                                </Typography>
                              </Stack>

                              {/* FLEET MANAGER */}
                              <Typography variant='caption'>
                                {option?.fleet?.fleet_manager_company}
                              </Typography>

                              {/* SKU */}
                              <Typography variant='caption'>
                                {option.sku}
                              </Typography>
                            </Stack>
                          </li>
                        )}
                      />
                      {/* CUSTOM HELPER TEXT */}
                      {(item?.value?.item_name?.id === undefined && isValidatingForms) &&
                      <Typography 
                        margin='6px 12px'
                        color='error' 
                        variant='caption'
                      >
                        This field can't be empty
                      </Typography>}
                    </Stack>
                  </Stack>

                  {/* CONTAINER NO */}
                  <Stack 
                    width='100%'
                    maxWidth='180px'
                  >
                    <Typography 
                      variant='body1' 
                      marginBottom='12px'
                      sx={(theme) => ({color: theme.palette.text.secondary})}
                    >
                      {item.titleNo}
                    </Typography>

                    <FormControl
                      variant='outlined'
                      hiddenLabel
                      className='formTextField'
                      color='secondary'
                      error
                    >
                      <TextField
                        placeholder='Enter container no.'
                        type='text'
                        name={`containerNo${index}`}
                        value={item.containerNo}
                        onChange={(event) => handleContainerNoForm(item,event)}
                        error={(isValidatingForms && item.containerNo === '') ? true : false}
                      />
                      {/* HELPER TEXT */}
                      {(isValidatingForms && item.containerNo === '') && 
                      <FormHelperText>This field can't be empty</FormHelperText>}
                    </FormControl>
                  </Stack>
                </Stack>

                {/* TOTAL PRICE */}
                <Stack 
                  direction='row' 
                  alignItems='center'
                >
                  {/* TOTAL PRICE */}
                  <Stack marginRight='45px'>
                    <Typography
                      variant='body1' 
                      marginBottom='12px'
                      sx={(theme) => ({color: theme.palette.text.secondary})}
                    >
                      Total Price
                    </Typography>
                    <Stack
                      height='48px'
                      marginBottom='20px'
                      justifyContent='center'
                      paddingLeft='67px'
                    >
                      <Typography variant='body1'>
                        {`Rp. ${addSeparatorsForNumber(item.price?? '')}`}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* BUTTON DELETE ITEM */}
                  <Stack direction='row'>
                    <IconButton 
                      onClick={() => deleteContainerTypeForm(index,item.id)}
                      disabled={unloadingItemList.length > 1 ? false : true}
                      disableRipple
                    >
                      <DeleteIcon 
                        sx={{color: unloadingItemList.length > 1 ? '#F96649' : '#00000033'}} 
                        className={classes.addNewFromIcon}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>

              {/* ITEM DESCRIPTION */}
              {item.fleet !== '' && item.description !== '' &&
              <Stack 
                direction='row' 
                marginBottom='20px'
                alignItems='center'
              >
                {/* FLEET */}
                <Stack width='480px' marginRight='30px' paddingLeft='16px'>
                  <Typography 
                    variant='body2'
                    textTransform='uppercase'
                  >
                    {item?.value?.fleet?.fleet_manager_company?? '-'}
                  </Typography>
                </Stack>

                {/* DESCRIPTION */}
                <CustomTooltipBlack
                  followCursor 
                  title={item.description?? '-'}
                  placement='bottom'
                >
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    width='100%'
                    maxWidth= '400px'
                  >
                    <IconInfo className={classes.descriptionIcon}/>

                    <Typography
                      variant='body2'
                      className={classes.descriptionText}
                    >
                      {item.description?? '-'}
                    </Typography>
                  </Stack>
                </CustomTooltipBlack>
              </Stack>}

              {/* DIVIDER */}
              <Divider sx={{marginBottom: '20px'}}/>
            </Stack>
          ))}

          {/* BUTTON ADD ITEM */}
          <Stack 
            width='150px' 
            marginBottom='24px'
          >
            <Button 
              variant='outlined'
              size='large'
              startIcon={<AddIcon/>}
              sx={{textTransform: 'none'}}
              onClick={() => handleAddNewContainerTypeForm()}
            >
              Add Items
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default EditDetailOrder