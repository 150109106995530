// MUIS
import { Button, Stack, Typography } from '@mui/material'

// MUI ICONS
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// STYLES
import useStyles from './finishOrderUseStyles'

const FinishOrder = (props) => {
  const {
    invoiceNo,
    continueButton,
  } = props

  const classes = useStyles()

  return (
    <Stack
      width='100%'
      height='100%'
      justifyContent='center'
      alignItems='center'
    >
      <Stack
        justifyContent='center'
        alignItems='center'
      >
        <CheckCircleIcon className={classes.checklistIcon}/>
      
        <Typography className={classes.tyography1}>
          Success, your order process has been completed
        </Typography>

        <Typography 
          marginTop='16px'
          className={classes.tyography2}
        >
          You can check the status of the transaction in the order check
        </Typography>

        <Stack 
          direction='row' 
          alignItems='center'
          margin='24px 0px'
        >
          <Typography className={classes.tyography2}>
            Invoice No: 
          </Typography>
          <Typography variant='body2' marginLeft='4px'>
            {invoiceNo} 
          </Typography>
        </Stack>

        {/* BUTTON */}
        <Stack width='320px'>
          <Button 
            onClick={() => continueButton()}
            size='large'
            variant='contained'
            sx={{
              height: '47px',
              textTransform: 'none'
            }}
          >
            See Transaction Detail
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FinishOrder