// LAYOUT
import Unloading from 'layout/Unloading/Unloading'

const UnloadingRoutes = (props) => {
  const { children } = props
  return (
    <Unloading>
      {children}
    </Unloading>
  )
}

export default UnloadingRoutes